import { Classes } from '@blueprintjs/core';
import * as cx from 'classnames';
import * as React from 'react';
import { Bounds } from './Bounds';

export type TooltipProps = Readonly<{
  top: number;
  left: number;
  offsetTop?: number;
  offsetLeft?: number;
  style?: any;
  children?: any;
}>;

export const Tooltip = ({
  top: initialTop,
  left: initialLeft,
  offsetTop = 12,
  offsetLeft = 12,
  style,
  children,
}: TooltipProps) => (
  <Bounds
    render={({ rect, parentRect }) => {
      let top = initialTop;
      let left = initialLeft;

      if (rect && parentRect) {
        top =
          rect.bottom + offsetTop > parentRect.bottom || rect.bottom + offsetTop > window.innerHeight
            ? top - rect.height - offsetTop
            : top + offsetTop;
        left =
          rect.right + offsetLeft > parentRect.right || rect.right + offsetLeft > window.innerWidth
            ? left - rect.width - offsetLeft
            : left + offsetLeft;
      }

      return (
        <div
          className={cx('Tooltip', Classes.POPOVER)}
          style={{ ...style, top: 0, transform: `translate(${left}px, ${top}px)` }}
        >
          <div className={cx('Tooltip-content', Classes.POPOVER_CONTENT)}>{children}</div>
        </div>
      );
    }}
  />
);
