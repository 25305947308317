import { Intent, ProgressBar } from '@blueprintjs/core';
import * as React from 'react';

type SubscriptionUsageMeterProps = {
  ratio: number;
};

export const SubscriptionUsageMeter = ({ ratio }: SubscriptionUsageMeterProps) => {
  let intent: Intent;

  if (ratio >= 0.75 && ratio < 1) {
    intent = Intent.WARNING;
  } else if (ratio !== Infinity && ratio >= 1) {
    intent = Intent.DANGER;
  } else {
    intent = Intent.SUCCESS;
  }
  return <ProgressBar animate={false} stripes={false} value={ratio === Infinity ? 0 : ratio} intent={intent} />;
};
