// tslint:disable:no-var-requires
import { merge } from 'lodash';
import * as consts from './constants';

type Config = Readonly<{
  launchdarkly: {
    baseUrl: string;
    streamUrl: string;
    eventsUrl: string;
    clientId: string;
    environment: string; // environment key on catfood
    project?: string;
  };
  gonfalon: {
    host: string; // private host
    ghostUrl?: string; // private host with SSL and subdomain under app
    publicUrl: string; // main URL
    localPrivateAuth?: boolean; // true when running Gonfalon locally to allow private API auth
  };
  honeycomb: {
    dataset?: string; // honeycomb dataset name
  };
  provisioning: {
    host: string;
    sfdcBaseUrl: string;
  };
  slack: {
    webhookUrl?: string;
  };
  datadog: {
    applicationId?: string;
    clientToken?: string;
    site?: string;
  };
  help: {
    troubleshootingURL?: string;
    slackURL?: string;
    slackChannelName?: string;
  };
}>;

let config: Config;
let override: Config;
const local: Config = require('../vitalstatistix.local.json');

if (process.env.VITALSTATISTIX_MODE === 'production') {
  override = require('../vitalstatistix.prod.json');
} else if (process.env.VITALSTATISTIX_MODE === 'staging') {
  override = require('../vitalstatistix.stg.json');
} else if (process.env.VITALSTATISTIX_MODE === 'federal_staging') {
  override = require('../vitalstatistix.federal_staging.json');
} else if (process.env.VITALSTATISTIX_MODE === 'federal_production') {
  override = require('../vitalstatistix.federal_production.json');
} else if (process.env.VITALSTATISTIX_MODE === 'catamorphic') {
  override = require('../vitalstatistix.catamorphic.json');
} else if (process.env.VITALSTATISTIX_MODE === 'eu_production') {
  override = require('../vitalstatistix.eu_production.json');
} else {
  override = require('../vitalstatistix.override.json');
}

config = merge({}, local, override);

export default config;

export const baseUrl = () => {
  if (process.env.VITALSTATISTIX_MODE === 'production') {
    return consts.envBaseUrls.production;
  } else if (process.env.VITALSTATISTIX_MODE === 'staging') {
    return consts.envBaseUrls.staging;
  } else if (process.env.VITALSTATISTIX_MODE === 'federal_staging') {
    return consts.envBaseUrls.federalStaging;
  } else if (process.env.VITALSTATISTIX_MODE === 'federal_production') {
    return consts.envBaseUrls.federalProduction;
  } else if (process.env.VITALSTATISTIX_MODE === 'catamorphic') {
    return consts.envBaseUrls.catamorphic;
  } else if (process.env.VITALSTATISTIX_MODE === 'eu_production') {
    return consts.envBaseUrls.euProduction;
  } else {
    return consts.envBaseUrls.localHost;
  }
};
