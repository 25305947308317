import { Drawer, Icon, Spinner, Tab, Tabs } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Box } from 'grid-styled';
import * as React from 'react';

import { Account, AuditLogEntryDelta, AuditLogEntryDetail, Project } from '../../model';
import { AuditLogEntryOverview } from './AuditLogEntryOverview';
import { EntryDiff } from './EntryDiff';

import './styles.scss';

type StateProps = Readonly<{
  account: Account;
  entryUrl: string;
  onGetEntryDetails: (account: Account, entryUrl: string) => void;
  projects: Project[];
  entryDetails: AuditLogEntryDetail;
}>;

type State = {
  isOpen: boolean;
};

export class AuditLogEntryDetails extends React.Component<StateProps, State> {
  public state: State = {
    isOpen: false,
  };
  public render() {
    const { projects, entryDetails } = this.props;

    return (
      <>
        <button onClick={this.getAuditLogEntryDetails} className="AuditLogEntryDetails-button">
          {'DETAILS'}
        </button>
        <Drawer isOpen={this.state.isOpen} onClose={this.closeEntryDetailsModal}>
          <Icon icon={IconNames.CROSS} iconSize={25} className="closeDrawer" onClick={this.closeEntryDetailsModal} />
          {entryDetails && entryDetails.isFetching ? (
            <Spinner className="AuditLogEntryDetails-spinner" size={25} />
          ) : (
            entryDetails && (
              <div className="AuditLogEntryDetails">
                <AuditLogEntryOverview entry={entryDetails} projects={projects} />
                {entryDetails.comment && <Box className="AuditLogEntry-comment"> {entryDetails.comment} </Box>}
                <Tabs id="entrytabs" className="AuditLogEntryDetails-Tabs">
                  {entryDetails.delta && <Tab id="patch" title="Patch" panel={this.displayJSON(entryDetails.delta)} />}
                  <Tab id="diff" title="Diff" panel={this.displayDiff()} />
                </Tabs>
                {!entryDetails.previousVersion &&
                  entryDetails.currentVersion && <div> {this.displayJSON(entryDetails.currentVersion)} </div>}
                {entryDetails.previousVersion &&
                  !entryDetails.currentVersion && <div> {this.displayJSON(entryDetails.previousVersion)} </div>}
              </div>
            )
          )}
        </Drawer>
      </>
    );
  }

  private displayJSON = (json: object | AuditLogEntryDelta) => {
    return (
      <div className="AuditLogEntryDetails-tabcontent">
        <pre>{JSON.stringify(json, null, 2)}</pre>
      </div>
    );
  };
  private displayDiff = () => {
    const { entryDetails } = this.props;
    if (entryDetails.previousVersion && entryDetails.currentVersion) {
      return <EntryDiff a={entryDetails.previousVersion} b={entryDetails.currentVersion} />;
    } else {
      return <p>We couldn’t generate a diff for this update.</p>;
    }
  };

  private getAuditLogEntryDetails = () => {
    this.props.onGetEntryDetails(this.props.account, this.props.entryUrl);
    this.setState({ isOpen: true });
  };
  private closeEntryDetailsModal = () => {
    this.setState({ isOpen: false });
  };
}
