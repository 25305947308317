import * as React from 'react';

export interface IKeyEventMap {
  [keyCode: number]: React.KeyboardEventHandler<HTMLElement>;
}

export const createKeyEventHandler = (actions: IKeyEventMap, preventDefault = false) => {
  return (e: React.KeyboardEvent<HTMLElement>) => {
    for (const k of Object.keys(actions)) {
      const key = Number(k);
      if (e.which === key) {
        if (preventDefault) {
          e.preventDefault();
        }
        actions[key](e);
      }
    }
  };
};

export const createStringChangeHandler = (handler: (value: string) => void) => {
  return (event: React.FormEvent<HTMLElement>) => handler((event.target as HTMLInputElement).value);
};

export const createBooleanChangeHandler = (handler: (value: boolean) => void) => {
  return (event: React.FormEvent<HTMLElement>) => handler((event.target as HTMLInputElement).checked);
};
