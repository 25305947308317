import { Tooltip } from '@blueprintjs/core';
import * as moment from 'moment';
import * as React from 'react';

type Format = 'L' | 'LL' | 'LLL' | 'LLLL';

type TimeProps = Readonly<{
  datetime: any;
  format: Format;
  utc?: boolean;
  fromNow?: boolean;
  color?: string;
}>;

export const Time = ({ datetime, format, utc, fromNow, color }: TimeProps) => {
  const dt = utc ? moment.utc(datetime) : moment(datetime);
  const display = fromNow ? dt.fromNow() : dt.format(format);
  const hexcolor = color ? color : '#000';

  return (
    <Tooltip content={dt.format('LLLL')}>
      <time dateTime={datetime} color={hexcolor}>
        {display}
      </time>
    </Tooltip>
  );
};
