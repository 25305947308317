import { ActionsUnion, makeActionCreator, RequestError } from '../shared';
import { SettingsPrivateResponse } from './api';

export enum ActionTypes {
  UPDATE_SETTINGS_PUBLIC = 'settings/UPDATE_SETTINGS_PUBLIC',
  UPDATE_SETTINGS_PRIVATE = 'settings/UPDATE_SETTINGS_PRIVATE',
  UPDATE_SETTINGS_PRIVATE_FULFILLED = 'settings/UPDATE_SETTINGS_PRIVATE_FULFILLED',
  UPDATE_SETTINGS_PRIVATE_FAILED = 'settings/UPDATE_SETTINGS_PRIVATE_FAILED',
}

const createAction = makeActionCreator<ActionTypes>();

export const Actions = {
  updateSettingsPublic: (settings: any) =>
    createAction({
      type: ActionTypes.UPDATE_SETTINGS_PUBLIC,
      settings,
    }),
  updateSettingsPrivate: () =>
    createAction({
      type: ActionTypes.UPDATE_SETTINGS_PRIVATE,
    }),
  updateSettingsPrivateFulfilled: (response: SettingsPrivateResponse) =>
    createAction({
      type: ActionTypes.UPDATE_SETTINGS_PRIVATE_FULFILLED,
      settings: { ...response },
    }),
  updateSettingsPrivateFailed: (error: RequestError) =>
    createAction({
      type: ActionTypes.UPDATE_SETTINGS_PRIVATE_FAILED,
      error,
    }),
};

export type Actions = ActionsUnion<typeof Actions>;
