import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AppState } from '../../reducer';
import { getSettings } from '../../settings';
import { Settings } from '../../settings';
import { PricingCalculator } from '../components';

type StateProps = Readonly<{
  settings: Settings;
}>;

type OwnProps = RouteComponentProps<{}>;

type DispatchProps = void;

const mapStateToProps = (state: AppState) => ({
  settings: getSettings(state),
});

export const PricingCalculatorContainer = connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(
  PricingCalculator,
);
