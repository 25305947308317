import { forEach } from 'lodash';
import { Action, combineReducers } from 'redux';
import { requestReducer, requestReducerByKey, RequestState } from '../shared';
import { Actions, ActionTypes } from './actions';
import {
  LineItem,
  Order,
  OrderSource,
  Plan,
  Platform,
  ProcessedPlan,
  Product,
  ProductKind,
  ProductsByKind,
} from './model';

type DateState = string;

export type ProcessedPlansByAccountIDState = {
  [accountID: string]: ProcessedPlan;
};

export type SelectedPlanState = {
  accountID: string;
  launchLineItem: LineItem | null;
};

export type ExecutedPlanState = {
  executedPlanID: number;
};

export type RejectedPlanState = {
  rejectedPlanID: number;
};

export const initialSelectedPlanState = {
  accountID: '',
  launchLineItem: null,
};

export const initialExecutedPlanState = {
  executedPlanID: 0,
};

export const initialRejectedPlanState = {
  rejectedPlanID: 0,
};

function mapProductsByKind(products: Product[]): ProductsByKind {
  return products.reduce<ProductsByKind>((productsByKind, currentProduct) => {
    productsByKind[currentProduct.kind] = currentProduct.qty;
    return productsByKind;
  }, {});
}

function processPlan(plan: Plan): ProcessedPlan {
  const startingLineItems: LineItem[] = [];
  const endingLineItems: LineItem[] = [];
  const washLineItems: LineItem[] = [];
  const participatingLineItems: LineItem[] = [];
  const immediatelyActionableSingleActionProducts: LineItem[] = [];

  plan.orders.forEach(order => {
    Object.values(order.productsByOperation.starting).forEach(products => {
      startingLineItems.push({
        orderID: order.orderID,
        orderSource: order.orderSource,
        sfdcOppId: order.sfdcOppId,
        startDate: products[0].startDate,
        endDate: products[0].endDate,
        platform: products[0].platform,
        products: mapProductsByKind(products),
      });
    });

    Object.values(order.productsByOperation.ending).forEach(products => {
      endingLineItems.push({
        orderID: order.orderID,
        orderSource: order.orderSource,
        sfdcOppId: order.sfdcOppId,
        startDate: products[0].startDate,
        endDate: products[0].endDate,
        platform: products[0].platform,
        products: mapProductsByKind(products),
      });
    });

    Object.values(order.productsByOperation.wash).forEach(products => {
      washLineItems.push({
        orderID: order.orderID,
        orderSource: order.orderSource,
        sfdcOppId: order.sfdcOppId,
        startDate: products[0].startDate,
        endDate: products[0].endDate,
        platform: products[0].platform,
        products: mapProductsByKind(products),
      });
    });

    Object.values(order.productsByOperation.participating).forEach(products => {
      participatingLineItems.push({
        orderID: order.orderID,
        orderSource: order.orderSource,
        sfdcOppId: order.sfdcOppId,
        startDate: products[0].startDate,
        endDate: products[0].endDate,
        platform: products[0].platform,
        products: mapProductsByKind(products),
      });
    });
  });

  plan.immediatelyActionableSingleActionProducts.forEach(IASAP => {
    immediatelyActionableSingleActionProducts.push({
      orderID: IASAP.product.orderID,
      orderSource: IASAP.orderSource,
      sfdcOppId: IASAP.sfdcOppId,
      startDate: IASAP.product.startDate,
      endDate: IASAP.product.endDate,
      platform: formatProductKindAsPlatform(IASAP.product.kind),
      products: {},
    });
  });

  return {
    organization: plan.organization,
    previousAccountConfig: plan.previousAccountConfig,
    plannedConfig: plan.plannedConfig,
    ldInstance: plan.ldInstance,
    inputsHash: plan.inputsHash,
    flagOverrides: plan.flagOverrides,
    startingLineItems,
    endingLineItems,
    washLineItems,
    participatingLineItems,
    immediatelyActionableSingleActionProducts,
  };
}

const formatProductKindAsPlatform = (kind: ProductKind): string => {
  switch (kind) {
    case ProductKind.StandardTrial2021: {
      return Platform.StandardTrial2021;
    }
    case ProductKind.EnterpriseTrial: {
      return Platform.EnterpriseTrial;
    }
    default: {
      return kind;
    }
  }
};

const dateReducer = (state: string = '', action: Actions) => {
  if (action.type === ActionTypes.FETCH_AVAILABLE_PLANS_FULFILLED) {
    return action.response.date;
  }

  return state;
};

const processedPlansByAccountIDReducer = (state: ProcessedPlansByAccountIDState = {}, action: Actions) => {
  if (action.type === ActionTypes.FETCH_AVAILABLE_PLANS_FULFILLED) {
    return action.response.plans.reduce<ProcessedPlansByAccountIDState>((plansByAccountID, currentPlan) => {
      plansByAccountID[currentPlan.accountID] = processPlan(currentPlan);
      return plansByAccountID;
    }, {});
  }

  return state;
};

function processLaunchOrders(orders: Order[]): LineItem | null {
  if (orders.length > 0) {
    const order = orders[0];
    const launchLineItem: LineItem = {
      orderID: order.orderID,
      orderSource: OrderSource.Launch,
      sfdcOppId: '',
      startDate: null,
      endDate: null,
      platform: order.products[0].platform,
      products: {},
    };

    launchLineItem.endDate = order.products[0].endDate;
    forEach(order.products.filter(product => product.planDownID === null), product => {
      launchLineItem.products[product.kind] = product.qty;
    });

    return launchLineItem;
  }

  return null;
}

const selectedPlanReducer = (state: SelectedPlanState = initialSelectedPlanState, action: Actions) => {
  if (action.type === ActionTypes.GET_SELECTED_PLAN_ID) {
    return {
      ...initialSelectedPlanState,
      accountID: action.accountID,
    };
  }

  if (action.type === ActionTypes.FETCH_LAUNCH_ORDERS_FULFILLED) {
    return {
      ...state,
      launchLineItem: processLaunchOrders(action.response.orders),
    };
  }

  if (action.type === ActionTypes.FETCH_AVAILABLE_PLANS_FULFILLED) {
    if (action.clearSelectedPlan) {
      return { ...initialSelectedPlanState };
    } else {
      return {
        ...initialSelectedPlanState,
        accountID: state.accountID,
      };
    }
  }

  return state;
};

const executedPlanReducer = (state: ExecutedPlanState = initialExecutedPlanState, action: Actions) => {
  if (action.type === ActionTypes.SET_EXECUTED_PLAN_FULFILLED) {
    return action.response;
  }

  return state;
};

const rejectedPlanReducer = (state: RejectedPlanState = initialRejectedPlanState, action: Actions) => {
  if (action.type === ActionTypes.SET_REJECTED_PLAN_FULFILLED) {
    return action.response;
  }

  return state;
};

const fetchAvailablePlansRequest = requestReducer([
  ActionTypes.FETCH_AVAILABLE_PLANS,
  ActionTypes.FETCH_AVAILABLE_PLANS_FULFILLED,
  ActionTypes.FETCH_AVAILABLE_PLANS_FAILED,
]);

interface IRequestByAccountIdAction extends Action {
  accountID: string;
}

const fetchLaunchOrdersRequest = requestReducerByKey(
  [ActionTypes.FETCH_LAUNCH_ORDERS, ActionTypes.FETCH_LAUNCH_ORDERS_FULFILLED, ActionTypes.FETCH_LAUNCH_ORDERS_FAILED],
  (action: IRequestByAccountIdAction) => action.accountID,
);

const setLaunchOrderEndDateRequest = requestReducerByKey(
  [
    ActionTypes.SET_LAUNCH_ORDER_END_DATE,
    ActionTypes.SET_LAUNCH_ORDER_END_DATE_FULFILLED,
    ActionTypes.SET_LAUNCH_ORDER_END_DATE_FAILED,
  ],
  (action: IRequestByAccountIdAction) => action.accountID,
);

const setExecutedPlanRequest = requestReducerByKey(
  [ActionTypes.SET_EXECUTED_PLAN, ActionTypes.SET_EXECUTED_PLAN_FULFILLED, ActionTypes.SET_EXECUTED_PLAN_FAILED],
  (action: IRequestByAccountIdAction) => action.accountID,
);

const setRejectedPlanRequest = requestReducerByKey(
  [ActionTypes.SET_REJECTED_PLAN, ActionTypes.SET_REJECTED_PLAN_FULFILLED, ActionTypes.SET_REJECTED_PLAN_FAILED],
  (action: IRequestByAccountIdAction) => action.accountID,
);

export const reducer = combineReducers({
  date: dateReducer,
  processedPlansByAccountID: processedPlansByAccountIDReducer,
  selectedPlan: selectedPlanReducer,
  executedPlan: executedPlanReducer,
  rejectedPlan: rejectedPlanReducer,
  fetchAvailablePlansRequest,
  fetchLaunchOrdersRequest,
  setLaunchOrderEndDateRequest,
  setExecutedPlanRequest,
  setRejectedPlanRequest,
});

export type ProvisioningState = {
  date: DateState;
  processedPlansByAccountID: ProcessedPlansByAccountIDState;
  selectedPlan: SelectedPlanState;
  executedPlan: ExecutedPlanState;
  rejectedPlan: RejectedPlanState;
  fetchAvailablePlansRequest: RequestState;
  fetchLaunchOrdersRequest: { [accountID: string]: RequestState };
  setLaunchOrderEndDateRequest: { [orderID: string]: RequestState };
  setExecutedPlanRequest: { [accountID: string]: RequestState };
  setRejectedPlanRequest: { [accountID: string]: RequestState };
};
