import { Icon, Text } from '@blueprintjs/core';
import * as React from 'react';

export type LegendProps = Readonly<{
  text?: string;
  stroke: string;
}>;

export class Legend extends React.Component<LegendProps> {
  public render() {
    const { stroke, text } = this.props;

    return (
      <>
        <Icon
          icon="symbol-square"
          iconSize={16}
          title={null}
          color={stroke}
          className="Icon"
          style={{ cursor: 'default' }}
        />
        <Text ellipsize={true} tagName="span" className="LegendText">
          {text}
        </Text>
      </>
    );
  }
}
