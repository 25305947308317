import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { concat, of } from 'rxjs';
import { catchError, filter, flatMap, map, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../reducer';
import { actionCreators, Actions, ActionTypes } from './actions';
import * as api from './api';
import { OrderSource } from './model';

export const availablePlansFetch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof actionCreators.fetchAvailablePlans>>(ActionTypes.FETCH_AVAILABLE_PLANS),
    flatMap(({ clearSelectedPlan, absoluteMode }) =>
      api.getAvailablePlans(absoluteMode).pipe(
        map(response => actionCreators.fetchAvailablePlansFulfilled(clearSelectedPlan, absoluteMode, response)),
        catchError(error => of(actionCreators.fetchAvailablePlansFailed(clearSelectedPlan, error))),
      ),
    ),
  );
};

export const selectPlanGet = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof actionCreators.getSelectedPlanID>>(ActionTypes.GET_SELECTED_PLAN_ID),
    flatMap(({ accountID }) => of(actionCreators.fetchLaunchOrders(accountID, OrderSource.Launch))),
  );
};

export const selectedPlanRefresh = (action$: ActionsObservable<Actions>, state$: StateObservable<AppState>) => {
  return action$.pipe(
    ofType<ReturnType<typeof actionCreators.fetchAvailablePlansFulfilled>>(ActionTypes.FETCH_AVAILABLE_PLANS_FULFILLED),
    withLatestFrom(state$),
    filter(([action, state]) => !action.clearSelectedPlan && state.provisioning.selectedPlan.accountID !== null),
    map(([_, state]) => actionCreators.getSelectedPlanID(`${state.provisioning.selectedPlan.accountID}`)),
  );
};

export const launchOrdersFetch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof actionCreators.fetchLaunchOrders>>(ActionTypes.FETCH_LAUNCH_ORDERS),
    flatMap(({ accountID, source }) =>
      api.getOrders({ accountID, source }).pipe(
        map(response => actionCreators.fetchLaunchOrdersFulfilled(accountID, response)),
        catchError(error => of(actionCreators.fetchLaunchOrdersFailed(accountID, error))),
      ),
    ),
  );
};

export const launchOrderEndDateSet = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof actionCreators.setLaunchOrderEndDate>>(ActionTypes.SET_LAUNCH_ORDER_END_DATE),
    flatMap(({ accountID, orderID, endDate, absoluteMode }) =>
      api.postLaunchOrderEndDate(orderID, endDate).pipe(
        flatMap(response => {
          return concat(
            of(actionCreators.setLaunchOrderEndDateFulfilled(accountID, orderID, endDate, response)),
            of(actionCreators.fetchAvailablePlans(false, absoluteMode)),
          );
        }),
        catchError(error => of(actionCreators.setLaunchOrderEndDateFailed(accountID, orderID, endDate, error))),
      ),
    ),
  );
};

export const executedPlanSet = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof actionCreators.setExecutedPlan>>(ActionTypes.SET_EXECUTED_PLAN),
    flatMap(({ accountID, inputsHash, skipEmailNotification, userEmail, absoluteMode }) =>
      api.postExecutedPlan(accountID, inputsHash, absoluteMode, skipEmailNotification, userEmail).pipe(
        flatMap(response => {
          return concat(
            of(actionCreators.setExecutedPlanFulfilled(accountID, response)),
            of(actionCreators.fetchAvailablePlans(true, absoluteMode)),
          );
        }),
        catchError(error => of(actionCreators.setExecutedPlanFailed(accountID, error))),
      ),
    ),
  );
};

export const rejectedPlanSet = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof actionCreators.setRejectedPlan>>(ActionTypes.SET_REJECTED_PLAN),
    flatMap(({ accountID, inputsHash, absoluteMode, userEmail, comment }) =>
      api.postRejectedPlan(accountID, inputsHash, absoluteMode, userEmail, comment).pipe(
        flatMap(response => {
          return concat(
            of(actionCreators.setRejectedPlanFulfilled(accountID, response)),
            of(actionCreators.fetchAvailablePlans(true, absoluteMode)),
          );
        }),
        catchError(error => of(actionCreators.setRejectedPlanFailed(accountID, error))),
      ),
    ),
  );
};
