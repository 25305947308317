import { Callout, Classes, NonIdealState, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as cx from 'classnames';
import * as React from 'react';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '../';
import './styles.scss';

export const AccessDenied = () => (
  <NonIdealState className="NonIdealState" icon={IconNames.BAN_CIRCLE} title="You do not have access to this page" />
);

export const NotFound = () => (
  <NonIdealState
    className="NonIdealState"
    icon={IconNames.WARNING_SIGN}
    title="Page not found"
    action={<Link to="/">Go back to the homepage</Link>}
  />
);

type LoadingProps = Readonly<{
  className?: any;
  title?: string;
}>;

export const Loading = ({ className, title }: LoadingProps) => (
  <NonIdealState className={cx('NonIdealState', className)} icon={<Spinner />} title={title || 'Loading'} />
);

export const AppLoading = ({ className, title }: LoadingProps) => (
  <NonIdealState className={cx('NonIdealState', 'AppLoading', className)} icon={<Logo />} title={title || ''} />
);

type LoadingErrorProps = Readonly<{
  title: string;
  description?: string;
}>;

export const LoadingError = ({ title, description }: LoadingErrorProps) => (
  <NonIdealState
    className="NonIdealState"
    icon={IconNames.ERROR}
    title={title}
    description={
      <div className={Classes.RUNNING_TEXT}>
        {description}
        <Callout title="Are you connected to the VPN?">
          Vitalstatistix requires VPN access to connect to LaunchDarkly. Make sure you are connected and reload the
          page. If this problem persists, 👋 in{' '}
          <a href="https://slack.com/app_redirect?channel=vitalstatistix">#vitalstatistix</a> on Slack.
        </Callout>
      </div>
    }
  />
);

type ResourceNotFoundProps = Readonly<{
  title: string;
  description?: string | ReactElement<any>;
  returnTo: string;
  returnLabel: string;
}>;

export const ResourceNotFound = ({ title, description, returnTo, returnLabel }: ResourceNotFoundProps) => (
  <NonIdealState
    className="NonIdealState"
    icon={IconNames.WARNING_SIGN}
    title={title}
    description={description}
    action={<Link to={returnTo}>{returnLabel}</Link>}
  />
);
