import { Spinner } from '@blueprintjs/core';
import * as React from 'react';

import './AccountList.scss';

import * as d3 from 'd3';
import { DefaultCallout, Legend, MetadataType } from '../../shared';
import { UsageResponse } from '../api';
import { ChartTypes, SDKOption, UsageIdentifier } from '../model';
import { UsageChart } from './UsageChart';

export type AccountUsageProps = UsageResponse &
  Readonly<{
    isFetching: boolean;
    onFetchAccountUsage: (option?: SDKOption) => void;
    chartType: ChartTypes;
    usageIdentifier?: UsageIdentifier;
    renderFilters?: () => void;
    tooltipText: string;
    selectedOption?: SDKOption;
    limit?: number;
  }>;

type State = {
  readonly selectedOption: SDKOption;
};

export class AccountUsage extends React.Component<AccountUsageProps, State> {
  public componentDidMount() {
    this.props.onFetchAccountUsage(this.props.selectedOption);
  }

  public render() {
    const { isFetching, metadata, series, chartType, tooltipText, renderFilters, limit } = this.props;

    const color = d3.scaleOrdinal(d3.schemeAccent);

    if (series.length === 0) {
      return (
        <div className="UsageGraph--loading">
          <DefaultCallout>There is no usage data.</DefaultCallout>
        </div>
      );
    }

    return (
      <>
        {renderFilters && renderFilters()}
        {isFetching ? (
          <div className="UsageGraph--loading">
            <Spinner size={Spinner.SIZE_SMALL} />
          </div>
        ) : (
          <UsageChart
            metadata={metadata}
            limit={limit}
            series={series}
            chartType={chartType}
            tooltipText={tooltipText}
            color={color}
            renderToolTip={this.renderToolTip}
          />
        )}
        <div className="Legend">
          {chartType === ChartTypes.LINE &&
            (metadata.length === 1 ? (
              <Legend
                stroke={color('0')}
                text={metadata[0].version ? `${metadata[0].sdk} - ${metadata[0].version}` : 'All connections'}
              />
            ) : (
              metadata.map((md: MetadataType, i) => (
                <Legend stroke={color(i.toString())} text={this.renderLegend(md)} key={i} />
              ))
            ))}
          {limit ? <Legend stroke={'#BFCCD6'} text={`limit - ${limit}`} /> : ''}
        </div>
      </>
    );
  }

  private renderLegend = (md: MetadataType) => {
    if (md.category) {
      return md.category;
    } else if (md.version) {
      return `${md.sdk} - ${md.version}`;
    } else if (md.sdk) {
      return md.sdk;
    } else if (md.kind) {
      return md.kind;
    } else {
      return 'All connections';
    }
  };

  public renderToolTip = (md: MetadataType) => {
    switch (this.props.chartType) {
      case ChartTypes.LINE:
        return this.renderLineTooltip(md);
      case ChartTypes.AREA:
        return this.renderMAU(md);
      case ChartTypes.BAR:
        return this.renderADS(md);
      default:
        return null;
    }
  };

  private renderMAU = (metadata: MetadataType) => {
    return metadata.category;
  };

  private renderLineTooltip = (metadata: MetadataType) => {
    if (metadata.category) {
      return metadata.category;
    }
    if (metadata.version) {
      return `${metadata.sdk} - ${metadata.version}`;
    } else if (metadata.sdk) {
      return metadata.sdk;
    } else {
      return '';
    }
  };

  private renderADS = (metadata: MetadataType) => {
    if (metadata) {
      return <code>{metadata.kind}</code>;
    }
    return null;
  };
}
