import { Schema } from 'normalizr';
import * as qs from 'query-string';
import { Observable } from 'rxjs';
import { AjaxRequest } from 'rxjs/ajax';
import { getDefaultDateRangeForHighUsage, SDKType } from '../accounts';
import { UsageResponse } from '../accounts/api';
import config from '../config';
import * as gonfalon from '../gonfalon';
import { request as baseRequest } from '../shared';

const base = config.gonfalon.host;
const ghostBase = config.gonfalon.ghostUrl;

export const ghostingEnabled = !!ghostBase;
export const url = config.gonfalon.publicUrl;

export type OverrideUrl = {
  overrideUrl?: boolean;
};

export const request = <T>(
  urlOrRequest: string | AjaxRequest & OverrideUrl,
  schema?: Schema,
  timeoutOverride?: number,
): Observable<T> => {
  let req: AjaxRequest & OverrideUrl;

  const baseHeaders = {
    'Content-Type': 'application/json',
    ...(config.gonfalon.localPrivateAuth && { 'X-LD-Private': 'allowed' }),
  };

  const baseOptions = {
    // Gonfalon requests should time out after 5s -- likely indicates that VPN is disconnected
    timeout: timeoutOverride || 10000,
    crossDomain: true,
    withCredentials: true,
  };

  if (typeof urlOrRequest === 'string') {
    req = {
      ...baseOptions,
      url: `${base}${urlOrRequest}`,
      headers: baseHeaders,
    };
  } else {
    req = {
      ...urlOrRequest,
      ...baseOptions,
      url: urlOrRequest.overrideUrl ? `${urlOrRequest.url}` : `${base}${urlOrRequest.url}`,
      headers: {
        ...(urlOrRequest.headers as object),
        ...baseHeaders,
      },
    };
  }

  return baseRequest(req, schema);
};

export const requestGhostingCookie = (accountId: string, email: string): Promise<Response | void> => {
  if (!ghostBase) {
    return Promise.reject(null);
  }
  const encodedEmail = encodeURIComponent(email);
  return fetch(`${ghostBase}/private/ghost/${encodedEmail}/${accountId}`, {
    credentials: 'include',
    ...(config.gonfalon.localPrivateAuth && { headers: { 'X-LD-Private': 'allowed' } }),
  });
};

export const getHighAccountUsage = (usageType: SDKType, dates?: { to: string; from: string }) => {
  return gonfalon.request<UsageResponse>({
    url: `/private/high-usage/stream/${usageType}?${qs.stringify(dates ? dates : getDefaultDateRangeForHighUsage())}`,
  });
};
