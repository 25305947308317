import { Tooltip } from '@blueprintjs/core';
import * as cx from 'classnames';
import * as React from 'react';
import './styles.scss';

type CopyToClipboardProps = Readonly<{
  content: string;
  children?: any;
  className?: string;
}>;

type CopyToClipboardState = Readonly<{
  hasCopied: boolean;
}>;

export class CopyToClipboard extends React.Component<CopyToClipboardProps, CopyToClipboardState> {
  public state = {
    hasCopied: false,
  };

  private inputRef: HTMLInputElement;

  public render() {
    const { content, children, className } = this.props;
    const { hasCopied } = this.state;

    return (
      <div className={cx(className, 'CopyToClipboard')} onClick={this.handleClick} onMouseLeave={this.handleMouseLeave}>
        <input value={content} readOnly ref={this.setInputRef} />
        {hasCopied ? (
          <Tooltip content="Copied" isOpen>
            {children}
          </Tooltip>
        ) : (
          children
        )}
      </div>
    );
  }

  private setInputRef = (node: HTMLInputElement) => {
    this.inputRef = node;
  };

  private handleMouseLeave = () => {
    this.setState({ hasCopied: false });
  };

  private handleClick = () => {
    this.inputRef.select();
    document.execCommand('copy');
    this.setState({ hasCopied: true });
  };
}
