import { Drawer, Icon, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import * as React from 'react';
import { CopyToClipboard } from '../../shared';
import { CustomRole } from '../model';
import './styles.scss';

type StateProps = Readonly<{
  role: CustomRole;
  key: string;
}>;

type State = {
  isOpen: boolean;
};

export class CustomRoleDetails extends React.Component<StateProps, State> {
  public state: State = {
    isOpen: false,
  };
  public render() {
    const { role, key } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        <Tag onClick={() => this.setState({ isOpen: true })} key={key} className="customRoleTag">
          {role.name}
        </Tag>
        <Drawer isOpen={isOpen} onClose={this.closeCustomRoleDetails} className="customRoleDrawer">
          <Icon icon={IconNames.CROSS} iconSize={25} className="closeDrawer" onClick={this.closeCustomRoleDetails} />
          <CopyToClipboard content={JSON.stringify(role, null, 2)}>
            <pre>{JSON.stringify(role, null, 2)}</pre>
          </CopyToClipboard>
        </Drawer>
      </>
    );
  }
  private closeCustomRoleDetails = () => {
    this.setState({ isOpen: false });
  };
}
