import * as React from 'react';
import { Loading, LoadingError, RequestState } from '../../shared';

type RequestStatusProps = {
  title: string;
  requestState: RequestState;
  showOnFetch?: boolean;
};

export class RequestStatus extends React.Component<RequestStatusProps> {
  public render() {
    const { title, requestState, showOnFetch } = this.props;

    if (showOnFetch && requestState.isFetching) {
      return <Loading title={`${title}...`} />;
    }

    if (requestState.error) {
      return <LoadingError title={`Error ${title}`} description={requestState.error.message.toString()} />;
    }

    return <></>;
  }
}
