import { AnchorButton, FormGroup, NumericInput } from '@blueprintjs/core';
import * as accounting from 'accounting';
import * as React from 'react';

type Props = Readonly<{
  name: string;
  value: number | string;
  formsEnabled: boolean;
  presets?: number[];
  onValueChange: (valueAsNumber: number, valueAsString: string) => void;
}>;

export class PlatformTotal extends React.Component<Props> {
  public render() {
    const { name, value, onValueChange } = this.props;

    return this.props.formsEnabled ? (
      <FormGroup className="options" inline label="$" labelFor={this.props.name}>
        <NumericInput
          name={name}
          value={value}
          onValueChange={onValueChange}
          fill
          min={5000}
          majorStepSize={10000}
          minorStepSize={1000}
          selectAllOnFocus
          stepSize={5000}
        />
        {this.props.presets &&
          this.props.presets.map((preset, index) => (
            <AnchorButton
              className="platform"
              key={index}
              small
              text={accounting.formatMoney(preset)}
              onClick={() => this.props.onValueChange(preset, NumericInput.VALUE_EMPTY)}
            />
          ))}
      </FormGroup>
    ) : (
      <>{accounting.formatMoney(value)}</>
    );
  }
}
