import { Icon } from '@blueprintjs/core';

import * as d3 from 'd3';
import { Box, Flex } from 'grid-styled';
import * as numbro from 'numbro';
import * as React from 'react';

import {
  Area,
  Axis,
  AxisOrientation,
  Bar,
  Chart,
  DataPoint,
  Dot,
  getDomainMaximum,
  LinePath,
  Marker,
  MetadataType,
  Mouse,
  resolveDataPoint,
  Responsive,
  timeTickFormatFromDomain,
  Tooltip,
} from '../../shared';
import { UsageResponse } from '../api';
import { ChartTypes } from '../model';
import { pluralize } from '../utils';

export type UsageChartProps = UsageResponse &
  Readonly<{
    chartType: ChartTypes;
    tooltipText: string;
    color: any;
    limit?: number;
    renderToolTip: (metadata: MetadataType) => React.ReactNode;
    focusedSeriesId?: string;
  }>;

export class UsageChart extends React.Component<UsageChartProps> {
  private overlayElement: SVGRectElement | null;

  // getFocusClass is used to determine if an extra class should be applied to the series.
  private getFocusClass(seriesId: string) {
    if (this.props.focusedSeriesId == null) {
      return undefined;
    }
    if (this.props.focusedSeriesId === seriesId) {
      return 'focused';
    } else {
      return 'diminished';
    }
  }

  public render() {
    const { metadata, series, chartType, tooltipText, color, renderToolTip, limit } = this.props;

    return (
      <Responsive
        minWidth={640}
        minHeight={480}
        render={({ width, height }) => {
          const margin = { top: 20, right: 20, bottom: 20, left: 35 };
          const xDomain = d3.extent(series, d => new Date(d.time)) as [Date, Date];
          const xRange = [margin.left, width - margin.right];
          const yDomain = [0, getDomainMaximum(series) || 0];
          const yRange = [height - margin.bottom, margin.top];
          const xFormat = timeTickFormatFromDomain(xDomain);
          const yFormat = this.abbreviateNumber;

          let xScale: any;
          const xScaleLinear = d3
            .scaleUtc()
            .domain(xDomain)
            .range(xRange);

          const xScaleBand = d3
            .scaleBand()
            .domain(series.map((d: any) => d.time))
            .range([margin.left, width - margin.right])
            .padding(0.1);

          const yScale = d3
            .scaleLinear()
            .domain(yDomain)
            .range(yRange)
            .nice();

          if (chartType === ChartTypes.BAR) {
            xScale = xScaleBand;
          } else {
            xScale = xScaleLinear;
          }

          const bisectDate = d3.bisector<DataPoint, Date>(d => new Date(d.time)).right;
          const yTicks = yDomain[1] < 10! ? d3.ticks(0, yDomain[1], yDomain[1]) : yScale.ticks(10);
          let limits: Array<{ time: number; 0: number }>;
          if (limit) {
            limits = series.map(d => {
              return { time: d.time, 0: limit };
            });
          }

          return (
            <Mouse
              render={({ mouseData, onMove }) => (
                <>
                  <Chart width={width} height={height}>
                    <Axis
                      scale={xScale}
                      orientation={AxisOrientation.BOTTOM}
                      tickFormat={xFormat}
                      ticks={series.length < 10 ? 6 : null}
                      transform={`translate(0, ${height - margin.bottom})`}
                    />
                    <Axis
                      scale={yScale}
                      orientation={AxisOrientation.LEFT}
                      tickFormat={yFormat}
                      tickValues={yTicks}
                      transform={`translate(${margin.left}, 0)`}
                    />
                    {limit && (
                      <>
                        <LinePath
                          stroke="#BFCCD6"
                          data={limits}
                          xScale={xScale}
                          yScale={yScale}
                          x={d => new Date(d.time)}
                          y={d => d[0]}
                        />
                      </>
                    )}
                    {chartType === ChartTypes.LINE &&
                      metadata.map((md: { _id: string }, i) => (
                        <LinePath
                          stroke={color(i.toString())}
                          key={i}
                          data={series}
                          xScale={xScale}
                          yScale={yScale}
                          x={d => new Date(d.time)}
                          y={d => d[i]}
                          extraClass={this.getFocusClass(md._id)}
                        />
                      ))}
                    {chartType === ChartTypes.BAR &&
                      series.map((d: any, i) => (
                        <Bar
                          key={i}
                          x={xScale(d.time)}
                          y={yScale(d[0])}
                          width={xScale.bandwidth()}
                          height={yScale(0) - yScale(d[0])}
                          innerRef={this.setOverlayRef}
                          className="Bar"
                        />
                      ))}
                    {chartType === ChartTypes.AREA &&
                      metadata.map((_, i: number) => (
                        <Area
                          key={i}
                          data={series}
                          xScale={xScale}
                          yScale={yScale}
                          height={height - 20}
                          x={d => new Date(d.time)}
                          y={d => {
                            return !d[1] ? d[0] : d[1] + d[0];
                          }}
                        />
                      ))}
                    <Bar
                      x={0}
                      y={0}
                      width={width}
                      height={height}
                      fill="transparent"
                      innerRef={this.setOverlayRef}
                      onMouseLeave={() => onMove({ data: null })}
                      onMouseMove={(event: React.MouseEvent<SVGRectElement>) =>
                        onMove({
                          data: resolveDataPoint(
                            series,
                            xScaleLinear,
                            bisectDate,
                            d => new Date(d.time),
                            this.overlayElement,
                            event,
                          ),
                        })
                      }
                    />
                    {mouseData && (
                      <>
                        <Marker
                          x1={xScale(mouseData.time)}
                          y1={margin.top}
                          x2={xScale(mouseData.time)}
                          y2={height - margin.bottom}
                        />

                        {metadata.map((_, i) => <Dot key={i} cx={xScale(mouseData.time)} cy={yScale(mouseData[i])} />)}
                      </>
                    )}
                  </Chart>
                  {mouseData && (
                    <Tooltip key={mouseData.time} top={margin.top} left={xScale(mouseData.time)}>
                      <strong>{xFormat(new Date(mouseData.time))}</strong>
                      {metadata.map((md: MetadataType, i) => (
                        <Flex key={i} justifyContent="space-between">
                          <Box py={1} pb={2}>
                            <Icon
                              icon="symbol-square"
                              iconSize={16}
                              title={null}
                              color={color(i.toString())}
                              className="Icon"
                            />
                            {yFormat(mouseData[i])} {pluralize(tooltipText, mouseData[i])} {renderToolTip(md)}
                          </Box>
                        </Flex>
                      ))}
                      <div className="usageTooltip" />
                    </Tooltip>
                  )}
                </>
              )}
            />
          );
        }}
      />
    );
  }

  private abbreviateNumber = (value: number) => {
    const formatOptions: { average: boolean; mantissa: number; trimMantissa: boolean } = {
      average: true,
      mantissa: 1,
      trimMantissa: true,
    };
    return numbro(value).format(formatOptions);
  };

  private setOverlayRef = (element: SVGRectElement | null) => {
    this.overlayElement = element;
  };
}
