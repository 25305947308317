import * as qs from 'query-string';
import { createPatch } from 'rfc6902';
import config from '../config';
import * as gonfalon from '../gonfalon';
import { Settings } from '../settings';
import { request as baseRequest } from '../shared';
import { Metadata, Series } from '../shared';
import {
  Account,
  AuditLogEntry,
  AuditLogEntryDetail,
  CatfoodFlagKeys,
  CatfoodProjectKeys,
  CustomRole,
  LegacySubscription,
  Member,
  MemberSessions,
  MetricType,
  OwnerPromotion,
  Plan,
  Project,
  SamlConfig,
  SDKType,
  Subscription,
  SubscriptionUsage,
  UsageFilters,
  UsageOrigin,
  UsageTypes,
} from './model';

import * as schema from './schema';

const expirationTrialUrl = 'https://gonfalon-internal.ld.catamorphic.com/api/v2/scheduled-flags/default/production';

export type AccountListResponse = Readonly<{
  entities: {
    accounts: {
      [id: string]: Account;
    };
  };
  result: {
    _links: gonfalon.ILinks;
    items: string[];
  };
}>;

export type AccountResponse = Readonly<{
  entities: {
    accounts: {
      [id: string]: Account;
    };
  };
}>;

const fetchEndpoint = ({ q, unpaid, overage }: { q?: string; unpaid?: boolean; overage?: MetricType | 'any' }) => {
  const search = qs.stringify({ q, unpaid: unpaid ? 1 : undefined, overage });
  return `${gonfalon.accountListingsRoot}?${search}`;
};

export const getAccounts = ({ q, unpaid, overage }: { q?: string; unpaid?: boolean; overage?: MetricType | 'any' }) =>
  gonfalon.request<AccountListResponse>(
    fetchEndpoint({ q: q ? q.trim() : undefined, unpaid, overage }),
    schema.accounts,
  );

export const getAccount = (id: string) =>
  gonfalon.request<AccountResponse>(`${gonfalon.accountsRoot}/${id}`, schema.account);

export type CatfoodFlagResponse = {
  value: any;
};

export const fetchCatfoodFlag = (projectKey: CatfoodProjectKeys, flagKey: CatfoodFlagKeys, accountId: string) => {
  const req = {
    url: `${config.provisioning.host}/api/flags/${projectKey}/${flagKey}/${accountId}`,
    headers: { 'Content-Type': 'application/json' },
    timeout: 5000,
  };
  return baseRequest<CatfoodFlagResponse>(req);
};

export const getLegacyAccountSubscription = (account: Account) =>
  gonfalon.request<LegacySubscription>(account._links.subscription.href, undefined, 16000);

export const getAccountSubscription = (account: Account) =>
  gonfalon.request<Subscription>(`/private/billingv2/accounts/${account._id}/subscription`);

export const getAccountSubscriptionUsage = (account: Account) =>
  gonfalon.request<SubscriptionUsage>(`/private/billingv2/accounts/${account._id}/subscription/usage`);

export type MemberResponse = Readonly<{
  entities: {
    members: {
      [id: string]: Member;
    };
  };
  result: {
    totalCount: number;
    _links: {
      first: {
        href: string;
      };
      last: {
        href: string;
      };
      next: {
        href: string;
      };
      prev: {
        href: string;
      };
      self: {
        href: string;
      };
    };
  };
}>;

export const changeAccountSubscriptionDates = (account: Account, date: object) => {
  return gonfalon.request<Subscription>({
    method: 'PUT',
    url: `/private/billingv2/accounts/${account._id}/subscription/dates`,
    body: date,
  });
};

export const changeEnterpriseTrialCampaignDates = (account: Account, date: { startTime: string; endTime: string }) => {
  return gonfalon.request<Subscription>({
    method: 'PATCH',
    url: `/private/billingv2/accounts/${account._id}/subscription/enterprise-campaign`,
    body: date,
  });
};

export const patchAccountSubscription = (
  account: Account,
  prevSubscription: Subscription,
  nextSubscription: Subscription,
) => {
  const patch = createPatch(prevSubscription, nextSubscription);
  return gonfalon.request<Subscription>({
    method: 'PATCH',
    url: `/private/billingv2/accounts/${account._id}/subscription`,
    body: patch,
  });
};

export const patchLegacyAccountSubscription = (
  account: Account,
  prevSubscription: LegacySubscription,
  nextSubscription: LegacySubscription,
) => {
  const patch = createPatch(prevSubscription, nextSubscription);
  return gonfalon.request<LegacySubscription>({
    method: 'PATCH',
    url: account._links.subscription.href,
    body: patch,
  });
};

export const cancelSubscription = (account: Account, endImmediately: boolean) => {
  const queryParam = endImmediately ? '?endImmediately=true' : '';
  return gonfalon.request<{}>({
    method: 'DELETE',
    url: `/private/accounts/${account._id}/subscription${queryParam}`,
  });
};

export type AuditLogEntryResponse = Readonly<{
  items: AuditLogEntry[];
  _links: gonfalon.ILinks;
}>;

export const getAccountAuditLogEntries = ({
  account,
  nextUrl,
  query,
  spec,
  date,
}: {
  account: Account;
  nextUrl?: string;
  query?: string;
  spec?: string;
  date?: ReadonlyArray<Date | null>;
}) =>
  gonfalon.request<AuditLogEntryResponse>({
    url: getAuditLogUrl(nextUrl, query, spec, date),
    headers: {
      'X-LD-AccountId': account._id,
      'X-LD-EnvId': account._id,
      'X-LD-PrjId': account._id,
    },
  });

export const getAuditLogUrl = (nextUrl?: string, query?: string, spec?: string, date?: ReadonlyArray<Date | null>) => {
  /* default to rollup to always display parent entries */
  const defaultUrl: string = '/api/v2/auditlog?';
  const after = date && date[0];
  const before = date && date[1];

  const searchParams = new URLSearchParams('rollup=1');

  if (nextUrl) {
    return nextUrl;
  } else {
    if (query) {
      searchParams.append('q', query);
    }
    if (spec) {
      searchParams.append('spec', spec);
    }
    if (after) {
      searchParams.append('after', `${after}`);
    }
    if (before) {
      searchParams.append('before', `${before}`);
    }
    return defaultUrl + searchParams.toString();
  }
};

export type AuditLogEntryDetailResponse = Readonly<{
  items: AuditLogEntryDetail;
}>;

export const getAccountAuditLogEntryDetail = (account: Account, entryUrl: string) =>
  gonfalon.request<AuditLogEntryDetailResponse>({
    url: entryUrl,
    headers: {
      'X-LD-AccountId': account._id,
      'X-LD-EnvId': account._id,
      'X-LD-PrjId': account._id,
    },
  });

const getAccountMembersUrl = (account: Account, enablePaginationOfMembers: boolean, url?: string) => {
  if (enablePaginationOfMembers) {
    return {
      method: 'GET',
      url: `${url ? url : '/api/v2/members?limit=20&offset=0'}`,
      headers: {
        'ld-api-version': 'beta',
        'X-LD-AccountId': account._id,
        'X-LD-EnvId': account._id,
        'X-LD-PrjId': account._id,
      },
    };
  } else {
    return account._links.members.href;
  }
};

export const getAccountMembers = (account: Account, enablePaginationOfMembers: boolean, url?: string) =>
  gonfalon.request<MemberResponse>(getAccountMembersUrl(account, enablePaginationOfMembers, url), schema.members);

export type MemberSessionsResponse = Readonly<{
  entities: {
    memberSessions: {
      [id: string]: MemberSessions;
    };
  };
}>;

export const getAccountMemberSessions = (account: Account) =>
  gonfalon.request<MemberSessionsResponse>(
    {
      url: `/private/accounts/${account._id}/sessions`,
      headers: {
        'X-LD-AccountId': account._id,
        'X-LD-EnvId': account._id,
        'X-LD-PrjId': account._id,
      },
    },
    schema.memberSessions,
  );

export type ProjectResponse = Readonly<{
  entities: {
    projects: {
      [id: string]: Project;
    };
  };
}>;

export const getAccountProjects = (account: Account) =>
  gonfalon.request<ProjectResponse>(
    {
      url: account._links.projects.href,
      headers: {
        'X-LD-AccountId': account._id,
        'X-LD-EnvId': account._id,
        'X-LD-PrjId': account._id,
        'LD-Api-Version': '20210729',
      },
    },
    schema.projects,
  );

export type CustomRoleResponse = Readonly<{
  entities: {
    customRoles: {
      [id: string]: CustomRole;
    };
  };
}>;

export const getAccountCustomRoles = (account: Account) =>
  gonfalon.request<CustomRoleResponse>(account._links.customRoles.href, schema.customRoles);

export type UsageResponse = Readonly<{
  metadata: Metadata;
  series: Series;
}>;

export type SDKResponse = Readonly<{
  sdkVersions: { [s: string]: Array<{ sdk: string; version: string }> };
}>;

export const getSdkVersions = (account: Account, sdkType: SDKType) =>
  gonfalon.request<UsageResponse>({
    url: `/api/v2/usage/streams/${sdkType}/sdkversions`,
    headers: {
      'LD-Api-Version': 'beta',
      'X-LD-AccountId': account._id,
      'X-LD-EnvId': account._id,
      'X-LD-PrjId': account._id,
    },
  });

export const getAccountUsage = (
  account: Account,
  usageType: UsageTypes,
  mauType: UsageTypes | undefined,
  metric: UsageOrigin,
  filters: UsageFilters,
  settings?: Settings,
) => {
  // TODO: clean up
  if (filters && filters.sdk) {
    return gonfalon.request<SDKResponse>({
      url: `/api/v2/usage/streams/${usageType}/bysdkversion?` + qs.stringify(filters),
      headers: {
        'LD-Api-Version': 'beta',
        'X-LD-AccountId': account._id,
        'X-LD-EnvId': account._id,
        'X-LD-PrjId': account._id,
      },
    });
  } else {
    return gonfalon.request<UsageResponse>({
      url: getAccountUsageUrl(usageType, mauType, metric, settings) + qs.stringify(filters),
      headers: {
        'LD-Api-Version': 'beta',
        'X-LD-AccountId': account._id,
        'X-LD-EnvId': account._id,
        'X-LD-PrjId': account._id,
      },
    });
  }
};

const getAccountUsageUrl = (
  usageType: UsageTypes,
  mauType: UsageTypes | undefined,
  metric: string,
  settings?: Settings,
) => {
  if (mauType) {
    if (settings && settings.enableDetailedMauCharts) {
      return `/api/v2/usage/mau?sdktype=${usageType}&`;
    } else {
      return `/api/v2/usage/${metric}/${mauType}?group=${usageType}&`;
    }
  } else {
    return `/api/v2/usage/${metric}/${usageType}?`;
  }
};

export const putAccountOwner = (account: Account, promotion: OwnerPromotion) => {
  return gonfalon.request<MemberResponse>(
    {
      method: 'PUT',
      url: `/private/accounts/${account._id}/owner`,
      body: promotion,
    },
    schema.members,
  );
};

export const putSubscription = (account: Account, plan: Plan) =>
  gonfalon.request<Subscription>({
    method: 'PUT',
    url: `/private/billingv2/accounts/${account._id}/subscription`,
    body: plan,
  });

export const postTrialFeatureExpiration = (account: Account, flagKey: string, date: Date) => {
  const body = {
    flagKey,
    scheduledTime: date,
    operations: [
      {
        action: 'updateTargets',
        op: 'remove',
        value: account._id,
        variationIndex: 0,
      },
    ],
  };
  return gonfalon.request<Account>({
    method: 'POST',
    url: expirationTrialUrl,
    headers: {
      'X-LD-AccountId': account._id,
      'X-LD-EnvId': account._id,
      'X-LD-PrjId': account._id,
    },
    overrideUrl: true,
    body: JSON.stringify(body),
  });
};

export const patchTrialFeature = (account: Account, flagKey: string) => {
  return gonfalon.request<Account>({
    method: 'PATCH',
    headers: {
      'X-LD-AccountId': account._id,
      'X-LD-EnvId': account._id,
      'X-LD-PrjId': account._id,
    },
    url: `${expirationTrialUrl}/${flagKey}`,
    overrideUrl: true,
    body: [{ op: 'add', path: '/environments/production/targets/0/values/3', value: account._id }], // TODO: do not hard code
  });
};

export const getScheduledFlagsByKey = (account: Account, flagKey: string) => {
  return gonfalon.request<Account>({
    method: 'GET',
    headers: {
      'X-LD-AccountId': account._id,
      'X-LD-EnvId': account._id,
      'X-LD-PrjId': account._id,
    },
    overrideUrl: true,
    url: `${expirationTrialUrl}/${flagKey}`,
  });
};

export const postProfileMfaDisable = (account: Account, member: Member) => {
  const body = {
    memberId: member._id,
  };
  return gonfalon.request<Member>({
    method: 'POST',
    url: `/private/accounts/${account._id}/owner/mfa/disable`,
    body: JSON.stringify(body),
  });
};

export const postAccountSsoDisable = (account: Account) => {
  return gonfalon.request<SamlConfig>({
    method: 'POST',
    url: `/private/accounts/${account._id}/sso/disable`,
  });
};
