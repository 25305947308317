import {
  Button,
  ButtonGroup,
  Icon,
  Intent,
  Menu,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import './components/AccountSearch/styles.scss';
import { SDKOption } from './model';

type ISDKFilterProps = {
  onSelect: (value: SDKOption) => void;
  options: SDKOption[];
  value: string;
  groupedSDKs: { [s: string]: SDKOption[] };
  selectedOption: SDKOption;
  onClear: () => void;
};

interface IFilterState {
  value?: string;
  isOpen: boolean;
}

export class SDKFilter extends React.Component<ISDKFilterProps, IFilterState> {
  constructor(props: ISDKFilterProps) {
    super(props);
    this.state = {
      value: props.value,
      isOpen: false,
    };
  }

  public render() {
    const { options, onClear } = this.props;
    const { isOpen } = this.state;

    return (
      <ButtonGroup>
        <Popover
          isOpen={isOpen}
          interactionKind={PopoverInteractionKind.CLICK}
          position={Position.BOTTOM}
          onInteraction={this.handlePopoverInteraction}
        >
          <Button intent={Intent.NONE}>
            {this.getDescription()}{' '}
            <span className="UsageChart--chevron-down">
              <Icon icon="chevron-down" className="DownCarrot" />
            </span>
          </Button>
          <div>
            <Menu>
              {options.map(
                (d: SDKOption, i: number) =>
                  d.isDivider ? (
                    <div className="bp3-menu-divider" key="divider" />
                  ) : (
                    <MenuItem
                      className={d.groupHeader ? 'GroupHeader' : ''}
                      text={d.name}
                      onClick={() => this.handleSelect(d)}
                      key={i}
                    />
                  ),
              )}
            </Menu>
          </div>
        </Popover>
        <Button icon={IconNames.SMALL_CROSS} onClick={() => onClear()} />
      </ButtonGroup>
    );
  }

  private getDescription = () => {
    const { groupedSDKs, selectedOption } = this.props;

    const { sdk, version }: SDKOption = selectedOption;

    const validSelectedSdkVersions: SDKOption[] = selectedOption.sdk && groupedSDKs[sdk] ? groupedSDKs[sdk] : [];

    if (!validSelectedSdkVersions.length) {
      return 'All SDKs';
    }

    const selectedSDK: SDKOption[] = validSelectedSdkVersions.filter((d: SDKOption) => d.version === version);

    return selectedSDK.length ? `${groupedSDKs[sdk][0].sdk}/${selectedSDK[0].version}` : groupedSDKs[sdk][0].sdk;
  };

  private handleSelect = (option: SDKOption) => {
    this.props.onSelect(option);
  };

  private handlePopoverInteraction = (nextOpenState: boolean) => {
    if (this.props.value) {
      this.setState(prevState => ({
        isOpen: nextOpenState,
        value: nextOpenState ? prevState.value : this.props.value,
      }));
    }
  };
}
