import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Breadcrumbs, MainContent, MainNav, MainPanel } from '../../shared';
import {
  AccountListPageContainer,
  AccountPageContainer,
  AllAccountListPageContainer,
  OverageAccountListPageContainer,
  UnpaidAccountListPageContainer,
} from '../containers';

export const AccountsWrapper = ({ match }: RouteComponentProps<{}>) => {
  return (
    <MainPanel>
      <MainNav>
        <Route path="/:path" component={Breadcrumbs} />
      </MainNav>
      <MainContent>
        <Helmet>
          <title>Accounts</title>
        </Helmet>
        <Switch>
          <Route exact path={`${match.url}`} component={AccountListPageContainer} />

          <Route path={`${match.url}/all`} component={AllAccountListPageContainer} />

          <Route path={`${match.url}/unpaid`} component={UnpaidAccountListPageContainer} />

          <Route path={`${match.url}/overages`} component={OverageAccountListPageContainer} />

          <Route path={`${match.url}/:accountId`} component={AccountPageContainer} />
        </Switch>
      </MainContent>
    </MainPanel>
  );
};
