import { normalize, Schema } from 'normalizr';
import { Observable, of, throwError } from 'rxjs';
import { ajax, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { OverrideUrl } from '../gonfalon';

export type RequestError = Readonly<{
  status: number;
  message: string | object;
}>;

const checkStatus = (response: AjaxResponse) => {
  if (response.status >= 200 && response.status < 400) {
    return of(response);
  } else {
    return throwError(response);
  }
};

const mapStatusToMessage = (status: number) => {
  switch (status) {
    case 404:
      return 'page not found';
    case 401:
    case 403:
      return 'User is unauthorized to make this request';
    default:
      return 'Unknown server error';
  }
};

export const request = <T>(urlOrRequest: string | AjaxRequest & OverrideUrl, schema?: Schema): Observable<T> => {
  return ajax(urlOrRequest).pipe(
    mergeMap(checkStatus),
    map(res => {
      if (res.responseType === 'json' && res.response && schema) {
        return {
          ...normalize(res.response, schema),
        };
      } else {
        return res.response;
      }
    }),
    catchError(res => {
      if (res.responseType === 'json' && res.response) {
        return throwError({
          status: res.status,
          message: res.response,
        });
      } else {
        return throwError({
          status: res.status,
          message: mapStatusToMessage(res.status),
        });
      }
    }),
  );
};
