import { Button, Checkbox, Dialog, Intent, Tooltip } from '@blueprintjs/core';
import * as React from 'react';
import { Account } from '../model';

type CancelPlanButtonProps = Readonly<{
  onCancelSubscription: (account: Account, endImmediately: boolean) => void;
  account: Account;
  cancelImmediatelyIsEnabled: boolean;
}>;

type CancelPlanButtonState = Readonly<{
  isOpen: boolean;
  isCheckboxChecked: boolean;
}>;

export class CancelPlanButton extends React.PureComponent<CancelPlanButtonProps, CancelPlanButtonState> {
  public state: CancelPlanButtonState = {
    isOpen: false,
    isCheckboxChecked: false,
  };

  public render() {
    const { onCancelSubscription, account, cancelImmediatelyIsEnabled } = this.props;
    const { isOpen, isCheckboxChecked } = this.state;

    return (
      <>
        <Button intent={Intent.DANGER} onClick={() => this.setState({ isOpen: true })}>
          Cancel Plan
        </Button>
        <Dialog
          isOpen={isOpen}
          onClose={() => this.setState({ isOpen: false })}
          title="Cancel Immediately or At End of Subscription?"
        >
          <div className="bp3-dialog-body">
            <p style={{ textAlign: 'center' }}>Cancel Immediately or At End of Subscription?</p>
            <p>Cancel at end of subscription: Standard option appropriate for most use situations</p>
            <p>
              Cancel immediately: Only for use in specific situations where the customer is past due.<br />
              <span style={{ fontWeight: 'bold', fontSize: 'smaller' }}>For Finance and Internal Systems use only</span>
            </p>
            <Checkbox
              checked={isCheckboxChecked}
              onChange={() => this.setState({ isCheckboxChecked: !isCheckboxChecked })}
            >
              Confirm you want to cancel this account
            </Checkbox>
          </div>
          <div className="bp3-dialog-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Tooltip
              content={!isCheckboxChecked ? 'Confirm you really want to cancel this plan' : ''}
              disabled={isCheckboxChecked}
            >
              <Button
                intent={Intent.DANGER}
                onClick={() => this.handleConfirm(onCancelSubscription, account, false)} // Use handleConfirm for Cancel At End of Subscription
                style={{ cursor: isCheckboxChecked ? 'pointer' : 'not-allowed' }}
                disabled={!isCheckboxChecked}
              >
                Cancel At End of Subscription
              </Button>
            </Tooltip>

            {cancelImmediatelyIsEnabled && (
              <Tooltip
                content={!isCheckboxChecked ? 'Confirm you really want to cancel this plan' : ''}
                disabled={isCheckboxChecked}
              >
                <Button
                  intent={Intent.DANGER}
                  onClick={() => this.handleConfirm(onCancelSubscription, account, true)} // Use . handleConfirm for Cancel Immediately
                  disabled={!isCheckboxChecked}
                  style={{ marginLeft: 'auto' }}
                >
                  Cancel Immediately
                </Button>
              </Tooltip>
            )}
          </div>
        </Dialog>
      </>
    );
  }

  private handleConfirm(
    onCancelSubscription: (account: Account, endImmediately: boolean) => void,
    account: Account,
    endImmediately: boolean,
  ) {
    onCancelSubscription(account, endImmediately);
    this.setState({ isOpen: false, isCheckboxChecked: false });
  }
}
