import { ButtonGroup, Icon, Menu, MenuItem, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';
import * as cx from 'classnames';
import * as React from 'react';
import './styles.scss';

export type FilterItem = {
  name?: string;
  value: string;
};

export type FilterOption =
  | FilterItem
  | {
      isDivider: boolean;
    };

type Props = Readonly<{
  options: FilterOption[];
  onChange: (val: string) => void;
  label: string;
  initialValue?: string;
  className?: string;
}>;

type State = {
  isOpen: boolean;
  selectedItem?: FilterItem;
};

export class FilterMenu extends React.Component<Props, State> {
  private handlePopoverInteraction = (nextOpenState: boolean) => {
    this.setState((prevState: State) => {
      return {
        isOpen: nextOpenState,
        selectedValue: prevState.selectedItem,
      };
    });
  };

  private handleItemSelect = (item: FilterItem) => {
    const { onChange } = this.props;
    this.setState((prevState: State) => {
      return {
        isOpen: prevState.isOpen,
        selectedItem: item,
      };
    });
    onChange(item.value);
  };

  public state: State = {
    isOpen: false,
    selectedItem: undefined,
  };

  public render() {
    const { options, label, className, initialValue } = this.props;
    let filterValue: FilterItem | undefined = this.state.selectedItem;
    if (!filterValue) {
      filterValue = options.find(o => 'value' in o && o.value === initialValue) as FilterItem;
    }
    return (
      <ButtonGroup className={cx('FilterMenu', className)}>
        <div className="FilterMenu-label">{label}:</div>

        <Popover
          isOpen={this.state.isOpen}
          interactionKind={PopoverInteractionKind.CLICK}
          position={Position.BOTTOM_LEFT}
          onInteraction={this.handlePopoverInteraction}
          className="FilterMenu-popover"
        >
          <p className="FilterMenu-trigger">
            {filterValue ? filterValue.name : 'All'} <Icon icon="chevron-down" className="DownCarrot" />
          </p>

          <Menu className="FilterMenu-options">
            {options.map(
              (item: FilterOption, i: number) =>
                'isDivider' in item ? (
                  <div className="bp3-menu-divider" key="divider" />
                ) : (
                  <MenuItem text={item.name} onClick={() => this.handleItemSelect(item)} key={i} />
                ),
            )}
          </Menu>
        </Popover>
      </ButtonGroup>
    );
  }
}
