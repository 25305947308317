import { Button, Icon, Intent, Menu, MenuItem, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';
import * as React from 'react';
import { SDKType } from '../model';
import './HighUsageFilter.scss';

type HighUsageFilterProps = {
  onSelect: (value: SDKType) => void;
  options: [{ name: string; value: SDKType }, { name: string; value: SDKType }];
  value: SDKType;
  selectedOption: SDKType;
};

type HighUsageFilterState = {
  value?: string;
  isOpen: boolean;
};

export class HighUsageFilter extends React.Component<HighUsageFilterProps, HighUsageFilterState> {
  public state: HighUsageFilterState = { value: this.props.value, isOpen: false };

  public render() {
    const { options } = this.props;
    const { isOpen } = this.state;

    return (
      <span className="HighUsage--filter">
        <Popover
          isOpen={isOpen}
          className="HighUsage--popover"
          interactionKind={PopoverInteractionKind.CLICK}
          position={Position.BOTTOM}
          onInteraction={this.handlePopoverInteraction}
        >
          <Button intent={Intent.NONE}>
            {this.getDescription()}
            <span className=" HighUsageFilter--chevron-down">
              <Icon icon="chevron-down" className="DownCarrot" />
            </span>
          </Button>
          <div>
            <Menu>
              {options.map((d: { name: string; value: SDKType }, i: number) => (
                <MenuItem text={d.name} onClick={() => this.handleSelect(d.value)} key={i} />
              ))}
            </Menu>
          </div>
        </Popover>
      </span>
    );
  }

  private getDescription = () => {
    const { selectedOption } = this.props;
    return selectedOption === SDKType.SERVER ? 'Server connections' : 'Client connections';
  };

  private handleSelect = (value: SDKType) => {
    this.props.onSelect(value);
  };

  private handlePopoverInteraction = (nextOpenState: boolean) => {
    if (this.props.value) {
      this.setState(prevState => ({
        isOpen: nextOpenState,
        value: nextOpenState ? prevState.value : this.props.value,
      }));
    }
  };
}
