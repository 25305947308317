import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Actions } from '../actions';
import { clientSDKSelector, serverSDKSelector } from '../selectors';

import { Account, SDKOption, SDKType } from '../model';
import { SDKFilter } from '../SDKFilter';

type OwnProps = Readonly<{
  account: Account;
  sdkType: SDKType;
}>;

type StateProps = Readonly<{
  isFetching: boolean;
  SDKs: SDKOption[];
  groupedSDKs: object;
}>;

type DispatchProps = Readonly<{
  onFetchSDKVersions: () => void;
}>;

type SDKVersionProps = Readonly<{
  SDKs: SDKOption[];
  groupedSDKs: { [s: string]: SDKOption[] };
  onFetchSDKVersions: () => void;
  onSelect: (option: SDKOption) => void;
  selectedSDK: SDKOption;
  onClear: () => void;
}>;

export class SDKVersions extends React.Component<SDKVersionProps> {
  public componentDidMount() {
    this.props.onFetchSDKVersions();
  }

  public render() {
    const { groupedSDKs, selectedSDK, onClear } = this.props;

    return (
      <SDKFilter
        onSelect={(option: SDKOption) => this.handleSelect(option)}
        options={this.getOptions()}
        value={'test'}
        groupedSDKs={groupedSDKs}
        onClear={onClear}
        selectedOption={selectedSDK}
      />
    );
  }

  private handleSelect = (option: SDKOption) => {
    const selectedSDK: SDKOption = { sdk: option.sdk, version: option.version, name: option.name, value: option.value };
    this.props.onSelect(selectedSDK);
  };

  private getOptions = () => {
    const { SDKs } = this.props;

    if (SDKs.length === 0) {
      return [{ name: 'All SDKs', value: '', sdk: '' }];
    }

    return [{ name: 'All SDKs', value: '', sdk: '' }, { isDivider: true, sdk: '', name: '', value: '' }, ...SDKs];
  };
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => ({
  onFetchSDKVersions: () =>
    dispatch(
      Actions.fetchSDKVersions({
        account: ownProps.account,
        sdkType: ownProps.sdkType,
      }),
    ),
});

export const ServerSDKVersionsContainer = connect<StateProps, DispatchProps, OwnProps>(
  serverSDKSelector,
  mapDispatchToProps,
)(SDKVersions);

export const ClientSDKVersionsContainer = connect<StateProps, DispatchProps, OwnProps>(
  clientSDKSelector,
  mapDispatchToProps,
)(SDKVersions);
