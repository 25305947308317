import { noop, Subscription } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { SlackMessage } from './model';

export const logMessage = (message: SlackMessage, webHookUrl: string): Subscription | undefined => {
  if (!webHookUrl) {
    return;
  }

  const { user, ...props } = message;
  const body = {
    attachments: [
      {
        ...props,
        footer: user.user_id,
      },
    ],
  };

  const req = {
    method: 'POST',
    url: webHookUrl,
    body: JSON.stringify(body),
    crossDomain: true,
  };

  // tslint:disable-next-line:no-console
  return ajax(req).subscribe(noop, error => console.error('Failed to post to Slack:', error.message));
};
