import { Classes } from '@blueprintjs/core';
import * as cx from 'classnames';
import * as React from 'react';
import './styles.scss';

type TableProps = Readonly<{
  className?: any;
  children?: any;
}>;

export const Table = ({ className, children }: TableProps) => (
  <table className={cx(Classes.HTML_TABLE, Classes.HTML_TABLE_STRIPED, Classes.INTERACTIVE, className, 'Table')}>
    {children}
  </table>
);

type TableCellProps = Readonly<{
  children?: any;
  className?: any;
  align?: 'left' | 'center' | 'right';
}>;

export const TableCell = ({ align, className, children }: TableCellProps) => (
  <td className={cx(className, 'TableCell', `TableCell-${align}`)}>{children}</td>
);
