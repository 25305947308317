import config from './config';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum-slim';

const applicationId = config.datadog.applicationId || '';
const clientToken = config.datadog.clientToken || '';
const environment = config.launchdarkly.environment;
const site = config.datadog.site || 'datadoghq.com';

const baseConfig = {
  clientToken,
  site,
  service: 'Vitalstatistix',
  env: environment,
  version: process.env.REVISION,
  sampleRate: 100,
};

export const initDatadogRUM = () => {
  if (applicationId && clientToken) {
    datadogRum.init({
      ...baseConfig,
      ...{
        applicationId,
        replaySampleRate: 0,
      },
    });
  }

  if (clientToken) {
    datadogLogs.init({
      ...baseConfig,
      ...{
        forwardErrorsToLogs: true,
      },
    });
  }
};
