import {
  Button,
  Classes,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core';
import { DatePicker, IDatePickerProps } from '@blueprintjs/datetime';
import * as moment from 'moment';
import * as React from 'react';
import './styles.scss';

type IDatePickerButtonProps = IDatePickerProps & {
  children: React.ReactElement<any>;
  hidePresets?: boolean;
  onDateChange: (date: Date) => void;
};

interface IDatePickerButtonState {
  value?: Date;
  isOpen: boolean;
}

export class DatePickerButton extends React.Component<IDatePickerButtonProps, IDatePickerButtonState> {
  constructor(props: IDatePickerButtonProps) {
    super(props);
    this.state = {
      value: props.value,
      isOpen: false,
    };
  }

  public componentWillReceiveProps(nextProps: IDatePickerButtonProps) {
    this.setState({ value: nextProps.value });
  }

  public render() {
    const { children, hidePresets, maxDate, value: originalValue } = this.props;
    const { value, isOpen } = this.state;
    const isDirty = !moment.utc(value).isSame(moment.utc(originalValue));

    return (
      <Popover
        isOpen={isOpen}
        interactionKind={PopoverInteractionKind.CLICK}
        position={Position.BOTTOM}
        onInteraction={this.handlePopoverInteraction}
      >
        {children}
        <div className="DatePickerButton">
          <DatePicker value={value} maxDate={maxDate} onChange={this.handleDateChange} />

          {!hidePresets && (
            <Menu>
              <MenuDivider title="Presets" />
              <MenuItem shouldDismissPopover={false} text="Add 7 days" onClick={this.handleForward7Days} />
              <MenuItem shouldDismissPopover={false} text="Add 14 days" onClick={this.handleForward14Days} />
            </Menu>
          )}

          <div className="DatePickerButton-action">
            <Button className={Classes.POPOVER_DISMISS}>Cancel</Button>
            <Button disabled={!isDirty} intent={Intent.PRIMARY} onClick={this.handleSave}>
              Save
            </Button>
          </div>
        </div>
      </Popover>
    );
  }

  private handlePopoverInteraction = (nextOpenState: boolean) => {
    this.setState(prevState => ({
      isOpen: nextOpenState,
      value: nextOpenState ? prevState.value : this.props.value,
    }));
  };

  private handleForward7Days = () => {
    const { value } = this.props;
    const forward7Days = moment
      .utc(value)
      .add(7, 'days')
      .toDate();
    this.setState({ value: forward7Days });
  };

  private handleForward14Days = () => {
    const { value } = this.props;
    const forward14Days = moment
      .utc(value)
      .add(14, 'days')
      .toDate();
    this.setState({ value: forward14Days });
  };

  private handleDateChange = (date: Date, hasUserManuallySelectedDate: boolean) => {
    if (!date || !hasUserManuallySelectedDate) {
      return;
    }
    this.setState({ value: date });
  };

  private handleSave = () => {
    const { onDateChange } = this.props;
    const { value } = this.state;
    if (!value) {
      return;
    }
    this.setState({ isOpen: false });
    onDateChange(value);
  };
}
