import { ActionsUnion, makeActionCreator } from '../shared';
import { Scopes, User } from './model';

export enum ActionTypes {
  CHECKING_AUTH = 'auth/CHECKING_AUTH',
  SIGN_IN = 'auth/SIGN_IN',
  SIGNING_IN = 'auth/SIGNING_IN',
  SIGNED_IN = 'auth/SIGNED_IN',
  SIGN_OUT = 'auth/SIGN_OUT',
  SIGNED_OUT = 'auth/SIGNED_OUT',
}

const createAction = makeActionCreator<ActionTypes>();

export const Actions = {
  checkingAuth: () =>
    createAction({
      type: ActionTypes.CHECKING_AUTH,
    }),
  signIn: () =>
    createAction({
      type: ActionTypes.SIGN_IN,
    }),
  signingIn: () =>
    createAction({
      type: ActionTypes.SIGNING_IN,
    }),
  signedIn: (user: User, scopes: Scopes) =>
    createAction({
      type: ActionTypes.SIGNED_IN,
      user,
      scopes,
    }),
  signOut: () =>
    createAction({
      type: ActionTypes.SIGN_OUT,
    }),
  signedOut: () =>
    createAction({
      type: ActionTypes.SIGNED_OUT,
    }),
};

export type Actions = ActionsUnion<typeof Actions>;
