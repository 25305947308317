import * as d3 from 'd3';
import { subDays, subHours, subMonths } from 'date-fns';
import { DataPoint, Series } from './types';

// Computes the maximum value for a series with multiple values per point
export const getDomainMaximum = (series: Series) =>
  d3.max(series, d => {
    const { time, ...values } = d;
    return d3.max<number>(d3.values(values));
  });

// Finds the data point that is closest to the mouse
export const resolveDataPoint = <U>(
  series: Series,
  scale: any,
  bisector: d3.Bisector<DataPoint, U>['left'] | d3.Bisector<DataPoint, U>['right'],
  accessor: (d: DataPoint) => U,
  element: d3.ContainerElement | null | undefined,
  event: React.MouseEvent,
): DataPoint | null => {
  if (!element) {
    return null;
  }

  const point = d3.clientPoint(element, event);
  const x0 = scale.invert(point[0]);
  const index = bisector(series, x0, 1);
  const d0 = series[index - 1];
  const d1 = series[index];

  let d = d0;
  if (d1 && d1.time) {
    d = +x0 - +accessor(d0) > +accessor(d1) - +x0 ? d1 : d0;
  }

  return d;
};

export const today: Date = new Date();

const modify = (fn: (val: Date) => Date) => {
  const clone = (d: Date) => new Date(d);
  const val = clone(today);
  return fn(val);
};

const pastHour: Date = modify(d => subHours(d, 1));
const past24Hours = modify(d => subHours(d, 24));
const oneWeekAgo = modify(d => subDays(d, 7));
const twoWeeksAgo = modify(d => subDays(d, 14));
const oneMonthAgo = modify(d => subMonths(d, 1));
const twoMonthsAgo = modify(d => subMonths(d, 2));

const createShortcut = (label: string, dateRange: Date[]) => ({
  label,
  dateRange: [dateRange[0], dateRange[1]],
});

export const createUsageShortcuts = () => [
  { last60Min: createShortcut('Last 60 minutes', [pastHour, today]) },
  { last24Hrs: createShortcut('Last 24 hours', [past24Hours, today]) },
  { last7Days: createShortcut('Last 7 days', [oneWeekAgo, today]) },
  { last14Days: createShortcut('Last 14 days', [twoWeeksAgo, today]) },
  { last30Days: createShortcut('Last 30 days', [oneMonthAgo, today]) },
  { last60Days: createShortcut('Last 60 days', [twoMonthsAgo, today]) },
];

export const createMAUUsageShortcuts = () => [
  { last7Days: createShortcut('Last 7 days', [oneWeekAgo, today]) },
  { last14Days: createShortcut('Last 14 days', [twoWeeksAgo, today]) },
  { last30Days: createShortcut('Last 30 days', [oneMonthAgo, today]) },
  { last60Days: createShortcut('Last 60 days', [twoMonthsAgo, today]) },
];
