import { Button, Classes } from '@blueprintjs/core';
import { Intent } from '@blueprintjs/core/lib/esm/common/intent';
import { addYears } from 'date-fns';
import * as React from 'react';
import { Scopes } from '../../auth';
import config from '../../config';
import { DatePickerButton, Time } from '../../shared/components';
import { LineItem, OrderSource } from '../model';
import { dateSuffix, formatNumber, localDateTimeToUTC, utcDateStringToLocalDate } from '../util';

type PlanReviewLineItemSectionProps = {
  title: string;
  isSFDCLineItem: boolean;
  lineItems: LineItem[];
  className: string;
  invertQtySign?: boolean;
  striped: boolean;
  setLaunchOrderEndDate: (orderID: number, endDate: Date) => void;
  scopes: Scopes;
};

export class PlanReviewLineItemSection extends React.Component<PlanReviewLineItemSectionProps> {
  public render() {
    const { title, isSFDCLineItem, lineItems, className, invertQtySign, striped, scopes } = this.props;

    return (
      <>
        {lineItems.map((lineItem, index) => (
          <tr className={striped ? `${className} planReview-striped` : className} key={lineItem.orderID}>
            <td>{!index ? title : ''}</td>
            <td>{lineItem.orderID}</td>
            <td>{lineItem.orderSource}</td>
            <td>
              <a href={`${config.provisioning.sfdcBaseUrl}${lineItem.sfdcOppId}/view`} target="_blank">
                {lineItem.sfdcOppId}
              </a>
            </td>
            <td>
              {lineItem.startDate && (
                <>
                  <Time datetime={lineItem.startDate} format="L" utc />
                  {dateSuffix(lineItem.startDate)}
                </>
              )}
            </td>
            <td>
              {lineItem.endDate && (
                <>
                  <Time datetime={lineItem.endDate} format="L" utc />
                  {dateSuffix(lineItem.endDate)}
                  {lineItem.orderSource === OrderSource.Launch &&
                    scopes.accountProvisioning && (
                      <DatePickerButton
                        onDateChange={this.handleDateChange(lineItem.orderID)}
                        value={utcDateStringToLocalDate(lineItem.endDate)}
                        maxDate={addYears(lineItem.endDate, 2)}
                        hidePresets
                      >
                        <Button className={Classes.MINIMAL} intent={Intent.PRIMARY} icon="edit" small />
                      </DatePickerButton>
                    )}
                </>
              )}
            </td>
            <td>{lineItem.platform}</td>
            <td>{formatNumber(lineItem.products.seats, invertQtySign, isSFDCLineItem)}</td>
            <td>{formatNumber(lineItem.products.hosts, invertQtySign, isSFDCLineItem)}</td>
            <td>{formatNumber(lineItem.products.c_mau, invertQtySign, isSFDCLineItem)}</td>
            <td>{formatNumber(lineItem.products.experiment_events, invertQtySign, isSFDCLineItem)}</td>
            <td>{formatNumber(lineItem.products.experiment_keys, invertQtySign, isSFDCLineItem)}</td>
            <td>{formatNumber(lineItem.products.data_export_events, invertQtySign, isSFDCLineItem)}</td>
            <td>{formatNumber(lineItem.products.sso, invertQtySign, isSFDCLineItem)}</td>
          </tr>
        ))}
      </>
    );
  }

  private handleDateChange = (orderID: number) => {
    return (dateTime: Date) => {
      // The DatePicker returns a localized Date object with the correct year/month/date.
      // Generate a UTC date with these values before sending it back to the API.
      this.props.setLaunchOrderEndDate(orderID, localDateTimeToUTC(dateTime));
    };
  };
}
