import { Alert, Button, FormGroup, Intent, MenuItem } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select';
import { Formik, FormikProps } from 'formik';
import { clone, get } from 'lodash';
import * as React from 'react';
import { Account, AccountRole, Member, OwnerPromotion } from '../model';

import './owner.scss';

const MemberSelect = Select.ofType<Member>();
const RoleSelect = Select.ofType<AccountRole>();

export type OwnerFormProps = {
  account: Account;
  members: Member[];

  isUpdatingAccountOwner: boolean;
  onChangeAccountOwner: (promotion: OwnerPromotion) => void;
};

type OwnerFormState = Readonly<{
  isConfirmationOpen: boolean;
}>;

export class OwnerForm extends React.Component<OwnerFormProps, OwnerFormState> {
  public state = {
    isConfirmationOpen: false,
  };

  private filterMembers: ItemPredicate<Member> = (query, member) => {
    const normalizedMember = member.email.toLowerCase();
    const normalizedQuery = query.toLowerCase();
    return normalizedMember.indexOf(normalizedQuery) >= 0;
  };

  public render() {
    const { members } = this.props;

    const owner = this.getOwner(members);
    if (!owner) {
      return;
    }

    return (
      <Formik
        initialValues={{
          promotedEmail: owner.email,
          requestedBy: '',
          demotedRole: AccountRole.ADMIN,
        }}
        onSubmit={() => this.setState({ isConfirmationOpen: true })}
        component={(form: FormikProps<OwnerPromotion>) => (
          <form onSubmit={form.handleSubmit} className="OwnerForm">
            <FormGroup label="Account owner:" inline={true} helperText={this.getFieldHelperText(form, 'promotedEmail')}>
              <MemberSelect
                items={members.filter(m => m.email !== owner.email)}
                itemRenderer={this.renderMemberItem}
                itemPredicate={this.filterMembers}
                onItemSelect={item => form.setFieldValue('promotedEmail', item.email)}
                filterable={true}
              >
                <Button
                  className="OwnerForm-button"
                  text={form.values.promotedEmail}
                  rightIcon="double-caret-vertical"
                />
              </MemberSelect>
            </FormGroup>

            <FormGroup label="Requested by: " inline={true} helperText={this.getFieldHelperText(form, 'requestedBy')}>
              <MemberSelect
                items={members}
                itemRenderer={this.renderMemberItem}
                itemPredicate={this.filterMembers}
                onItemSelect={item => form.setFieldValue('requestedBy', item.email)}
                filterable={true}
              >
                <Button className="OwnerForm-button" text={form.values.requestedBy} rightIcon="double-caret-vertical" />
              </MemberSelect>
            </FormGroup>

            <FormGroup label="Demoted role:" inline={true} helperText={this.getFieldHelperText(form, 'demotedRole')}>
              <RoleSelect
                items={[AccountRole.ADMIN, AccountRole.WRITER, AccountRole.READER]}
                itemRenderer={this.renderRoleItem}
                onItemSelect={item => form.setFieldValue('demotedRole', item)}
                filterable={false}
              >
                <Button text={form.values.demotedRole} rightIcon="double-caret-vertical" />
              </RoleSelect>
            </FormGroup>

            <Button
              intent={Intent.PRIMARY}
              type="submit"
              disabled={this.props.isUpdatingAccountOwner || this.ownerEmailUnchanged(form)}
            >
              {this.props.isUpdatingAccountOwner ? 'Saving account owner…' : 'Save'}
            </Button>
            <Alert
              intent={Intent.DANGER}
              isOpen={this.state.isConfirmationOpen}
              cancelButtonText="Cancel"
              confirmButtonText="Confirm"
              onConfirm={() => this.handleSubmit(form.values)}
              onCancel={() => this.setState({ isConfirmationOpen: false })}
            >
              Are you sure you want to change the account owner?
            </Alert>
          </form>
        )}
      />
    );
  }

  public handleSubmit = (promotion: OwnerPromotion) => {
    const payload = clone(promotion);
    this.props.onChangeAccountOwner(payload);
    this.setState({ isConfirmationOpen: false });
  };

  private renderMemberItem: ItemRenderer<Member> = (member, props) => {
    return (
      <MenuItem active={props.modifiers.active} key={member.email} text={member.email} onClick={props.handleClick} />
    );
  };

  private renderRoleItem: ItemRenderer<AccountRole> = (role, props) => {
    return <MenuItem active={props.modifiers.active} key={role} text={role} onClick={props.handleClick} />;
  };

  private hasError = (form: FormikProps<OwnerPromotion>, name: string) =>
    get(form.touched, name, false) && get(form.errors, name, null);

  private getFieldHelperText = (form: FormikProps<OwnerPromotion>, name: string) =>
    this.hasError(form, name) ? get(form.errors, name) : undefined;

  private ownerEmailUnchanged = (form: FormikProps<OwnerPromotion>) => {
    return form.values.promotedEmail === form.initialValues.promotedEmail;
  };

  private getOwner = (members: Member[]) => {
    return members.find(m => m.role === AccountRole.OWNER);
  };
}
