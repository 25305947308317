import { Classes } from '@blueprintjs/core';
import * as cx from 'classnames';
import * as React from 'react';

type CardProps = Readonly<{
  elevation?: number;
  children?: any;
}>;

const elevationClass = (e: number) =>
  [Classes.ELEVATION_0, Classes.ELEVATION_1, Classes.ELEVATION_2, Classes.ELEVATION_3, Classes.ELEVATION_4][e];

export const Card = ({ elevation, children }: CardProps) => (
  <div className={cx(Classes.CARD, elevationClass(elevation || 0))}>{children}</div>
);
