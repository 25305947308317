import * as React from 'react';
import { Link } from 'react-router-dom';
import { Table } from '../../shared';
import { NoAccountsMsg, SavedAccount } from '../model';

type SavedAccountListProps = Readonly<{
  noAccountsMsg: string;
  onAddAccountToRecentlyViewed: (recentlyViewedAccount: SavedAccount) => void;
  savedAccounts: SavedAccount[];
  type: string;
}>;

export const SavedAccountList = ({
  noAccountsMsg,
  onAddAccountToRecentlyViewed,
  savedAccounts,
  type,
}: SavedAccountListProps) => {
  const NoAccountsMessage = () => {
    switch (noAccountsMsg) {
      case NoAccountsMsg.FAVORITED:
        return 'You do not have any bookmarked accounts. To bookmark an account, click the star on the account page.';
      case NoAccountsMsg.RECENTLY_VIEWED:
        return 'You do not have any recently viewed accounts.';
    }
    return null;
  };
  return (
    <Table>
      <thead>
        <tr>
          <td className="Recently-Viewed-Header">{type === 'favorited' ? 'Bookmarked' : 'Recently Viewed'}</td>
        </tr>
      </thead>
      <tbody>
        {savedAccounts && savedAccounts.length ? (
          savedAccounts.map(
            a =>
              a && (
                <tr key={a.id}>
                  <td
                    onClick={() => {
                      onAddAccountToRecentlyViewed(a);
                    }}
                  >
                    <Link to={`/accounts/${a.id}`}>{a.displayName}</Link>
                  </td>
                </tr>
              ),
          )
        ) : (
          <tr>
            <th>
              <div className="SavedAccount-noAccounts">{NoAccountsMessage()}</div>
            </th>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
