import { connect } from 'react-redux';
import { AppState } from '../../reducer';
import { getSettings, Settings } from '../../settings';
import { Actions } from '../actions';
import { AccountSearch } from '../components/AccountSearch';
import { Account } from '../model';
import { getAccountSearch } from '../selectors';

type StateProps = Readonly<{
  isFetching: boolean;
  q: string;
  results: Account[];
  settings: Settings;
}>;

type DispatchProps = Readonly<{
  onSearch: (value: string) => void;
  onClearResults: () => void;
}>;

const mapStateToProps: (state: AppState) => StateProps = state => ({
  ...getAccountSearch(state),
  settings: getSettings(state),
});

const mapDispatchToProps = {
  onSearch: Actions.searchAccounts,
  onClearResults: Actions.clearAccountResults,
};

export const AccountSearchContainer = connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AccountSearch);
