import { Action, Reducer } from 'redux';
import { RequestError } from './request';

export type RequestState = Readonly<{
  isFetching: boolean;
  error: RequestError | null;
}>;

const initialState = {
  isFetching: false,
  error: null,
};

export const requestReducer = <T extends Action>(
  types: string[],
  filterAction?: (action: T) => boolean,
): Reducer<RequestState> => {
  const [requestType, successType, failureType] = types;

  return (state: RequestState = initialState, action: any) => {
    if (filterAction && !filterAction(action)) {
      return state;
    }

    switch (action.type) {
      case requestType:
        return {
          isFetching: true,
          error: null,
        };
      case successType:
        return {
          isFetching: false,
          error: null,
        };
      case failureType:
        return {
          isFetching: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
};

export type RequestByKeyState = {
  readonly [key: string]: RequestState;
};

export const requestReducerByKey = <T extends Action>(
  types: string[],
  keyFn: (action: T) => string,
): Reducer<RequestByKeyState> => {
  const byKey = requestReducer(types);

  return (state: RequestByKeyState = {}, action: T) => {
    if (types.indexOf(action.type) !== -1) {
      const key = keyFn(action);
      return {
        ...state,
        [key]: byKey(state[key], action),
      };
    } else {
      return state;
    }
  };
};
