import { Checkbox, FormGroup, HTMLSelect, Icon, Switch, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as accounting from 'accounting';
import { Flex } from 'grid-styled';
import * as React from 'react';
import { Settings } from '../../../settings';
import { MainContent, MainPanel } from '../../../shared';
import { Loading, LoadingError } from '../../../shared';
import { getFullTable } from '../../api';
import { PlatformTotal } from '../PlatformTotal';
import { DataExport } from './DataExport';
import './styles.scss';

type Props = Readonly<{
  settings: Settings;
}>;

type State = {
  forms: boolean;
  error: any;
  airtableData: any;
  isEnterprise: boolean;
  planTypeSelection: string;
  included: string[];
  platformTotal: number;
  seatsSelection: number;
  serverConnSelection: number;
  extraClientPackSelection: number;
  extraExperimentPackSelection: number;
  extraDataPackSelection: number;
  dataExport: boolean;
  dataExportCost: number;
  premiumSupport: boolean;
  premiumSupportCost: number;
  totalMonthly: number;
  totalAnnually: number;
};

export class PricingCalculator extends React.Component<Props, State> {
  public state: State = {
    airtableData: null,
    error: null,
    isEnterprise: true,
    planTypeSelection: 'ent',
    included: [
      'Access to the LD Dashboard',
      'Unlimited Server Side MAUs',
      'SSO',
      'Custom Roles',
      'SLAs',
      'Quickstart',
      '100k Client Side MAUs',
      '100k Monthly Experimentation Events',
      'Assigned CSM',
      'Enterprise Agreement Terms',
    ],
    platformTotal: 30000,
    seatsSelection: 25,
    serverConnSelection: 50000,
    extraClientPackSelection: 0,
    extraExperimentPackSelection: 0,
    extraDataPackSelection: 0,
    dataExport: false,
    dataExportCost: 0,
    premiumSupport: false,
    premiumSupportCost: 0,
    totalMonthly: 0,
    totalAnnually: 0,
    forms: true,
  };

  public componentDidMount() {
    this.getAirtableData();
  }

  public getAirtableData = () => {
    return Promise.all([
      getFullTable('PRO-per-seat', 'Pro-Seats'),
      getFullTable('PRO-Server-Connections', 'Connections'),
      getFullTable('PRO-Client-MAU', 'MAU'),
      getFullTable('PRO-Experimentation-Events', 'Events-per-month'),
      getFullTable('ENT-per-seat', 'Ent-Seats'),
      getFullTable('ENT-Server-Connections', 'Connections'),
      getFullTable('ENT-Client-MAU', 'MAU'),
      getFullTable('ENT-Experimentation-Events', 'Events-per-month'),
      getFullTable('ENT-Data-Export', 'Events-per-month'),
    ])
      .then(results => {
        this.setState(
          {
            airtableData: this.combineTableData(results),
          },
          () => this.getTotal(),
        );
      })
      .catch(err => {
        this.setState({ error: err });
      });
  };

  public combineTableData = (tableData: ReadonlyArray<any>) => {
    const proObject: object = {
      seats: this.getTableObject(tableData[0], 'Pro-Seats'),
      servers: this.getTableObject(tableData[1], 'Connections'),
      mau: this.getTableObject(tableData[2], 'MAU'),
      experiments: this.getTableObject(tableData[3], 'Events-per-month'),
      data: this.getTableObject(tableData[8], 'Events-per-month'),
    };

    const entObject: any = {
      seats: this.getTableObject(tableData[4], 'Ent-Seats'),
      servers: this.getTableObject(tableData[5], 'Connections'),
      mau: this.getTableObject(tableData[6], 'MAU'),
      experiments: this.getTableObject(tableData[7], 'Events-per-month'),
      data: this.getTableObject(tableData[8], 'Events-per-month'),
    };

    return { pro: proObject, ent: entObject };
  };

  public getTableObject = (calcTable: any, fieldHeader: string) => {
    const tableObject: any = {};
    if (fieldHeader !== 'Pro-Seats' && fieldHeader !== 'Ent-Seats' && fieldHeader !== 'Connections') {
      tableObject['0'] = {
        totalMonthly: 0,
        totalAnnually: 0,
      };
    }
    for (const record of calcTable.records) {
      const selectionValue = record.fields[fieldHeader];
      tableObject[selectionValue] = {
        totalMonthly: record.fields['Total monthly cost'],
        totalAnnually: record.fields['Total annual cost'],
      };
    }
    return tableObject;
  };

  public handleToggle = () => {
    this.clearFields();
    if (this.state.isEnterprise) {
      this.setState({
        isEnterprise: false,
        planTypeSelection: 'pro',
        platformTotal: 0,
        seatsSelection: 5,
        serverConnSelection: 25,
        dataExportCost: 0,
      });
    } else {
      this.setState({
        isEnterprise: true,
        planTypeSelection: 'ent',
        platformTotal: 30000,
        seatsSelection: 25,
        serverConnSelection: 50000,
        dataExportCost: 0,
      });
    }
  };

  public clearFields = () => {
    this.setState(
      {
        platformTotal: 30000,
        seatsSelection: 25,
        serverConnSelection: 50000,
        extraClientPackSelection: 0,
        extraExperimentPackSelection: 0,
        extraDataPackSelection: 0,
        totalMonthly: 0,
        totalAnnually: 0,
        dataExport: false,
        dataExportCost: 0,
        premiumSupport: false,
        premiumSupportCost: 0,
        forms: true,
      },
      () => this.getTotal(),
    );
  };

  public handlePlatformChange = (valueAsNumber: number, valueAsString: string) => {
    const platformTotal = valueAsNumber || accounting.unformat(valueAsString);
    if (platformTotal) {
      this.setState(
        {
          platformTotal,
          dataExportCost: this.getDataExportCost(this.state.dataExport, platformTotal),
        },
        () => this.getTotal(),
      );
    }
  };

  public handleSeatsChange = (event: any) => {
    this.setState(
      {
        seatsSelection: accounting.unformat(event.target.value),
      },
      () => this.getTotal(),
    );
  };

  public handleServerConnChange = (event: any) => {
    this.setState(
      {
        serverConnSelection: accounting.unformat(event.target.value),
      },
      () => this.getTotal(),
    );
  };

  public handleExtraClientPackChange = (event: any) => {
    this.setState(
      {
        extraClientPackSelection: accounting.unformat(event.target.value),
      },
      () => this.getTotal(),
    );
  };

  public handleExtraExperimentPackChange = (event: any) => {
    this.setState(
      {
        extraExperimentPackSelection: accounting.unformat(event.target.value),
      },
      () => this.getTotal(),
    );
  };

  public handleExtraDataPackChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState(
      {
        extraDataPackSelection: accounting.unformat(event.target.value),
      },
      () => this.getTotal(),
    );
  };

  public getDataExportCost = (isDataExportEnabled: boolean, platformTotal: number) => {
    return isDataExportEnabled ? Math.max(platformTotal * 0.3, 10000) : 0;
  };

  public handleDataExportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = event.target.checked;
    this.setState(
      {
        dataExport: isEnabled,
        dataExportCost: this.getDataExportCost(isEnabled, this.state.platformTotal),
      },
      () => this.getTotal(),
    );
  };

  public getPremiumSupportCost = (isPremiumSupportEnabledAndAllowed: boolean) => {
    if (isPremiumSupportEnabledAndAllowed) {
      return this.state.totalAnnually / 10;
    }
    return 0;
  };

  public handlePremiumSupportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = event.target.checked;
    this.setState(
      {
        premiumSupport: isEnabled,
        premiumSupportCost: this.getPremiumSupportCost(isEnabled),
      },
      () => this.getTotal(),
    );
  };

  public recalculatePremiumSupportCost = () => {
    const premiumSupportTotal = this.getPremiumSupportCost(this.state.premiumSupport);
    this.setState({
      premiumSupportCost: premiumSupportTotal,
      totalAnnually: this.state.totalAnnually + premiumSupportTotal,
    });
  };

  public getTotal = () => {
    const airTableData = this.state.airtableData[this.state.planTypeSelection];

    let totalMonthly =
      airTableData.seats[this.state.seatsSelection].totalMonthly +
      airTableData.mau[this.state.extraClientPackSelection].totalMonthly +
      airTableData.experiments[this.state.extraExperimentPackSelection].totalMonthly;

    let totalAnnually =
      airTableData.seats[this.state.seatsSelection].totalAnnually +
      airTableData.mau[this.state.extraClientPackSelection].totalAnnually +
      airTableData.experiments[this.state.extraExperimentPackSelection].totalAnnually;

    /*
    2020-04-01: ch70981 Update pricing calculator for Data Export
    Data export fee is now 30% of the enterprise platform fee or a minimum of $10,000
     */
    if (this.state.isEnterprise && this.props.settings.dataExportPercentagePricing) {
      // Add platform total
      totalMonthly += (this.state.platformTotal + this.state.dataExportCost) / 12;
      totalAnnually += this.state.platformTotal + this.state.dataExportCost;
    } else {
      totalMonthly +=
        airTableData.servers[this.state.serverConnSelection].totalMonthly +
        airTableData.data[this.state.extraDataPackSelection].totalMonthly;
      totalAnnually +=
        airTableData.servers[this.state.serverConnSelection].totalAnnually +
        airTableData.data[this.state.extraDataPackSelection].totalAnnually;
    }

    this.setState(
      {
        totalMonthly,
        totalAnnually,
      },
      () => this.recalculatePremiumSupportCost(),
    );
  };

  public handleForms = () => {
    this.setState(prevState => ({
      forms: !prevState.forms,
    }));
  };

  public render() {
    if (this.state.error !== null) {
      return <LoadingError title="Error loading calculator data" description={'Error loading calculator data'} />;
    }
    if (this.state.airtableData === null) {
      return <Loading title="Loading pricing calculator data…" />;
    }
    return (
      <MainPanel>
        <MainContent>
          <h1>
            Pricing Calculator
            <span>
              <Icon icon={IconNames.CALCULATOR} iconSize={30} />
            </span>
          </h1>

          <Flex className="calculator">
            <div className="SectionItem">
              <div className="toggle-plantype">
                <div className="pro-toggle"> Pro </div>
                <Switch
                  label="Enterprise"
                  checked={this.state.isEnterprise}
                  onChange={this.handleToggle}
                  large={true}
                />
              </div>

              <h2>Total</h2>
              <table>
                <tbody>
                  <tr>
                    {this.state.planTypeSelection === 'pro' && <th>Monthly</th>}
                    {this.state.isEnterprise && <th>Annual Platform Total</th>}
                    <th>Annual Grand Total</th>
                  </tr>
                  <tr>
                    {this.state.planTypeSelection === 'pro' && (
                      <td>{accounting.formatMoney(this.state.totalMonthly)}</td>
                    )}
                    {this.state.isEnterprise && (
                      <td>
                        {this.props.settings.dataExportPercentagePricing ? (
                          <PlatformTotal
                            name="platform"
                            value={this.state.platformTotal}
                            formsEnabled={this.state.forms}
                            presets={[5000, 30000, 80000]}
                            onValueChange={this.handlePlatformChange}
                          />
                        ) : (
                          accounting.formatMoney(
                            this.state.airtableData[this.state.planTypeSelection].servers[
                              this.state.serverConnSelection
                            ].totalAnnually,
                          )
                        )}
                      </td>
                    )}
                    <td>{accounting.formatMoney(this.state.totalAnnually)}</td>
                  </tr>
                </tbody>
              </table>
              <h2>Cost Breakdown</h2>
              <table>
                <tbody>
                  <tr>
                    {this.state.isEnterprise || this.props.settings.removeServerConnections ? (
                      <th>Seats</th>
                    ) : (
                      <th>Seats and Servers</th>
                    )}
                    <th>Selection</th>
                    {this.state.planTypeSelection === 'pro' && <th>Monthly</th>}
                    <th>Annual Total</th>
                  </tr>
                  {!this.state.isEnterprise &&
                    this.props.settings.removeServerConnections && (
                      <>
                        <tr>
                          <td>Core</td>
                          <td />

                          {this.state.planTypeSelection === 'pro' && (
                            <td>
                              {accounting.formatMoney(
                                this.state.airtableData[this.state.planTypeSelection].servers[25].totalMonthly,
                              )}
                            </td>
                          )}
                          <td>
                            {accounting.formatMoney(
                              this.state.airtableData[this.state.planTypeSelection].servers[25].totalAnnually,
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  <tr>
                    <td>Seats</td>
                    <td>
                      {this.state.forms ? (
                        <FormGroup className="options">
                          <HTMLSelect
                            id="seats"
                            name="seatsSelection"
                            options={accounting.formatNumber(
                              Object.keys(this.state.airtableData[this.state.planTypeSelection].seats),
                            )}
                            onChange={this.handleSeatsChange}
                            value={accounting.formatNumber(this.state.seatsSelection)}
                            fill={true}
                          />
                        </FormGroup>
                      ) : (
                        accounting.formatNumber(this.state.seatsSelection)
                      )}
                    </td>

                    {this.state.planTypeSelection === 'pro' && (
                      <td>
                        {accounting.formatMoney(
                          this.state.airtableData[this.state.planTypeSelection].seats[this.state.seatsSelection]
                            .totalMonthly,
                        )}
                      </td>
                    )}
                    <td>
                      {accounting.formatMoney(
                        this.state.airtableData[this.state.planTypeSelection].seats[this.state.seatsSelection]
                          .totalAnnually,
                      )}
                    </td>
                  </tr>
                  {!this.state.isEnterprise &&
                    !this.props.settings.removeServerConnections && (
                      <>
                        <tr>
                          <td>Servers</td>
                          <td>
                            {this.state.forms ? (
                              <FormGroup className="options">
                                <HTMLSelect
                                  id="servers"
                                  options={accounting.formatNumber(
                                    Object.keys(this.state.airtableData[this.state.planTypeSelection].servers),
                                  )}
                                  onChange={this.handleServerConnChange}
                                  value={accounting.formatNumber(this.state.serverConnSelection)}
                                  fill={true}
                                />
                              </FormGroup>
                            ) : (
                              accounting.formatNumber(this.state.serverConnSelection)
                            )}
                          </td>

                          {this.state.planTypeSelection === 'pro' && (
                            <td>
                              {accounting.formatMoney(
                                this.state.airtableData[this.state.planTypeSelection].servers[
                                  this.state.serverConnSelection
                                ].totalMonthly,
                              )}
                            </td>
                          )}
                          <td>
                            {accounting.formatMoney(
                              this.state.airtableData[this.state.planTypeSelection].servers[
                                this.state.serverConnSelection
                              ].totalAnnually,
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  {!this.state.isEnterprise &&
                    !this.props.settings.removeServerConnections && (
                      <tr>
                        <td>Subtotal Seats and Servers</td>
                        <td />
                        <td className="subtotal">
                          {accounting.formatMoney(
                            this.state.airtableData[this.state.planTypeSelection].servers[
                              this.state.serverConnSelection
                            ].totalMonthly +
                              this.state.airtableData[this.state.planTypeSelection].seats[this.state.seatsSelection]
                                .totalMonthly,
                          )}
                        </td>
                        <td>
                          {accounting.formatMoney(
                            this.state.airtableData[this.state.planTypeSelection].servers[
                              this.state.serverConnSelection
                            ].totalAnnually +
                              this.state.airtableData[this.state.planTypeSelection].seats[this.state.seatsSelection]
                                .totalAnnually,
                          )}
                        </td>
                      </tr>
                    )}
                  {!this.state.isEnterprise &&
                    this.props.settings.removeServerConnections && (
                      <tr>
                        <td>Subtotal Core and Seats</td>
                        <td />
                        <td className="subtotal">
                          {accounting.formatMoney(
                            this.state.airtableData[this.state.planTypeSelection].servers[25].totalMonthly +
                              this.state.airtableData[this.state.planTypeSelection].seats[this.state.seatsSelection]
                                .totalMonthly,
                          )}
                        </td>
                        <td>
                          {accounting.formatMoney(
                            this.state.airtableData[this.state.planTypeSelection].servers[25].totalAnnually +
                              this.state.airtableData[this.state.planTypeSelection].seats[this.state.seatsSelection]
                                .totalAnnually,
                          )}
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
              {this.state.isEnterprise && (
                <div className="addOnHeader">
                  <h2>Add-Ons</h2>
                  <Tooltip
                    popoverClassName="tooltipPopover"
                    content={
                      'Add-on selections represent an addition to what is already included in the platform, and not the total. Example: 30k platform + 200k client MAU add-on = 300k total MAU'
                    }
                  >
                    <Icon icon={IconNames.WARNING_SIGN} iconSize={15} />
                  </Tooltip>
                </div>
              )}

              <table>
                <tbody>
                  <tr>
                    <th>Add-Ons</th>
                    <th>Selection</th>
                    {this.state.planTypeSelection === 'pro' && <th>Monthly</th>}
                    <th>Annual Total</th>
                  </tr>
                  <tr>
                    <td>Client Add-Ons</td>

                    <td>
                      {this.state.forms ? (
                        <FormGroup className="options">
                          <HTMLSelect
                            id="mau"
                            options={accounting.formatNumber(
                              Object.keys(this.state.airtableData[this.state.planTypeSelection].mau),
                            )}
                            onChange={this.handleExtraClientPackChange}
                            value={accounting.formatNumber(this.state.extraClientPackSelection)}
                            fill={true}
                          />
                        </FormGroup>
                      ) : (
                        accounting.formatNumber(this.state.extraClientPackSelection)
                      )}
                    </td>

                    {this.state.planTypeSelection === 'pro' && (
                      <td>
                        {accounting.formatMoney(
                          this.state.airtableData[this.state.planTypeSelection].mau[this.state.extraClientPackSelection]
                            .totalMonthly,
                        )}
                      </td>
                    )}
                    <td>
                      {accounting.formatMoney(
                        this.state.airtableData[this.state.planTypeSelection].mau[this.state.extraClientPackSelection]
                          .totalAnnually,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Experiment Pack</td>
                    <td>
                      {this.state.forms ? (
                        <FormGroup className="options">
                          <HTMLSelect
                            id="experiments"
                            options={accounting.formatNumber(
                              Object.keys(this.state.airtableData[this.state.planTypeSelection].experiments),
                            )}
                            onChange={this.handleExtraExperimentPackChange}
                            value={accounting.formatNumber(this.state.extraExperimentPackSelection)}
                            fill={true}
                          />
                        </FormGroup>
                      ) : (
                        accounting.formatNumber(this.state.extraExperimentPackSelection)
                      )}
                    </td>

                    {this.state.planTypeSelection === 'pro' && (
                      <td>
                        {accounting.formatMoney(
                          this.state.airtableData[this.state.planTypeSelection].experiments[
                            this.state.extraExperimentPackSelection
                          ].totalMonthly,
                        )}
                      </td>
                    )}
                    <td>
                      {accounting.formatMoney(
                        this.state.airtableData[this.state.planTypeSelection].experiments[
                          this.state.extraExperimentPackSelection
                        ].totalAnnually,
                      )}
                    </td>
                  </tr>
                  <tr className={this.state.planTypeSelection}>
                    <td>Data Export {!this.props.settings.dataExportPercentagePricing && ' Pack'}</td>
                    <td>
                      <DataExport
                        isChecked={this.state.dataExport}
                        isDisabled={!this.state.isEnterprise}
                        formsEnabled={this.state.forms}
                        options={this.state.airtableData[this.state.planTypeSelection].data}
                        settings={this.props.settings}
                        value={this.state.extraDataPackSelection}
                        onChange={
                          this.props.settings.dataExportPercentagePricing
                            ? this.handleDataExportChange
                            : this.handleExtraDataPackChange
                        }
                      />
                    </td>
                    {this.state.planTypeSelection === 'pro' && (
                      <td>
                        {accounting.formatMoney(
                          this.state.airtableData[this.state.planTypeSelection].data[this.state.extraDataPackSelection]
                            .totalMonthly,
                        )}
                      </td>
                    )}
                    <td>
                      {this.props.settings.dataExportPercentagePricing
                        ? accounting.formatMoney(this.state.dataExportCost)
                        : accounting.formatMoney(
                            this.state.airtableData[this.state.planTypeSelection].data[
                              this.state.extraDataPackSelection
                            ].totalAnnually,
                          )}
                    </td>
                  </tr>
                  {this.state.planTypeSelection !== 'pro' && (
                    <tr className={this.state.planTypeSelection}>
                      <td>Premium Support</td>
                      <td>
                        <FormGroup className="options">
                          <Checkbox checked={this.state.premiumSupport} onChange={this.handlePremiumSupportChange} />
                        </FormGroup>
                      </td>
                      <td>{accounting.formatMoney(this.state.premiumSupportCost)}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Subtotal Extras</td>
                    <td />
                    {this.state.planTypeSelection === 'pro' && (
                      <td className="subtotal">
                        {accounting.formatMoney(
                          this.state.airtableData[this.state.planTypeSelection].mau[this.state.extraClientPackSelection]
                            .totalMonthly +
                            this.state.airtableData[this.state.planTypeSelection].experiments[
                              this.state.extraExperimentPackSelection
                            ].totalMonthly +
                            this.state.airtableData[this.state.planTypeSelection].data[
                              this.state.extraDataPackSelection
                            ].totalMonthly,
                        )}
                      </td>
                    )}
                    <td>
                      {accounting.formatMoney(
                        this.state.airtableData[this.state.planTypeSelection].mau[this.state.extraClientPackSelection]
                          .totalAnnually +
                          this.state.airtableData[this.state.planTypeSelection].experiments[
                            this.state.extraExperimentPackSelection
                          ].totalAnnually +
                          this.state.airtableData[this.state.planTypeSelection].data[this.state.extraDataPackSelection]
                            .totalAnnually +
                          this.state.dataExportCost +
                          this.state.premiumSupportCost,
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />

              <Switch
                labelElement="forms"
                checked={this.state.forms}
                onChange={this.handleForms}
                alignIndicator="left"
                large={true}
                style={{ float: 'right' }}
              />
            </div>
            {this.state.isEnterprise && (
              <div className="SectionItem included">
                <div>
                  <h2>{'Included with enterprise:'}</h2>
                  {this.state.included.map(included => (
                    <ul key={included}>
                      <li>{included}</li>
                    </ul>
                  ))}
                </div>
              </div>
            )}
          </Flex>
        </MainContent>
      </MainPanel>
    );
  }
}
