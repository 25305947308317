import { Alert, Button, Icon, Intent, Position, Tag, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import { Scopes } from '../../auth';
import { AccountRole, MFAStatus } from '../model';

type MFAStatusTagProps = Readonly<{
  scopes: Scopes;
  status: MFAStatus;
  role: AccountRole;
  onMFADisable: () => void;
}>;

type MFAStatusTagState = Readonly<{
  alertIsOpen: boolean;
}>;

export class MFAStatusTag extends React.Component<MFAStatusTagProps, MFAStatusTagState> {
  public state: MFAStatusTagState = {
    alertIsOpen: false,
  };

  public render() {
    const { scopes, status, role } = this.props;
    const { alertIsOpen } = this.state;

    return (
      <>
        <Tooltip content={statusToTooltip[status]} position={Position.TOP_RIGHT}>
          <Tag intent={statusToIntent[status]}>
            <Icon icon={statusToIconName[status]} />
          </Tag>
        </Tooltip>
        {scopes.ownerMfaReset &&
          role === AccountRole.OWNER &&
          (status === MFAStatus.ENABLED || status === MFAStatus.RESET) && (
            <>
              <Button
                className="mfaReset-Button"
                icon="disable"
                intent={Intent.DANGER}
                small
                text="Disable MFA"
                type="button"
                onClick={this.handleDisable}
              />
              <Alert
                canEscapeKeyCancel
                cancelButtonText="Cancel"
                confirmButtonText="Disable"
                icon={IconNames.SHIELD}
                intent={Intent.DANGER}
                isOpen={alertIsOpen}
                onCancel={this.handleCancel}
                onConfirm={this.handleConfirm}
              >
                <h3>Disable multi-factor authentication?</h3>
                <p>This reduces the security of the account and is not recommended. Are you sure?</p>
              </Alert>
            </>
          )}
      </>
    );
  }

  private handleDisable = () => this.setState({ alertIsOpen: true });
  private handleCancel = () => this.setState({ alertIsOpen: false });
  private handleConfirm = () => {
    this.setState({ alertIsOpen: false });
    this.props.onMFADisable();
  };
}

const statusToIntent = {
  enabled: Intent.SUCCESS,
  disabled: Intent.DANGER,
  reset: Intent.WARNING,
};

// TODO: replace any with IconName.
const statusToIconName: { [key: string]: any } = {
  enabled: IconNames.SMALL_TICK,
  disabled: IconNames.SMALL_CROSS,
  reset: IconNames.REFRESH,
};

const statusToTooltip = {
  enabled: 'MFA is enabled for this member',
  disabled: 'MFA is disabled for this member',
  reset: 'This member used their recovery code and must reset their MFA settings',
};
