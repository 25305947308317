import * as d3 from 'd3';
import * as React from 'react';
import { DataPoint, Series } from './types';

export type LineProps = Readonly<{
  xScale: any;
  yScale: any;
  x: (d: DataPoint) => any;
  y: (d: DataPoint) => any;
  data: Series;
  stroke: string;
  extraClass?: string;
}>;

export class LinePath extends React.Component<LineProps> {
  public render() {
    const { xScale, yScale, x, y, data, stroke, extraClass } = this.props;
    const lineGenerator = d3
      .line<DataPoint>()
      .x(d => xScale(x(d)))
      .y(d => yScale(y(d)));

    const line = lineGenerator(data as DataPoint[]);
    const baseClass = 'LinePath';
    const className = extraClass ? baseClass + ' ' + extraClass : baseClass;
    return <path className={className} d={line || ''} stroke={stroke} />;
  }
}
