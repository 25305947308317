import * as React from 'react';

export type BarProps = Readonly<{
  x: any;
  y: number;
  width: number;
  height: number;
  fill?: string;
  className?: string;
  innerRef?: (element: SVGRectElement | null) => void;
  onMouseMove?: (event: React.MouseEvent<SVGRectElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<SVGRectElement>) => void;
}>;

export const Bar = ({ innerRef, ...props }: BarProps) => <rect ref={innerRef} {...props} />;
