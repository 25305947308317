import * as React from 'react';
import config from '../../config';
import { LimitOverrideFlag } from '../model';
import { formatNumber } from '../util';

type PlanReviewOverrideCellProps = {
  value: number | undefined;
  flag: LimitOverrideFlag;
};

export class PlanReviewOverrideCell extends React.Component<PlanReviewOverrideCellProps> {
  public render() {
    const { value, flag } = this.props;

    if (!value) {
      return <></>;
    }

    return (
      <a
        href={`${config.launchdarkly.baseUrl}/default/${config.launchdarkly.environment}/features/${flag}/targeting`}
        target="_blank"
      >
        {formatNumber(value)}
      </a>
    );
  }
}
