import * as gonfalon from '../gonfalon';

export type SettingsPrivateResponse = Readonly<{
  slackWebHookPlanChanges: string;
}>;

export const getSettingsPrivate = () => {
  return gonfalon.request<SettingsPrivateResponse>({
    method: 'GET',
    url: '/private/settings/vital',
  });
};
