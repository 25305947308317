import { Classes, Tooltip } from '@blueprintjs/core';
import * as cx from 'classnames';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { NavLink, Route } from 'react-router-dom';
import { CopyToClipboard } from '../../shared';
import { capitalize } from '../utils';

const bsonId = /^[a-f\d]{24}$/i;

const crumbName = (url: string) => {
  if (!url) {
    return '';
  }
  const pathParts = url.replace(/\/$/g, '').split('/');
  const path = pathParts[pathParts.length - 1];

  if (bsonId.test(path)) {
    return path;
  }
  return capitalize(path);
};

const Breadcrumb = ({ match }: RouteComponentProps<any>) => (
  <li data-match={JSON.stringify(match)}>
    <NavLink
      className={cx(Classes.BREADCRUMB, {
        [Classes.BREADCRUMB_CURRENT]: match.isExact,
      })}
      to={match.url}
    >
      <Tooltip content="Copy">
        <CopyToClipboard content={crumbName(match.url)}>{crumbName(match.url)}</CopyToClipboard>
      </Tooltip>
    </NavLink>
  </li>
);

export class Breadcrumbs extends React.Component<RouteComponentProps<any>, any> {
  public render() {
    const { location } = this.props;
    if (!location) {
      return null;
    }

    let paths: string[] = [];
    location.pathname
      .replace(/\/$/g, '')
      .split('/')
      .reduce((prev, curr) => {
        const sofar = `${prev}/${curr}`;
        paths = paths.concat(sofar);
        return sofar;
      });

    return <ul className={Classes.BREADCRUMBS}>{paths.map(p => <Route key={p} path={p} component={Breadcrumb} />)}</ul>;
  }
}
