import { Tooltip } from '@blueprintjs/core';
import * as React from 'react';
import { Scopes, User } from '../../auth';
import * as gonfalon from '../../gonfalon';
import { Settings } from '../../settings';
import { DataList, DataListItem, Module, ModuleContent, ModuleHeader } from '../../shared';
import {
  linkToCatfood,
  linkToFullstory,
  linkToHoneycombEvents,
  linkToHoneycombSDKVersions,
  linkToHowToGhostConfluencePage,
  linkToLogDNA,
  linkToSalesforce,
  linkToStripe,
} from '../../shared/third-party';

type LinkHubProps = Readonly<{
  accountID: string;
  accountOrg: string | undefined;
  user: User;
  settings: Settings;
  scopes: Scopes;
}>;

const handleGhost = (accountID: string, email: string) => {
  return (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    const win = window.open('', '_blank');
    gonfalon.requestGhostingCookie(accountID, email).then(() => {
      if (win) {
        win.location.href = gonfalon.url;
      }
    });
  };
};

const TooltipHowToGhost = () => {
  return (
    <div>
      <p>Make sure the account has enabled in app consent on the security tab of their LD instance</p>
      <p>Go to the account in Vitalstatistix, click the 👻 icon, and you are ghosting!</p>
    </div>
  );
};

export const LinkHub = ({ accountID, user, settings, scopes }: LinkHubProps) => {
  return (
    <Module>
      <ModuleHeader>Link Hub</ModuleHeader>
      <ModuleContent snug>
        <DataList>
          {scopes.ghostingLink &&
            gonfalon.ghostingEnabled && (
              <DataListItem>
                <a onClick={handleGhost(accountID, user.email || 'anon')}>👻</a>
                <Tooltip content={<TooltipHowToGhost />}>
                  <a
                    href={linkToHowToGhostConfluencePage()}
                    className="howToGhost-Link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    (How to ghost)
                  </a>
                </Tooltip>
              </DataListItem>
            )}

          <DataListItem>
            <a href={linkToCatfood(accountID)} target="_blank" rel="noopener noreferrer">
              Catfood
            </a>
          </DataListItem>
          <DataListItem>
            <a href={linkToFullstory(accountID)} target="_blank" rel="noopener noreferrer">
              Fullstory
            </a>
          </DataListItem>
          {settings.enableHoneycombLinks && (
            <>
              <DataListItem>
                <a href={linkToHoneycombEvents(accountID)} target="_blank" rel="noopener noreferrer">
                  Events - Honeycomb
                </a>
              </DataListItem>
              <DataListItem>
                <a href={linkToHoneycombSDKVersions(accountID)} target="_blank" rel="noopener noreferrer">
                  SDK Versions - Honeycomb
                </a>
              </DataListItem>
            </>
          )}
          <DataListItem>
            <a href={linkToLogDNA(accountID)} target="_blank" rel="noopener noreferrer">
              LogDNA
            </a>
          </DataListItem>
          <DataListItem>
            <a href={linkToSalesforce(accountID)} target="_blank" rel="noopener noreferrer">
              Salesforce
            </a>
          </DataListItem>
          <DataListItem>
            <a href={linkToStripe(accountID)} target="_blank" rel="noopener noreferrer">
              Stripe
            </a>
          </DataListItem>
        </DataList>
      </ModuleContent>
    </Module>
  );
};
