import { camelCase, mapKeys } from 'lodash';

import { ScheduledTrial } from '../accounts';

export const camelizeKeys = (raw: { [key: string]: any }) => mapKeys(raw, (_, k: string) => camelCase(k));

export const capitalize = (raw: string) => `${raw.charAt(0).toUpperCase()}${raw.slice(1)}`;

export const getFeatureTrialExpiration = (trial: ScheduledTrial[], accountId: string) =>
  trial.length && trial.filter((d: ScheduledTrial) => d.operations[0].value === accountId)[0].scheduledTime;
