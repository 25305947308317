import { addMinutes, isToday, isYesterday, parse as parseDate } from 'date-fns';
import * as numbro from 'numbro';
import { envBaseUrls } from '../constants';
import { Instance } from './model';

export const dateSuffix = (date: string): string => {
  if (date) {
    const compare = utcDateStringToLocalDate(date);
    if (isToday(compare)) {
      return ' (Today)';
    }

    if (isYesterday(compare)) {
      return ' (Yesterday)';
    }
  }

  return '';
};
// Edit formatNumber function to format the number to an infinity symbol if:
// 1) current product limit (from Gonfalon) is -1
// 2) new product limits (from SalesForce) is 999 or -999
export const formatNumber = (
  value: number | undefined,
  invertSign: boolean = false,
  isSFDCLineItem: boolean = false,
): string => {
  const multiplier = invertSign ? -1 : 1;
  if (isSFDCLineItem) {
    if ((invertSign && value === 999) || (!invertSign && value === -999)) {
      return '-∞';
    } else if ((invertSign && value === -999) || (!invertSign && value === 999)) {
      return '∞';
    }
  } else if (!isSFDCLineItem && value === -1) {
    return '∞';
  }
  return value === undefined || isNaN(value) ? '' : numbro(value * multiplier).format({ thousandSeparated: true });
};

// Dates returned by the account-magic API are in UTC time, causing parsed dates to be off by -1 days.
// This function converts a UTC date to a local date so that it is correctly rendered by the Blueprint DatePicker component.
export const utcDateStringToLocalDate = (dateString: string): Date => {
  const date = parseDate(dateString);
  // Date.getTimezoneOffset returns the delta in minutes between local time and UTC. So we need to *add* that offset
  // to the parsed date in order for it to be displayed correctly by controls which do not compensate
  return addMinutes(date, date.getTimezoneOffset());
};

export const localDateTimeToUTC = (dateTime: Date): Date => {
  return new Date(Date.UTC(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate()));
};

export const vitalBaseUrl = (ldInstance: Instance): string => {
  if (process.env.VITALSTATISTIX_MODE === 'production') {
    switch (ldInstance) {
      case Instance.Commercial:
        return envBaseUrls.production;
      case Instance.Federal:
        return envBaseUrls.federalProduction;
    }
  }

  if (process.env.VITALSTATISTIX_MODE === 'staging') {
    switch (ldInstance) {
      case Instance.Commercial:
        return envBaseUrls.staging;
      case Instance.Federal:
        return envBaseUrls.federalStaging;
    }
  }

  return '';
};
