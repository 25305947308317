import * as moment from 'moment';
import { Account, getAccountDisplayName, LegacySubscription, Subscription } from '../accounts';
import { User } from '../auth';
import * as routes from '../routes';

const SUCCESS = '#36a64f';

const formatDate = (date: moment.Moment) => date.format('L');

export type SlackMessage = Readonly<{
  user: User;
  color: string;
  title: string;
  text?: string;
}>;

const subscriptionPeriodChangeSuccessMessage = (
  periodName: string,
  user: User,
  account: Account,
  prevSubscription: LegacySubscription,
  nextSubscription: LegacySubscription,
) => {
  const prevDate = moment.utc(prevSubscription.trialEndDate);
  const formattedPrevDate = formatDate(prevDate);
  const nextDate = moment.utc(nextSubscription.trialEndDate);
  const formattedNextDate = formatDate(nextDate);
  const diff = nextDate.diff(prevDate, 'days');
  const verb = diff < 0 ? 'shortened' : 'extended';
  const unit = Math.abs(diff) === 1 ? 'day' : 'days';
  const days = Math.abs(diff);
  const accountLink = routes.linkToAccount(account);
  const accountName = getAccountDisplayName(account);

  return {
    user,
    color: SUCCESS,
    title: `Subscription modified for <${accountLink}|${accountName}>`,
    text: `The ${periodName} period was ${verb} by ${days} ${unit} from ${formattedPrevDate} to ${formattedNextDate}`,
  };
};

export const trialChangeSuccessMessage = (
  user: User,
  account: Account,
  prevSubscription: LegacySubscription,
  nextSubscription: LegacySubscription,
): SlackMessage => subscriptionPeriodChangeSuccessMessage('trial', user, account, prevSubscription, nextSubscription);

export const graceChangeSuccessMessage = (
  user: User,
  account: Account,
  prevSubscription: LegacySubscription,
  nextSubscription: LegacySubscription,
): SlackMessage => subscriptionPeriodChangeSuccessMessage('grace', user, account, prevSubscription, nextSubscription);

export const planChangeSuccessMessage = (
  user: User,
  account: Account,
  prevSubscription: Subscription,
  nextSubscription: Subscription,
) => {
  const accountLink = routes.linkToAccount(account);
  const accountName = getAccountDisplayName(account);

  const prevType = prevSubscription.planType;
  const nextType = nextSubscription.planType;

  const msg =
    prevType === nextType
      ? `${prevType} plan limits updated for <${accountLink}|${accountName}>`
      : `Plan changed from ${prevType} to ${nextType} for <${accountLink}|${accountName}>`;

  return {
    user,
    color: SUCCESS,
    title: msg,
  };
};

export const cancelSubscriptionSuccessMessage = (user: User, account: Account) => {
  const accountLink = routes.linkToAccount(account);
  const accountName = getAccountDisplayName(account);

  return {
    user,
    color: SUCCESS,
    title: `Plan canceled for <${accountLink}|${accountName}>`,
  };
};
