import { Spinner } from '@blueprintjs/core';
import { values } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';

import './AccountList.scss';

import * as d3 from 'd3';
import { createUsageShortcuts, DefaultCallout, MainContent, MainPanel, Subhead } from '../../shared';
import { Legend } from '../../shared';
import { UsageResponse } from '../api';
import { ChartTypes, SDKType, UsageIdentifier } from '../model';
import { HighUsageFilter } from './HighUsageFilter';
import { TimeRangeFilter } from './TimeRangeFilter';
import { UsageChart } from './UsageChart';

// Get the time range associated with a label (selectedTimeOption)
type TimeRangeShortcut = {
  label: string;
  dateRange: Date[];
};

const getTimeRange = (timeOption: string) => {
  const timeRanges = createUsageShortcuts();
  const timeRange = timeRanges.find((sc: object) => {
    const value: TimeRangeShortcut = values(sc)[0];
    return value.label === timeOption;
  });
  if (timeRange !== undefined) {
    const timeRangeValue: TimeRangeShortcut = values(timeRange)[0] as TimeRangeShortcut;
    const [startDate, endDate] = timeRangeValue.dateRange;
    return { to: endDate.valueOf(), from: startDate.valueOf() };
  } else {
    return undefined;
  }
};

export type HighAccountUsageProps = UsageResponse &
  Readonly<{
    isFetching: boolean;
    onFetchHighAccountUsage: (value: SDKType, dates?: { to: number; from: number }) => void;
    chartType: ChartTypes.LINE;
    usageIdentifier?: UsageIdentifier;
    renderFilters?: () => void;
    tooltipText: string;
    selectedOption?: SDKType;
    limit?: number;
  }>;

type State = {
  readonly selectedOption: SDKType;
  readonly selectedTimeOption: string;
  readonly focusedId?: string;
};

export class HighAccountUsage extends React.Component<HighAccountUsageProps, State> {
  public state: State = { selectedOption: SDKType.CLIENT, selectedTimeOption: 'Last 14 days' };

  public componentDidMount() {
    this.props.onFetchHighAccountUsage(this.state.selectedOption, getTimeRange(this.state.selectedTimeOption));
  }

  public render() {
    const { isFetching, chartType, tooltipText, limit, series, metadata } = this.props;
    const color = d3.scaleOrdinal(d3.schemeCategory10);

    return (
      <MainPanel>
        <MainContent>
          <Subhead>Top 10 accounts with high usage</Subhead>
          <div>
            <HighUsageFilter
              onSelect={(value: SDKType) => this.handleSelect(value)}
              options={[
                { value: SDKType.SERVER, name: 'Server connections' },
                { value: SDKType.CLIENT, name: 'Client connections' },
              ]}
              selectedOption={this.state.selectedOption}
              value={SDKType.SERVER}
            />
            <TimeRangeFilter
              onSelect={(value: { to: number; from: number }, label: string) => this.handleTimeSelect(value, label)}
              selectedOption={this.state.selectedTimeOption}
              value="hour"
              timeRange={createUsageShortcuts()}
            />
          </div>
          {series.length === 0 &&
            !isFetching && (
              <div className="UsageGraph--loading">
                <DefaultCallout>There is no usage data.</DefaultCallout>
              </div>
            )}
          {isFetching && (
            <div className="UsageGraph--loading">
              <Spinner size={Spinner.SIZE_SMALL} />
            </div>
          )}
          {!isFetching &&
            series.length > 0 && (
              <>
                <UsageChart
                  metadata={metadata}
                  limit={limit}
                  series={series}
                  chartType={chartType}
                  tooltipText={tooltipText}
                  color={color}
                  renderToolTip={() => null}
                  focusedSeriesId={this.state.focusedId}
                />
                <div className="Legend">
                  {metadata.map((md: { _id: string; organization: string; ownerEmail: string }, i) => (
                    <Link
                      to={`/accounts/${md._id}`}
                      className="LegendItem"
                      key={i}
                      onMouseEnter={() => {
                        this.setState({ focusedId: md._id });
                      }}
                      onMouseLeave={() => {
                        this.setState({ focusedId: undefined });
                      }}
                    >
                      <Legend stroke={color(i.toString())} text={md.organization || md.ownerEmail} />
                    </Link>
                  ))}
                </div>
              </>
            )}
        </MainContent>
      </MainPanel>
    );
  }

  private handleTimeSelect = (value: { to: number; from: number }, label: string) => {
    this.setState({ selectedTimeOption: label });
    this.props.onFetchHighAccountUsage(this.state.selectedOption, value);
  };

  private handleSelect = (value: SDKType) => {
    this.setState({ selectedOption: value });
    this.props.onFetchHighAccountUsage(value, getTimeRange(this.state.selectedTimeOption));
  };
}
