import * as cx from 'classnames';
import * as React from 'react';
import './styles.scss';

type DataListProps = Readonly<{
  className?: any;
  children?: any;
}>;

export const DataList = ({ className, children }: DataListProps) => (
  <div className={cx('DataList', className)}>{children}</div>
);

export const DataListItem = ({ className, children }: DataListProps) => (
  <div className={cx('DataList-item', className)}>{children}</div>
);
