import { orderBy } from 'lodash';
import { SDKOption } from './model';

export function convertGroupedSDKsToFlatArray(SDKs: any) {
  const options: any = [];
  const values = Object.keys(SDKs).map((key: string) => SDKs[key]);

  values.forEach((sdk: SDKOption[]) =>
    sdk.forEach((type: SDKOption, i: number) => {
      if (i === 0) {
        options.push({
          name: type.sdk === 'Unknown' ? `${type.sdk} SDK` : type.sdk,
          value: `${type.sdk}`,
          sdk: type.sdk,
          groupHeader: true,
        });
      }

      options.push({
        name: type.version === 'Unknown' ? `${type.version} version` : type.version,
        value: `${type.sdk}--${type.version}`,
        sdk: type.sdk,
        version: type.version,
        nested: true,
      });
    }),
  );

  return orderBy(options, ['sdk'], ['asc']);
}

export const pluralize = (word: string, count: number): string => (count !== 1 ? `${word}s` : word);
