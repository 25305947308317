import { schema } from 'normalizr';

export const account = new schema.Entity(
  'accounts',
  {},
  {
    idAttribute: a => a._id,
  },
);

export const accounts = {
  items: [account],
};

export const member = new schema.Entity(
  'members',
  {},
  {
    idAttribute: m => m._id,
  },
);

export const members = {
  items: [member],
};

export const memberSession = new schema.Entity(
  'memberSessions',
  {},
  {
    idAttribute: m => m.memberId,
  },
);

export const memberSessions = {
  items: [memberSession],
};

export const customRole = new schema.Entity(
  'customRoles',
  {},
  {
    idAttribute: r => r._id,
  },
);

export const customRoles = {
  items: [customRole],
};

export const project = new schema.Entity(
  'projects',
  {},
  {
    idAttribute: r => r._id,
  },
);

export const projects = {
  items: [project],
};

export const entry = new schema.Entity(
  'auditLogEntries',
  {},
  {
    idAttribute: e => e,
  },
);

export const auditLogEntries = {
  items: [entry],
};
