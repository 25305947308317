import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../reducer';
import { Actions } from '../actions';
import { Subscription, SubscriptionUsage } from '../model';
import { getSubscription, getSubscriptionUsageByAccount } from '../selectors';
import { Overages } from './Overages';

type StateProps = Readonly<{
  subscription: Subscription;
  subscriptionUsage: SubscriptionUsage;
}>;

type OwnProps = Readonly<{
  accountId: string;
}>;

type DispatchProps = Readonly<{
  onFetchAccount: (accountId: string) => void;
}>;

type OveragesPageProps = DispatchProps & StateProps & OwnProps;

export class OveragesPage extends React.Component<OveragesPageProps, any> {
  public componentWillMount() {
    const { onFetchAccount, accountId } = this.props;
    onFetchAccount(accountId);
  }

  public render() {
    const { subscription, subscriptionUsage } = this.props;
    return <Overages subscriptionUsage={subscriptionUsage} subscription={subscription} />;
  }
}

const mapStateToProps = (state: AppState, props: OwnProps) => ({
  subscription: getSubscription(state, {
    accountId: props.accountId,
  }),
  subscriptionUsage: getSubscriptionUsageByAccount(state, {
    accountId: props.accountId,
  }),
});

const mapDispatchToProps = {
  onFetchAccount: Actions.fetchAccount,
};

export const OveragesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OveragesPage);
