import * as d3 from 'd3';

export const formatSecond = d3.timeFormat(':%S');
export const formatMinute = d3.timeFormat('%I:%M');
export const formatHour = d3.timeFormat('%I %p');
export const formatDay = d3.timeFormat('%a %d');
export const formatMonth = d3.timeFormat('%b %d');
export const formatYear = d3.timeFormat('%Y');

export const timeTickFormatFromDomain = ([start, end]: [Date, Date]) => {
  if (d3.timeMinute.count(start, end) <= 1) {
    return formatSecond;
  }

  if (d3.timeHour.count(start, end) <= 1) {
    return formatMinute;
  }

  if (d3.timeDay.count(start, end) <= 1) {
    return formatHour;
  }

  if (d3.timeWeek.count(start, end) <= 1) {
    return formatDay;
  }

  if (d3.timeMonth.count(start, end) <= 1) {
    return formatMonth;
  }

  if (d3.timeYear.count(start, end) <= 1) {
    return formatMonth;
  }

  return formatYear;
};
