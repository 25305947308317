import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { AccountSearchContainer, AccountsWrapper } from './accounts';
import { HighAccountUsageContainer } from './accounts/components/HighAccountUsageContainer';
import { getScopes, Scopes, SignIn, User } from './auth';
import config from './config';
import { initDatadogRUM } from './datadog';
import history from './history';
import { PricingCalculatorContainer } from './pricingCalculator';
import { PlanReviewContainer } from './provisioning/containers/PlanReviewContainer';
import { AppState } from './reducer';
import { getSettings, Settings } from './settings';
import { AccessDenied, AppLoading, LoadingError, NotFound, Page, RequestError, TopNav } from './shared';

type RootProps = Readonly<{
  settings: Settings;
  scopes: Scopes;
  user: User | null;
  isUserReady: boolean;
  onSignIn: () => void;
  onSignOut: () => void;
}>;

class Root extends React.Component<RootProps, {}> {
  public render() {
    const { user, isUserReady } = this.props;
    const { isFetching, error } = this.props.settings;
    if (!isUserReady || isFetching) {
      return this.renderLoading();
    } else if (error) {
      return this.renderError(error);
    }
    return user ? this.renderAuthenticated() : this.renderUnauthenticated();
  }

  private renderLoading() {
    return <AppLoading />;
  }

  private renderError(error: RequestError) {
    return <LoadingError title="Error loading settings" description={error.message.toString()} />;
  }

  private renderUnauthenticated() {
    const { onSignIn } = this.props;
    return (
      <SignIn
        onSignIn={onSignIn}
        troubleshootingURL={config.help.troubleshootingURL}
        slackChannelName={config.help.slackChannelName}
        slackURL={config.help.slackURL}
      />
    );
  }

  private renderAuthenticated() {
    const { scopes, settings, onSignOut } = this.props;

    return (
      <Router history={history}>
        <Page>
          <Helmet defaultTitle="Vitalstatistix" titleTemplate="%s | Vitalstatistix" />

          <TopNav
            avatar={null} // TODO: Okta doesn't return an avatar, figure out what to pass here
            onSignOut={onSignOut}
            searchComponent={scopes.accountsList ? AccountSearchContainer : null}
            history={history}
            menuItems={[
              { to: '/', name: 'Home', allowed: true },
              { to: '/accounts', name: 'Accounts', allowed: scopes.accountsList },
              {
                to: '/provisioning-plan-review',
                name: 'Provisioning Plan Review',
                allowed: scopes.accountProvisioning,
              },
              { to: '/calculator', name: 'Pricing Calculator', allowed: settings.pricingCalculator },
              { to: '/high-usage', name: 'High usage accounts', allowed: settings.enableHighUsageChart },
            ]}
          />

          <Switch>
            <Route exact path="/" render={() => <Redirect to="/accounts" />} />

            <Route path="/accounts" component={scopes.accountsList ? AccountsWrapper : AccessDenied} />

            <Route
              exact
              path="/provisioning-plan-review"
              component={scopes.accountProvisioning ? PlanReviewContainer : AccessDenied}
            />

            <Route
              exact
              path="/calculator"
              component={settings.pricingCalculator ? PricingCalculatorContainer : AccessDenied}
            />

            <Route
              exact
              path="/high-usage"
              component={settings.enableHighUsageChart ? HighAccountUsageContainer : AccessDenied}
            />

            <Route component={NotFound} />
          </Switch>
        </Page>
      </Router>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  settings: getSettings(state),
  scopes: getScopes(state),
});

initDatadogRUM();

export default connect(mapStateToProps)(Root);
