export type Plan = {
  accountID: string;
  organization: string;
  ldInstance: Instance;
  inputsHash: string;
  previousAccountConfig: AccountConfig;
  plannedConfig: AccountConfig;
  flagOverrides: { [kind in ProductKind]?: number };
  orders: OrderWithCollatedProducts[];
  immediatelyActionableSingleActionProducts: ImmediatelyActionableSingleActionProduct[];
};

export type ProcessedPlan = {
  organization: string;
  previousAccountConfig: AccountConfig;
  plannedConfig: AccountConfig;
  ldInstance: Instance;
  inputsHash: string;
  flagOverrides: ProductsByKind;
  startingLineItems: LineItem[];
  endingLineItems: LineItem[];
  washLineItems: LineItem[];
  participatingLineItems: LineItem[];
  immediatelyActionableSingleActionProducts: LineItem[];
};

export type AccountIdAndOrganization = {
  accountID: string;
  organization: string;
};

export type Account = {
  _id: string;
  organization: string;
  ownerEmail: string;
};

export type AccountConfig = {
  platform: Platform;
  trialEndDate: string;
  trialStartDate: string;
  seats: number;
  cMau: number;
  experimentEvents: number;
  experimentationKeys: number;
  dataExportEvents: number;
  sso: number;
  hosts: number;
};

export type Order = {
  orderID: number;
  orderSource: OrderSource;
  comment: string | null;
  sfdcOppId: string;
  products: Product[];
};

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
export type OrderWithCollatedProducts = Omit<Order, 'products'> & {
  productsByOperation: {
    ending: ProductsByDates;
    starting: ProductsByDates;
    wash: ProductsByDates;
    participating: ProductsByDates;
  };
};

export type Product = {
  id: number;
  orderID: number;
  sfdcProductId: string | null;
  platform: Platform;
  kind: ProductKind;
  qty: number;
  startDate: string;
  endDate: string;
  planUpID: number | null;
  planDownID: number | null;
};

export type ImmediatelyActionableSingleActionProduct = {
  product: Product;
  orderSource: OrderSource;
  sfdcOppId: string;
};

export type ProductsByDates = {
  [concatenatedStartAndEndDates: string]: Product[];
};

export type ProductsByKind = { [kind in ProductKind]?: number };

export type LineItem = {
  orderID: number;
  orderSource: OrderSource;
  sfdcOppId: string;
  startDate: string | null;
  endDate: string | null;
  platform: string;
  products: ProductsByKind;
};

export enum Instance {
  Commercial = 'Commercial',
  Federal = 'Federal',
}

export enum OrderSource {
  Manual = 'manual',
  Sfdc = 'sfdc',
  Launch = 'launch',
}

export enum Platform {
  StandardTrial = 'StandardTrial',
  EnterpriseTrial = 'EnterpriseTrial',
  Enterprise = 'Enterprise',
  Professional = 'Professional',
  StandardTrial2021 = 'StandardTrial2021',
  Starter2021 = 'Starter2021',
  Professional2021 = 'Professional2021',
  Foundation2023 = 'Foundation2023',
  Enterprise2023 = 'Enterprise2023',
  FoundationTrial2023 = 'FoundationTrial2023',
}

export enum ProductKind {
  Seats = 'seats',
  Cmau = 'c_mau',
  Experiments = 'experiment_events',
  ExperimentationKeys = 'experiment_keys',
  DataExport = 'data_export_events',
  SSO = 'sso',
  StandardTrial2021 = 'standard_trial_2021',
  EnterpriseTrial = 'enterprise_trial',
  Hosts = 'hosts',
}

export enum LimitOverrideFlag {
  Seats = 'account-limits-seats',
  Cmau = 'account-limits-client-maus',
  ExperimentEvents = 'account-limits-experiment-events',
  ExperimentationKeys = 'account-limits-experimentation-keys',
  DataExportEvents = 'account-limits-dataexport-events',
  SSO = 'account-limits-sso',
  Hosts = 'account-limits-hosts',
}
