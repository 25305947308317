import * as React from 'react';
import { connect } from 'react-redux';
import RedBox from 'redbox-react';
import history from '../../history';
import { Actions } from '../actions';
import { Scopes, User } from '../model';
import { getAuth } from '../selectors';

type ComponentWithAuthProps = Readonly<{
  user: User | null;
  isUserReady: boolean;
  onSignIn: () => void;
  onSignOut: () => void;
}>;

type OwnProps = Readonly<{
  children: (props: ComponentWithAuthProps) => React.ReactNode;
}>;

type StateProps = Readonly<{
  user: User | null;
  error: Error | null;
  isReady: boolean;
}>;

type DispatchProps = Readonly<{
  checkingAuth: () => void;
  signIn: () => void;
  onSignedIn: (user: User, scopes: Scopes) => void;
  signOut: () => void;
  onSignedOut: (error: Error | null) => void;
}>;

type WithAuthProps = OwnProps & StateProps & DispatchProps;

class WithAuth extends React.Component<WithAuthProps> {
  public componentDidMount() {
    // If user is coming from an OAuth redirect, redirect back to previous path
    this.redirectAfterOAuth();

    // Dispatch checkingAuth action to see if user is logged in
    this.props.checkingAuth();
  }

  public render() {
    const { user, error, isReady, children, signIn, signOut /*: child*/ } = this.props;

    const wrappedProps = {
      user,
      isUserReady: isReady,
      onSignIn: signIn,
      onSignOut: signOut,
    };

    if (error) {
      return <RedBox error={Error(error.message)} />;
    }

    return children(wrappedProps);
  }

  // If loading page after OAuth login, check if previous path was saved and push to browser history
  private redirectAfterOAuth = () => {
    const redirectPath = sessionStorage.getItem('oauthRedirectPath');
    if (redirectPath !== null) {
      sessionStorage.removeItem('oauthRedirectPath');
      history.replace(redirectPath);
    }
  };
}

const mapStateToProps = getAuth;

const mapDispatchToProps = {
  checkingAuth: Actions.checkingAuth,
  signIn: Actions.signIn,
  onSignedIn: Actions.signedIn,
  signOut: Actions.signOut,
  onSignedOut: Actions.signedOut,
};

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(WithAuth);
