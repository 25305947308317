import { Button, ButtonGroup, FormGroup, Icon, Intent, NumericInput, Tag, Tooltip } from '@blueprintjs/core';
import { format, subMonths } from 'date-fns';
import { Box, Flex } from 'grid-styled';
import * as React from 'react';
import * as Gravatar from 'react-gravatar';
import { Settings } from '../../settings';
import { BooleanTag, CopyToClipboard, Loading, Module, ModuleContent, Table } from '../../shared';
import {
  Account,
  CustomRole,
  getMemberDisplayName,
  hasName,
  isOwner,
  Member,
  MemberSessions,
  MembersPaginationData,
} from '../model';
import { pluralize } from '../utils';
import { CustomRoleDetails } from './CustomRoleDetails';
import { MFAStatusTag } from './MFAStatusTag';

import { Scopes } from '../../auth';
import './members.scss';

type MembersPanelProps = Readonly<{
  account: Account;
  settings: Settings;
  scopes: Scopes;
  memberSessions: MemberSessions[];
  members: Member[];
  customRoles: { [accountId: string]: CustomRole };
  onSetMfaDisabled: (account: Account, member: Member) => void;
  handlePaginateMembers: (url: string) => void;
  customRolesForMemberInTeams: (member: Member, customRoles: { [accountId: string]: CustomRole }) => any;
  getMemberSession: (memberId: string) => MemberSessions[];
  getMemberLastActive: (memberId: string, dateFormat: string) => React.ReactNode;
  paginationData: MembersPaginationData;
  isFetching: boolean;
}>;

type StateProps = {
  monthsAgo: number;
};

export class MembersPanel extends React.Component<MembersPanelProps, StateProps> {
  public state: StateProps = {
    monthsAgo: 1,
  };
  public render() {
    const {
      account,
      customRoles,
      members,
      settings,
      scopes,
      handlePaginateMembers,
      paginationData,
      getMemberLastActive,
      customRolesForMemberInTeams,
      isFetching,
    } = this.props;

    return (
      <Flex>
        <Box width={1} p={1}>
          <Flex justifyContent="space-between">
            {settings.enableMemberSessions && (
              <div className="AccountDetails-memberActivityCount">
                <div>
                  <strong>{this.getActiveMembersCount()}</strong> active members the past
                </div>
                <FormGroup className="MembersPanel-monthsAgoForm">
                  <NumericInput
                    className="MembersPanel-monthsAgoInput"
                    min={1}
                    id="events-published"
                    name="months-ago"
                    value={this.state.monthsAgo}
                    onValueChange={value => this.setState({ monthsAgo: value })}
                  />
                </FormGroup>
                {pluralize('month', this.state.monthsAgo)}
              </div>
            )}
            {settings.enablePaginationOfMembers && (
              <Flex>
                <ButtonGroup>
                  <Button
                    icon="double-chevron-left"
                    minimal={true}
                    onClick={() => handlePaginateMembers(paginationData.links.first.href)}
                    disabled={
                      (paginationData &&
                        paginationData.links &&
                        paginationData.links.first &&
                        paginationData.links.first.href) === undefined
                    }
                  />
                  <Button
                    icon="chevron-left"
                    minimal={true}
                    onClick={() => handlePaginateMembers(paginationData.links.prev.href)}
                    disabled={
                      (paginationData &&
                        paginationData.links &&
                        paginationData.links.prev &&
                        paginationData.links.prev.href) === undefined
                    }
                  />
                  <Button
                    icon="chevron-right"
                    minimal={true}
                    onClick={() => handlePaginateMembers(paginationData.links.next.href)}
                    disabled={
                      (paginationData &&
                        paginationData.links &&
                        paginationData.links.next &&
                        paginationData.links.next.href) === undefined
                    }
                  />
                  <Button
                    icon="double-chevron-right"
                    minimal={true}
                    onClick={() => handlePaginateMembers(paginationData.links.last.href)}
                    disabled={
                      (paginationData &&
                        paginationData.links &&
                        paginationData.links.last &&
                        paginationData.links.last.href) === undefined
                    }
                  />
                </ButtonGroup>
              </Flex>
            )}
          </Flex>
          <Module>
            <ModuleContent snug>
              <Table>
                <colgroup>
                  <col className="TableColumn-30" />
                  <col className="TableColumn-10" />
                  <col className="TableColumn-20" />
                  <col className="TableColumn-10" />
                  <col className="TableColumn-10" />
                  <col className="TableColumn-30" />
                </colgroup>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Role</th>
                    {settings.enableTeamsVisibility !== 'teams_off' && <th>Team</th>}
                    <th>MFA</th>
                    <th>Pending invite</th>
                    {settings.enableMemberSessions && <th>Last active</th>}
                    <th>ID</th>
                  </tr>
                </thead>
                <>
                  {isFetching ? (
                    <tbody>
                      <tr>
                        <td colSpan={6}>
                          <Flex justifyContent="center">
                            <Box>
                              <Loading title="Loading members" />
                            </Box>
                          </Flex>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <>
                      {members ? (
                        <tbody>
                          {members.map(m => (
                            <tr key={m._id}>
                              <td>
                                <Flex align="center">
                                  <Box pr={1}>
                                    <Gravatar email={m.email} size={24} />
                                  </Box>
                                  <Box>
                                    <Flex flexDirection="column">
                                      <Box>{getMemberDisplayName(m)}</Box>
                                      {hasName(m) && (
                                        <Box>
                                          <small className="pt-text-muted">{m.email}</small>
                                        </Box>
                                      )}
                                    </Flex>
                                  </Box>
                                </Flex>
                              </td>
                              <td>
                                {m.customRoles.length ? (
                                  <div>
                                    {m.customRoles.map(r => <CustomRoleDetails role={customRoles[r]} key={r} />)}
                                  </div>
                                ) : (
                                  <Tag intent={isOwner(m) ? Intent.PRIMARY : Intent.NONE}>{m.role}</Tag>
                                )}
                                {(() => {
                                  if (settings.enableTeamsVisibility === 'teams_v1') {
                                    return (
                                      <span>
                                        {customRolesForMemberInTeams(m, customRoles).map((r: CustomRole) => (
                                          <CustomRoleDetails role={customRoles[r._id]} key={r._id} />
                                        ))}
                                      </span>
                                    );
                                  }
                                  return <span />;
                                })()}
                              </td>
                              <td>
                                {(() => {
                                  if (settings.enableTeamsVisibility === 'teams_v1') {
                                    if (m.teams) {
                                      return (
                                        <div>
                                          {m.teams.map(team => <span className="MembersTeams">{team.key}</span>)}
                                        </div>
                                      );
                                    } else {
                                      return <BooleanTag value={false} />;
                                    }
                                  }
                                  if (settings.enableTeamsVisibility === 'teams_v2') {
                                    if (m.teams) {
                                      return (
                                        <div>
                                          {customRolesForMemberInTeams(m, customRoles).map((r: CustomRole) => (
                                            <CustomRoleDetails role={customRoles[r._id]} key={r._id} />
                                          ))}
                                        </div>
                                      );
                                    } else {
                                      return <BooleanTag value={false} />;
                                    }
                                  }
                                  return <span />;
                                })()}
                              </td>
                              <td>
                                <MFAStatusTag
                                  scopes={scopes}
                                  status={m.mfa}
                                  role={m.role}
                                  onMFADisable={() => this.props.onSetMfaDisabled(account, m)}
                                />
                              </td>
                              <td>
                                {m._pendingInvite && (
                                  <Icon icon="warning-sign" iconSize={24} title={null} color="orange" />
                                )}
                              </td>
                              {/* TODO: Replace the <td> content with MemberActivity component to render additional session details in drawer */}
                              {settings.enableMemberSessions && (
                                <td>
                                  <Tooltip content={this.getSessionAuthKind(m._id)}>
                                    {getMemberLastActive(m._id, 'timeAgo')}
                                  </Tooltip>
                                </td>
                              )}

                              <td>
                                <CopyToClipboard content={m._id}>
                                  <code>{m._id}</code>
                                </CopyToClipboard>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <div>No members</div>
                      )}
                    </>
                  )}
                </>
              </Table>
            </ModuleContent>
          </Module>
        </Box>
      </Flex>
    );
  }

  private getActiveMembersCount = () => {
    const { memberSessions, paginationData } = this.props;
    const { monthsAgo } = this.state;
    const dateNMonthsAgo = subMonths(new Date(), monthsAgo);
    const membersActivePastNMonths = memberSessions.filter(
      session => format(session.lastActiveDate) > format(dateNMonthsAgo),
    );
    return `${membersActivePastNMonths.length} / ${paginationData && paginationData.totalCount}`;
  };

  private getSessionAuthKind = (memberId: string) => {
    const memberSession = this.props.getMemberSession(memberId);
    if (memberSession.length) {
      const authKind = memberSession[0].authKind;
      switch (authKind) {
        case 'session':
          return 'This member was last active on the application';
        case 'token':
        case 'scoped':
          return 'This member was last active using an API access token';
        case 'snippet':
          return 'This member was last active using a snippet';
        case 'oauth2':
          return 'This member was last active using an OAuth application';
        case '':
        default:
          return 'Authorization information is unavailable for this member';
      }
    }
    // TODO: Add the actual date we began logging sessions once rolled out in prod
    return 'This member has not been active since we began logging member sessions';
  };
}
