import { Button, Colors, Dialog, Icon, InputGroup, Intent, MenuItem, Spinner, Tooltip } from '@blueprintjs/core';
import { DateRange, DateRangePicker } from '@blueprintjs/datetime';
import { IconNames } from '@blueprintjs/icons';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { AuditLogEntryDetails } from './AuditLogEntryDetails';
import { AuditLogEntryOverview } from './AuditLogEntryOverview';

import { endOfDay, format, subMonths } from 'date-fns';
import { Box, Flex } from 'grid-styled';
import * as React from 'react';
import { DefaultCallout } from '../../../shared';
import {
  Account,
  AuditLogEntries,
  AuditLogEntryDetail,
  Project,
  ResourcesReadableNames,
  ResourcesWrittenExpressions,
  ResourceType,
} from '../../model';
import './styles.scss';

const ResourceSelect = Select.ofType<ResourceType>();

const ResourceSelectTooltip = () => {
  return (
    <div>
      <p>
        Selecting a resource type will pre-populate the audit log resource query parameter with a reasonable default.
      </p>
      <p>The asterisks can be replaced with a specific resource name to filter.</p>
      {/* The resource specifier language below makes Prettier throws an error that says 'Comment location overlaps with node location' when formatting the file.
        Using `` to format the string resolve the issue */}
      <p>{`For example, proj/default:env/test:flag/* will return all flags in the test environment.`}</p>
    </div>
  );
};

const QueryTooltip = () => {
  return (
    <div>
      <p>Search for the provided string in the resource indicated by the resource specifier.</p>
      <p>
        For example, a query of “Manhattan” on a flag resource specifier will search for flags with “Manhattan” in its
        name or in its description.
      </p>
    </div>
  );
};

type StateProps = Readonly<{
  auditLogEntries: AuditLogEntries;
  entryDetails: AuditLogEntryDetail;
  projects: Project[];
  account: Account;
  onGetEntryDetails: (account: Account, entryUrl: string) => void;
  onGetEntries: (
    {
      account,
      nextUrl,
      query,
      spec,
      date,
    }: {
      account: Account;
      nextUrl?: string;
      query?: string;
      spec?: string;
      date?: ReadonlyArray<Date | null | number>;
    },
  ) => void;
}>;

type State = {
  query: string | undefined;
  spec: string | undefined;
  date: ReadonlyArray<Date | null | number>;
  dateSelected: DateRange;
  resourceSelected: ResourceType;
  isOpen: boolean;
};

export class AuditLog extends React.Component<StateProps, State> {
  public state: State = {
    query: undefined,
    spec: undefined,
    date: [null, null],
    dateSelected: [undefined, undefined],
    resourceSelected: ResourceType.NO_RESOURCE_SELECTED,
    isOpen: false,
  };
  public render() {
    const { account, auditLogEntries, entryDetails, projects, onGetEntryDetails } = this.props;

    return (
      <>
        <Box className="AuditlogHead">
          <Box className="AuditLogEntry-form">
            <Box className="AuditLogEntry-inputGroup">
              <Box className="AuditLogEntry-formGroup">
                <Box>
                  <label className="AuditLogEntry-label" htmlFor="resourceSpecifierSelect">
                    Optional Auto-Resource Specifier{' '}
                    <Tooltip className="AuditLogEntry-resourceSelectTooltip" content={<ResourceSelectTooltip />}>
                      <Icon icon={IconNames.INFO_SIGN} iconSize={15} color={Colors.GRAY1} />
                    </Tooltip>
                  </label>
                </Box>
                <Box className="AuditLogEntry-search" id="resourceSpecifierSelect">
                  <ResourceSelect
                    items={[
                      ResourceType.NO_RESOURCE_SELECTED,
                      ResourceType.FLAG,
                      ResourceType.PROJ,
                      ResourceType.ENV,
                      ResourceType.SEGMENT,
                      ResourceType.EXPERIMENT,
                      ResourceType.ACCT,
                      ResourceType.APPLICATION,
                      ResourceType.CODE_REFERENCE_REPOSITORY,
                      ResourceType.CONTEXT_KIND,
                      ResourceType.DESTINATION,
                      ResourceType.INTEGRATION,
                      ResourceType.MEMBER,
                      ResourceType.METRIC,
                      ResourceType.METRIC_GROUP,
                      ResourceType.RELAY_PROXY_GROUP,
                      ResourceType.RELEASE_PIPELINE,
                      ResourceType.ROLE,
                      ResourceType.SERVICE_TOKEN,
                      ResourceType.TEAM,
                      ResourceType.TOKEN,
                      ResourceType.TEMPLATE,
                      ResourceType.WEBHOOK,
                    ]}
                    itemRenderer={this.renderResourceItem}
                    onItemSelect={item => this.handleResourceItemSelect(item)}
                    filterable={false}
                    activeItem={this.state.resourceSelected}
                  >
                    <Button
                      text={ResourcesReadableNames[this.state.resourceSelected]}
                      rightIcon="double-caret-vertical"
                    />
                  </ResourceSelect>
                </Box>
              </Box>
              <Box className="AuditLogEntry-formGroup">
                <label className="AuditLogEntry-label" htmlFor="resourceSpecifierTextInput">
                  Resource specifier <span className="bp3-text-muted">{`(query param spec)`}</span>
                </label>
                <InputGroup
                  id="resourceSpecifierTextInput"
                  type="search"
                  className="AuditLogEntry-search"
                  placeholder="search resources"
                  onChange={this.handleSpecInputChange}
                  value={this.state.spec}
                />
              </Box>
            </Box>
            <Box className="AuditLogEntry-inputGroup">
              <Box className="AuditLogEntry-formGroup">
                <label className="AuditLogEntry-label" htmlFor="queryTextInput">
                  Query <span className="bp3-text-muted">{`(query param q)`}</span>{' '}
                  <Tooltip className="AuditLogEntry-resourceSelectTooltip" content={<QueryTooltip />}>
                    <Icon icon={IconNames.INFO_SIGN} iconSize={15} color={Colors.GRAY1} />
                  </Tooltip>
                </label>
                <InputGroup
                  id="queryTextInput"
                  type="search"
                  className="AuditLogEntry-search"
                  leftIcon="search"
                  placeholder="search entries"
                  onChange={this.handleQueryInputChange}
                />
              </Box>
              <Box className="AuditLogEntry-formGroup">
                <button className="AuditLogEntry-selectdate" onClick={this.selectDate}>
                  {this.state.dateSelected[0] && this.state.dateSelected[1]
                    ? `Date range: ${format(this.state.dateSelected[0] as Date, 'MM/DD/YYYY')} - ${format(
                        this.state.dateSelected[1] as Date,
                        'MM/DD/YYYY',
                      )}`
                    : 'Filter by date'}
                  {<Icon icon={IconNames.CHEVRON_DOWN} iconSize={16} />}
                </button>
                {this.state.date[0] && <Icon onClick={this.clearDates} icon={IconNames.FILTER_REMOVE} iconSize={16} />}

                <Dialog isOpen={this.state.isOpen} onClose={this.selectDate}>
                  <div className="AuditLogEntry-datepicker">
                    <DateRangePicker
                      onChange={this.handleDateSelection}
                      allowSingleDayRange={true}
                      initialMonth={subMonths(format(new Date(), 'MM/DD/YYYY'), 1)}
                      value={this.state.dateSelected}
                    />
                    <button className="AuditLogEntry--datepickerbutton" onClick={this.selectDate}>
                      {'CANCEL'}
                    </button>
                    <button className="AuditLogEntry--datepickerbutton" onClick={this.filterEntriesByDate}>
                      {'APPLY'}
                    </button>
                  </div>
                </Dialog>
              </Box>
            </Box>
            <Box className="AuditLogEntry-searchButtonAndDocLinkInputGroup">
              <Box className="AuditLogEntry-searchButtonAndDocLink">
                <Button
                  intent={Intent.PRIMARY}
                  type="button"
                  disabled={auditLogEntries.isFetching}
                  onClick={this.filterEntriesByQuery}
                  fill={true}
                  large={true}
                >
                  Search
                </Button>
              </Box>
              <Box className="AuditLogEntry-searchButtonAndDocLink">
                <a
                  href="https://docs.launchdarkly.com/home/members/role-resources#understanding-resource-types-and-scopes"
                  target="_blank"
                >
                  Resource specifier documentation <Icon icon={IconNames.SHARE} iconSize={15} color={Colors.GRAY1} />
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
        {auditLogEntries.entries.length ? (
          auditLogEntries.entries.map(entry => (
            <Box className="AuditLogEntry" key={entry._id}>
              <Flex flexDirection="column">
                <AuditLogEntryOverview entry={entry} projects={projects} />
              </Flex>
              <Box>
                <AuditLogEntryDetails
                  account={account}
                  entryUrl={entry._links.self.href}
                  projects={projects}
                  onGetEntryDetails={onGetEntryDetails}
                  entryDetails={entryDetails}
                />
              </Box>
            </Box>
          ))
        ) : auditLogEntries.isFetching ? (
          <Spinner className="AuditLogEntry-spinner" size={25} />
        ) : (
          <DefaultCallout>No entries match your search.</DefaultCallout>
        )}
        {auditLogEntries._links.next && (
          <button disabled={auditLogEntries.isFetching} className="AuditLogEntry-button" onClick={this.getMoreEntries}>
            {auditLogEntries.isFetching ? 'LOADING...' : 'LOAD MORE'}
          </button>
        )}
      </>
    );
  }

  private handleQueryInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ query: event.currentTarget.value });
  };

  private handleSpecInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ spec: event.currentTarget.value });
  };

  private handleResourceItemSelect = (item: ResourceType) => {
    this.setState({ spec: ResourcesWrittenExpressions[item], resourceSelected: item });
  };

  private filterEntriesByQuery = () => {
    if (this.state.date[0]) {
      this.props.onGetEntries({
        account: this.props.account,
        nextUrl: undefined,
        query: this.state.query,
        spec: this.state.spec,
        date: this.state.date,
      });
    } else {
      this.props.onGetEntries({
        account: this.props.account,
        nextUrl: undefined,
        query: this.state.query,
        spec: this.state.spec,
      });
    }
  };

  private selectDate = () => {
    if (this.state.dateSelected[0] && !this.state.date[0]) {
      this.setState({
        dateSelected: [undefined, undefined],
        isOpen: false,
      });
    } else {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  private clearDates = () => {
    this.setState({ date: [null, null], dateSelected: [undefined, undefined], isOpen: false }, () =>
      this.props.onGetEntries({
        account: this.props.account,
        nextUrl: undefined,
        query: this.state.query,
        date: this.state.date,
      }),
    );
  };

  private handleDateSelection = (selectedDates: DateRange) => {
    this.setState({
      dateSelected: selectedDates,
    });
  };

  private filterEntriesByDate = () => {
    const { dateSelected } = this.state;
    if (dateSelected[0] && dateSelected[1]) {
      this.setState(
        {
          date: [(dateSelected[0] as Date).getTime(), endOfDay(dateSelected[1] as Date).getTime()],
          isOpen: false,
        },
        () =>
          this.props.onGetEntries({
            account: this.props.account,
            nextUrl: undefined,
            query: this.state.query,
            spec: this.state.spec,
            date: this.state.date,
          }),
      );
    } else if (!dateSelected[0] && !dateSelected[1]) {
      this.setState({ date: [null, null] }, () =>
        this.props.onGetEntries({
          account: this.props.account,
          nextUrl: undefined,
          query: this.state.query,
          spec: this.state.spec,
          date: this.state.date,
        }),
      );
    }
  };

  private getMoreEntries = () => {
    this.props.onGetEntries({ account: this.props.account, nextUrl: this.props.auditLogEntries._links.next.href });
  };

  private renderResourceItem: ItemRenderer<ResourceType> = (resource, props) => (
    <MenuItem
      active={props.modifiers.active}
      onClick={props.handleClick}
      key={resource}
      text={ResourcesReadableNames[resource]}
    />
  );
}
