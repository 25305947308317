import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from '../../reducer';
import { createUsageShortcuts, Metadata, Series } from '../../shared/index';
import { Actions } from '../actions';

import * as React from 'react';
import { AccountUsage } from '../components/AccountUsage';
import { TimeRangeFilter } from '../components/TimeRangeFilter';
import {
  Account,
  ChartTypes,
  getDefaultDateRangeForUsage,
  UsageFilters,
  UsageIdentifier,
  UsageOrigin,
  UsageTypes,
} from '../model';
import { dataExportSelector } from '../selectors';

type OwnProps = Readonly<{
  account: Account;
  limit: number;
}>;

type StateProps = Readonly<{
  isFetching: boolean;
  metadata: Metadata;
  series: Series;
  chartType: ChartTypes;
  tooltipText: string;
}>;

type DispatchProps = Readonly<{
  onFetchAccountUsage: (filters: UsageFilters) => void;
}>;

type State = {
  readonly timeRange: { to: number; from: number };
  readonly selectedTimeOption?: string;
};

type DataExportUsageContainerProps = StateProps &
  Readonly<{
    onFetchAccountUsage: (filters: any) => void;
    account: Account;
  }>;

export class DataExportUsageContainer extends React.Component<DataExportUsageContainerProps> {
  public state: State = {
    timeRange: getDefaultDateRangeForUsage(),
    selectedTimeOption: 'Last 30 days',
  };
  public componentDidMount() {
    this.props.onFetchAccountUsage(this.state.timeRange);
  }

  public render() {
    const { metadata, chartType, tooltipText, series, onFetchAccountUsage, isFetching } = this.props;
    return (
      <AccountUsage
        renderFilters={() => this.renderFilters()}
        series={series}
        metadata={metadata}
        onFetchAccountUsage={onFetchAccountUsage}
        chartType={chartType}
        tooltipText={tooltipText}
        isFetching={isFetching}
      />
    );
  }

  private renderFilters = () => (
    <TimeRangeFilter
      onSelect={(value: { to: number; from: number }, label: string) => this.handleTimeSelect(value, label)}
      selectedOption={this.state.selectedTimeOption}
      value="hours"
      timeRange={createUsageShortcuts()}
    />
  );

  private handleTimeSelect = (value: { to: number; from: number }, label: string) => {
    this.setState({ selectedTimeOption: label });
    this.props.onFetchAccountUsage(value);
  };
}

const mapStateToProps: (state: AppState) => StateProps = state => ({
  ...dataExportSelector(state),
  chartType: ChartTypes.BAR,
  tooltipText: 'published event',
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => ({
  onFetchAccountUsage: (filters: UsageFilters) =>
    dispatch(
      Actions.fetchAccountUsage({
        account: ownProps.account,
        usageIdentifier: UsageIdentifier.DATA_EXPORT,
        usageType: UsageTypes.PUBLISHED,
        metric: UsageOrigin.EVENTS,
        filters,
      }),
    ),
  chartType: ChartTypes.BAR,
});

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DataExportUsageContainer);
