import { Button, Callout, Classes, Intent } from '@blueprintjs/core';
import * as cx from 'classnames';
import * as React from 'react';
import './styles.scss';
// tslint:disable-next-line:no-var-requires
const image = require('../../../images/vitalstatistix-shield.gif');

type Props = Readonly<{
  onSignIn: () => void;
  slackURL?: string;
  slackChannelName?: string;
  troubleshootingURL?: string;
}>;

export const SignIn = ({ onSignIn, slackURL, slackChannelName, troubleshootingURL }: Props) => (
  <div className="SignIn">
    <div className="SignIn-inner">
      <img className="SignIn-image" src={image} />
      <Button intent={Intent.PRIMARY} className="pt-large xpt-minimal" onClick={() => onSignIn()}>
        Sign in with Okta SSO
      </Button>
    </div>

    <Callout title="Are you having trouble signing in?" className={cx('SignIn-callout', Classes.RUNNING_TEXT)}>
      Visit the <a href={troubleshootingURL}>troubleshooting page on Confluence</a> or 👋 in{' '}
      <a href={slackURL}>#{slackChannelName}</a> on Slack.
    </Callout>
  </div>
);
