import { addMinutes } from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { getScopes, getUser, Scopes, User } from '../../auth';
import { AppState } from '../../reducer';
import { getSettings, Settings } from '../../settings';
import { Loading, LoadingError, ResourceNotFound } from '../../shared';
import { RequestError } from '../../shared';
import { Actions } from '../actions';
import { AccountDetails } from '../components';
import {
  Account,
  AuditLogEntries,
  AuditLogEntryDetail,
  CustomRole,
  LegacySubscription,
  Member,
  MemberSessions,
  OwnerPromotion,
  Plan,
  Project,
  SavedAccount,
  ScheduledTrial,
  Subscription,
  SubscriptionUsage,
  TrialFeatures,
} from '../model';
import {
  getAccountById,
  getAccountOwnerUpdateRequest,
  getAuditLogEntries,
  getAuditLogEntry,
  getCustomRoles,
  getIsFavorited,
  getLegacySubscription,
  getMemberSessions,
  getProjects,
  getScheduledTrialsForAccount,
  getSubscription,
  getSubscriptionUpdateRequest,
  getSubscriptionUsage,
} from '../selectors';

type StateProps = Readonly<{
  settings: Settings;
  scopes: Scopes;
  account: Account;
  isUpdatingSubscription: boolean;
  isUpdatingAccountOwner: boolean;
  subscription: Subscription;
  legacySubscription: LegacySubscription;
  memberSessions: MemberSessions[];
  customRoles: { [accountId: string]: CustomRole };
  projects: Project[];
  isFavorited: boolean;
  auditLogEntries: AuditLogEntries;
  entryDetails: AuditLogEntryDetail;
  isFetching: boolean;
  error: RequestError;
  subscriptionUsage: SubscriptionUsage;
  user: User;
  scheduledTrials: { [id in TrialFeatures]: ScheduledTrial[] };
}>;

type OwnProps = RouteComponentProps<{ accountId: string }>;

type DispatchProps = Readonly<{
  onFetchAccount: (id: string) => void;
  onChangeLegacyTrialEnd: (account: Account, legacySubscription: LegacySubscription, date: Date) => void;
  onChangeLegacyGraceEnd: (account: Account, legacySubscription: LegacySubscription, date: Date) => void;
  onChangeTrialEnd: (account: Account, subscription: Subscription, date: Date) => void;
  onChangeGraceEnd: (account: Account, subscription: Subscription, date: Date) => void;
  onChangeAccountOwner: (account: Account, promotion: OwnerPromotion) => void;
  onSubscribe: (account: Account, subscription: Subscription, plan: Plan) => void;
  onCancelSubscription: (account: Account, endImmediately: boolean) => void; // Updated to include endImmediately
  onClearPendingSubscriptionUpdate: (account: Account, subscription: Subscription) => void;
  onUpdateStripeSubscriptionId: (account: Account, subscription: Subscription, stripeSubscriptionId: string) => void;
  onGetEntries: (
    account: Account,
    url?: string,
    query?: string,
    spec?: string,
    date?: ReadonlyArray<Date | null>,
  ) => void;
  onGetEntryDetails: (account: Account, entryUrl: string) => void;
  onAddFavoritedAccount: (favoritedAccount: SavedAccount) => void;
  onRemoveFavoritedAccount: (favoritedAccount: SavedAccount) => void;
  onGetFavoritedAccounts: () => void;
  onSetFeatureTrial: (account: Account, flagKey: string) => void;
  onSetFeatureExpirationDate: (account: Account, flagKey: string, date: number) => void;
  onFetchScheduledTrials: (accountId: string, flagKey: string) => void;
  onSetMfaDisabled: (account: Account, member: Member) => void;
  onSetSsoDisabled: (account: Account) => void;
  onChangeEnterpriseCampaignDates(account: Account, subscription: Subscription, date: Date): void;
}>;

type AccountPageProps = StateProps & DispatchProps & OwnProps;

class AccountPage extends React.Component<AccountPageProps, any> {
  public componentWillMount() {
    const { match, onFetchAccount, onFetchScheduledTrials, onGetEntries, settings, scopes } = this.props;
    onFetchAccount(match.params.accountId);
    if (settings.enableSchedulingTrialEntitlements) {
      onFetchScheduledTrials(match.params.accountId, TrialFeatures.DATA_EXPORT_KEY);
      onFetchScheduledTrials(match.params.accountId, TrialFeatures.EXPERIMENTATION_KEY);
    }
    if (scopes.auditLog) {
      onGetEntries({ _id: match.params.accountId } as Account);
    }
  }

  public componentWillReceiveProps(nextProps: AccountPageProps) {
    if (this.props.match.params.accountId !== nextProps.match.params.accountId) {
      this.props.onFetchAccount(nextProps.match.params.accountId);
    }
  }

  public render() {
    const {
      isFetching,
      match,
      settings,
      scopes,
      account,
      legacySubscription,
      subscription,
      isUpdatingSubscription,
      isUpdatingAccountOwner,
      memberSessions,
      customRoles,
      projects,
      isFavorited,
      error,
      subscriptionUsage,
      user,
      auditLogEntries,
      entryDetails,
      scheduledTrials,
    } = this.props;

    if (isFetching) {
      return <Loading title={`Loading ${match.params.accountId}`} />;
    }

    if (error) {
      switch (error.status) {
        case 404:
          return (
            <ResourceNotFound
              title="Account not found"
              description={
                <p>
                  <code>{match.params.accountId}</code> was not found
                </p>
              }
              returnTo="/accounts"
              returnLabel="Go back to accounts"
            />
          );
        default:
          return <LoadingError title={`Failed to load account ${match.params.accountId}`} />;
      }
    }

    return (
      <AccountDetails
        settings={settings}
        scopes={scopes}
        account={account}
        scheduledTrials={scheduledTrials}
        subscriptionUsage={subscriptionUsage}
        legacySubscription={legacySubscription}
        subscription={subscription}
        isUpdatingSubscription={isUpdatingSubscription}
        isUpdatingAccountOwner={isUpdatingAccountOwner}
        memberSessions={memberSessions}
        customRoles={customRoles}
        projects={projects}
        isFavorited={isFavorited}
        auditLogEntries={auditLogEntries}
        entryDetails={entryDetails}
        user={user}
        onChangeTrialEnd={this.handleChangeTrialEnd}
        onChangeGraceEnd={this.handleChangeGraceEnd}
        onChangeLegacyTrialEnd={this.handleChangeLegacyTrialEnd}
        onChangeLegacyGraceEnd={this.handleChangeLegacyGraceEnd}
        onChangeAccountOwner={this.handleChangeAccountOwner}
        onSubscribe={this.handleSubscribe}
        onCancelSubscription={this.handleCancelSubscription}
        onUpdateStripeSubscriptionId={this.handleUpdateStripeSubscriptionId}
        onClearPendingSubscriptionUpdate={this.handleClearPendingSubscriptionUpdate}
        onGetEntries={this.handleOnGetEntries}
        onGetEntryDetails={this.handleOnGetEntryDetails}
        onAddFavoritedAccount={this.handleAddFavoritedAccount}
        onRemoveFavoritedAccount={this.handleRemoveFavoritedAccount}
        onGetFavoritedAccounts={this.handleGetFavoritedAccounts}
        onSetFeatureTrial={this.handleOnSetFeatureTrial}
        onSetFeatureExpirationDate={this.handleOnSetFeatureExpirationDate}
        onChangeEnterpriseCampaignDates={this.handleChangeEnterpriseCampaignDates}
        onSetMfaDisabled={this.props.onSetMfaDisabled}
        setSsoDisabled={this.props.onSetSsoDisabled.bind(null, this.props.account)}
      />
    );
  }

  private handleOnSetFeatureTrial = (flagKey: string) => {
    const { account, onSetFeatureTrial } = this.props;
    return onSetFeatureTrial(account, flagKey);
  };

  private handleOnSetFeatureExpirationDate = (flagKey: string) => {
    const { account, onSetFeatureExpirationDate } = this.props;
    return onSetFeatureExpirationDate(account, flagKey, addMinutes(new Date(), 1).valueOf());
  };

  private handleAddFavoritedAccount = (favoritedAccount: SavedAccount) => {
    const { onAddFavoritedAccount } = this.props;
    return onAddFavoritedAccount(favoritedAccount);
  };

  private handleRemoveFavoritedAccount = (favoritedAccount: SavedAccount) => {
    const { onRemoveFavoritedAccount } = this.props;
    return onRemoveFavoritedAccount(favoritedAccount);
  };

  private handleGetFavoritedAccounts = () => {
    const { onGetFavoritedAccounts } = this.props;
    return onGetFavoritedAccounts();
  };

  private handleChangeLegacyTrialEnd = (date: Date) => {
    this.props.onChangeLegacyTrialEnd(this.props.account, this.props.legacySubscription, date);
  };

  private handleChangeLegacyGraceEnd = (date: Date) => {
    this.props.onChangeLegacyGraceEnd(this.props.account, this.props.legacySubscription, date);
  };

  private handleChangeTrialEnd = (date: Date) => {
    this.props.onChangeTrialEnd(this.props.account, this.props.subscription, date);
  };

  private handleChangeGraceEnd = (date: Date) => {
    this.props.onChangeGraceEnd(this.props.account, this.props.subscription, date);
  };

  private handleChangeEnterpriseCampaignDates = (date: Date) => {
    this.props.onChangeEnterpriseCampaignDates(this.props.account, this.props.subscription, date);
  };

  private handleChangeAccountOwner = (promotion: OwnerPromotion) => {
    this.props.onChangeAccountOwner(this.props.account, promotion);
  };

  private handleSubscribe = (plan: Plan) => {
    this.props.onSubscribe(this.props.account, this.props.subscription, plan);
  };

  private handleCancelSubscription = (account: Account, endImmediately: boolean) => {
    this.props.onCancelSubscription(account, endImmediately);
  };

  private handleUpdateStripeSubscriptionId = (id: string) => {
    this.props.onUpdateStripeSubscriptionId(this.props.account, this.props.subscription, id);
  };

  private handleClearPendingSubscriptionUpdate = () => {
    this.props.onClearPendingSubscriptionUpdate(this.props.account, this.props.subscription);
  };

  private handleOnGetEntryDetails = (account: Account, entryUrl: string) => {
    this.props.onGetEntryDetails(account, entryUrl);
  };

  private handleOnGetEntries = ({
    account,
    nextUrl,
    query,
    spec,
    date,
  }: {
    account: Account;
    nextUrl?: string;
    query?: string;
    spec?: string;
    date?: ReadonlyArray<Date | null>;
  }) => {
    this.props.onGetEntries(account, nextUrl, query, spec, date);
  };
}

const mapStateToProps = (state: AppState, props: OwnProps) => ({
  settings: getSettings(state),
  scopes: getScopes(state),
  ...getAccountById(state, { accountId: props.match.params.accountId }),
  legacySubscription: getLegacySubscription(state, {
    accountId: props.match.params.accountId,
  }),
  subscription: getSubscription(state, {
    accountId: props.match.params.accountId,
  }),

  subscriptionUsage: getSubscriptionUsage(state),
  scheduledTrials: getScheduledTrialsForAccount(state),
  isUpdatingSubscription: getSubscriptionUpdateRequest(state).isFetching,
  isUpdatingAccountOwner: getAccountOwnerUpdateRequest(state).isFetching,
  memberSessions: getMemberSessions(state, { accountId: props.match.params.accountId }),
  projects: getProjects(state, { accountId: props.match.params.accountId }),
  customRoles: getCustomRoles(state, {
    accountId: props.match.params.accountId,
  }),

  isFavorited: getIsFavorited({
    accountId: props.match.params.accountId,
  }),

  auditLogEntries: getAuditLogEntries(state, {
    accountId: props.match.params.accountId,
  }),

  entryDetails: getAuditLogEntry(state, {
    accountId: props.match.params.accountId,
  }),
  user: getUser(state),
});

const mapDispatchToProps = {
  onAddFavoritedAccount: Actions.addFavoritedAccount,
  onRemoveFavoritedAccount: Actions.removeFavoritedAccount,
  onGetFavoritedAccounts: Actions.getFavoritedAccount,
  onFetchAccount: Actions.fetchAccount,
  onChangeLegacyTrialEnd: Actions.updateLegacyTrialEnd,
  onChangeLegacyGraceEnd: Actions.updateLegacyGraceEnd,
  onChangeTrialEnd: Actions.updateTrialEnd,
  onChangeGraceEnd: Actions.updateGraceEnd,
  onChangeAccountOwner: Actions.updateAccountOwner,
  onSubscribe: Actions.subscribe,
  onCancelSubscription: Actions.cancelSubscription,
  onUpdateStripeSubscriptionId: Actions.updateStripeSubscriptionId,
  onClearPendingSubscriptionUpdate: Actions.clearPendingSubscriptionUpdate,
  onGetEntries: Actions.fetchAuditLogEntries,
  onGetEntryDetails: Actions.fetchAuditLogEntryDetail,
  onSetFeatureTrial: Actions.setFeatureTrial,
  onSetFeatureExpirationDate: Actions.setFeatureExpirationDate,
  onFetchScheduledTrials: Actions.fetchScheduledTrials,
  onSetMfaDisabled: Actions.setMfaDisabled,
  onSetSsoDisabled: Actions.setSsoDisabled,
  onChangeEnterpriseCampaignDates: Actions.updateEnterpriseCampaignDates,
};

export const AccountPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountPage);
