import {
  Button,
  ButtonGroup,
  Icon,
  Intent,
  Menu,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core';
import { values } from 'lodash';
import * as React from 'react';

import './AccountList.scss';

type TimeRangeFilterProps = {
  onSelect: ({ to, from }: { to: number; from: number }, label: string) => void;
  value: string;
  selectedOption?: string;
  timeRange: any;
  rightMarginSpacing?: boolean;
};

type TimeRangeFilterState = {
  value?: string;
  isOpen: boolean;
  label?: string;
};

export class TimeRangeFilter extends React.Component<TimeRangeFilterProps, TimeRangeFilterState> {
  public state: TimeRangeFilterState = {
    value: 'test',
    isOpen: false,
  };

  public render() {
    const { isOpen } = this.state;

    return (
      <ButtonGroup className={this.props.rightMarginSpacing ? 'TimeRangeFilter--margin-right' : ''}>
        <Popover
          isOpen={isOpen}
          className="HighUsage--popover"
          interactionKind={PopoverInteractionKind.CLICK}
          position={Position.BOTTOM}
          onInteraction={this.handlePopoverInteraction}
        >
          <Button intent={Intent.NONE}>
            {this.getDescription()}
            <span className="HighUsageFilter--chevron-down">
              <Icon icon="chevron-down" className="DownCarrot" />
            </span>
          </Button>
          <div>
            <Menu>
              {this.getOptions().map((d: { name: string; value: [number, number] }, i: number) => (
                <MenuItem text={d.name} onClick={() => this.handleSelect(d)} key={i} />
              ))}
            </Menu>
          </div>
        </Popover>
      </ButtonGroup>
    );
  }

  private getOptions = () => {
    return this.props.timeRange.map((sc: object) => {
      const value: any = values(sc)[0];
      return {
        name: value.label,
        value: value.dateRange,
        isChecked: value.label === this.state.label,
      };
    });
  };

  private getDescription = () => {
    const { selectedOption } = this.props;
    return selectedOption ? selectedOption : this.state.label;
  };

  private handleSelect = (dates: { name: string; value: [number, number] }) => {
    const [startDate, endDate] = dates.value;
    this.setState({ label: dates.name });

    this.props.onSelect({ to: endDate.valueOf(), from: startDate.valueOf() }, dates.name);
  };

  private handlePopoverInteraction = (nextOpenState: boolean) => {
    if (this.props.value) {
      this.setState(prevState => ({
        isOpen: nextOpenState,
        value: nextOpenState ? prevState.value : this.props.value,
      }));
    }
  };
}
