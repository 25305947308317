import { Flex } from 'grid-styled';
import React = require('react');
import { FilterMenu } from '../../../shared/components/FilterMenu';
import { SubscriptionState, SubscriptionStateReadableNames } from '../../model';
import './SubscriptionStateSelect.scss';

const subscriptionStateOptions = [
  { name: 'All', value: '' },
  { isDivider: true },
  ...(Object.keys(SubscriptionState) as Array<keyof typeof SubscriptionState>).map(key => ({
    name: SubscriptionStateReadableNames[SubscriptionState[key]],
    value: SubscriptionState[key],
  })),
];

export function SubscriptionStateSelect({
  subStateFilter,
  setSubStateFilter,
}: {
  subStateFilter: SubscriptionState | undefined;
  setSubStateFilter: (item?: string) => void;
}) {
  return (
    <Flex>
      <FilterMenu
        className="SubscriptionFilter"
        label="Subscription state"
        options={subscriptionStateOptions}
        onChange={setSubStateFilter}
        initialValue={subStateFilter}
      />
    </Flex>
  );
}
