import { groupBy, some, sortBy, values } from 'lodash';
import { createSelector } from 'reselect';
import { AppState } from '../reducer';
import { getAccountDisplayName, SavedAccount } from './model';
import { SDKUsageState, UsageState } from './reducer';
import { localStorageKeys } from './storageUtils';
import { convertGroupedSDKsToFlatArray } from './utils';

export const getAccounts = (state: AppState) => state.accounts;

export const getAccountsRequest = (state: AppState) => getAccounts(state).request;

export const getAllAccounts = (state: AppState) => ({
  ...getAccountsRequest(state),
  accounts: sortBy(values(getAccounts(state).entities), account => getAccountDisplayName(account).toLowerCase()),
});

export const getAccountRequestById = (state: AppState, props: { accountId: string }) =>
  getAccounts(state).requestsById[props.accountId];

export const getLegacySubscriptionRequestByAccountId = (state: AppState, props: { accountId: string }) =>
  getAccounts(state).subscriptionRequestByAccountId[props.accountId];

export const getMembersRequestByAccountId = (state: AppState, props: { accountId: string }) =>
  getAccounts(state).membersRequestByAccountId[props.accountId];

export const getMemberSessionsRequestByAccountId = (state: AppState, props: { accountId: string }) =>
  getAccounts(state).memberSessionsRequestByAccountId[props.accountId];

export const getCustomRolesRequestByAccountId = (state: AppState, props: { accountId: string }) =>
  getAccounts(state).customRolesRequestByAccountId[props.accountId];

export const getAccountEntityById = (state: AppState, props: { accountId: string }) =>
  getAccounts(state).entities[props.accountId];

export const getAccountById = (state: AppState, props: { accountId: string }) => {
  const reqs = [
    getAccountRequestById(state, props),
    getLegacySubscriptionRequestByAccountId(state, props),
    getCustomRolesRequestByAccountId(state, props),
    getSubscriptionFetchRequest(state),
    getSubscriptionUsageFetchRequest(state),
  ];

  const error = reqs.map(req => req && req.error)[0];
  const isFetching = !error && some(reqs, req => (req ? req.isFetching : true));

  return {
    isFetching,
    error,
    account: getAccountEntityById(state, props),
  };
};

export const getIsFavorited = (props: { accountId: string }) => {
  const favoritedAccounts: SavedAccount[] = window.localStorage.getItem(localStorageKeys.FAVORITED)
    ? JSON.parse(window.localStorage.favoritedVitalstatistixAccounts)
    : [];
  const favoritedAccountIds: string[] = favoritedAccounts.map(favorite => favorite.id);
  return favoritedAccountIds.includes(props.accountId);
};

export const getLegacySubscription = (state: AppState, props: { accountId: string }) =>
  getAccounts(state).legacySubscriptionByAccount[props.accountId];

export const getMembersPaginationData = (state: AppState, props: { accountId: string }) =>
  getAccounts(state).membersPaginationData[props.accountId];

export const getMembers = (state: AppState, props: { accountId: string }) =>
  values(getAccounts(state).membersByAccount[props.accountId]);

export const getMembersForExport = (state: AppState, props: { accountId: string }) =>
  values(getAccounts(state).membersForExportByAccount[props.accountId]);

export const getMemberSessions = (state: AppState, props: { accountId: string }) =>
  values(getAccounts(state).memberSessionsByAccount[props.accountId]);

export const getCustomRoles = (state: AppState, props: { accountId: string }) =>
  getAccounts(state).customRolesByAccount[props.accountId];

export const getProjects = (state: AppState, props: { accountId: string }) =>
  values(getAccounts(state).projectsByAccount[props.accountId]);

export const getAuditLogEntries = (state: AppState, props: { accountId: string }) =>
  getAccounts(state).auditLogEntriesByAccount[props.accountId];

export const getAuditLogEntry = (state: AppState, props: { accountId: string }) =>
  getAccounts(state).auditLogEntryDetailByAccount[props.accountId];

export const getAccountSearch = createSelector(getAccounts, ({ entities, search }) => ({
  ...search,
  results: search.results.map(id => entities[id]).filter(r => !!r),
}));

const createUsageSelector = (rootSelector: any) =>
  createSelector(rootSelector, (state: UsageState) => {
    return {
      metadata: state.data.metadata,
      isFetching: state.usageRequest.isFetching,
      series: state.data.series,
      error: state.usageRequest.error,
    };
  });

export const serverConnectionsSelector = createUsageSelector(
  (state: AppState) => getAccounts(state).usage.serverConnections,
);

export const serverMAUSelector = createUsageSelector((state: AppState) => getAccounts(state).usage.serverMAU);

export const clientMAUSelector = createUsageSelector((state: AppState) => getAccounts(state).usage.clientMAU);

export const experimentsSelector = createUsageSelector((state: AppState) => getAccounts(state).usage.experiments);

export const clientConnectionsSelector = createUsageSelector(
  (state: AppState) => getAccounts(state).usage.clientConnections,
);

export const dataExportSelector = createUsageSelector((state: AppState) => getAccounts(state).usage.dataExport);

export const sdkVersionsSelector = (rootSelector: any) =>
  createSelector(rootSelector, (state: SDKUsageState) => {
    const groupedSDKs = groupBy(state.data, 'sdk');
    const SDKs = state.data.length > 1 ? convertGroupedSDKsToFlatArray(groupedSDKs) : [];

    return {
      isFetching: state.sdkRequest.isFetching,
      SDKs,
      groupedSDKs,
    };
  });

export const serverSDKSelector = sdkVersionsSelector((state: AppState) => getAccounts(state).sdk.serverSDK);
export const clientSDKSelector = sdkVersionsSelector((state: AppState) => getAccounts(state).sdk.clientSDK);

export const getSubscription = (state: AppState, props: { accountId: string }) =>
  getAccounts(state).subscriptionByAccount[props.accountId];

export const getHighAccountUsage = (state: AppState) => getAccounts(state).highAccountUsage;

export const getHighAccountUsageFetchRequest = (state: AppState) => getAccounts(state).highAccountUsageFetchRequest;

export const getSubscriptionUsage = (state: AppState) => getAccounts(state).subscriptionUsage;

export const getScheduledTrialsForAccount = (state: AppState) => getAccounts(state).scheduledTrialEntriesByAccount;

export const getSubscriptionUsageByAccount = (state: AppState, props: { accountId: string }) =>
  getAccounts(state).subscriptionUsageByAccount[props.accountId];

export const getSubscriptionFetchRequest = (state: AppState) => getAccounts(state).subscriptionFetchRequest;

export const getSubscriptionUsageFetchRequest = (state: AppState) => getAccounts(state).subscriptionUsageFetchRequest;

export const getSubscriptionUpdateRequest = (state: AppState) => getAccounts(state).subscriptionUpdateRequest;

export const getAccountOwnerUpdateRequest = (state: AppState) => getAccounts(state).ownerUpdateRequest;
