import { fill } from 'lodash';
import * as React from 'react';
import { AccountMetrics, MetricType, Subscription, SubscriptionUsage } from '../model';
import { summarizeSubscriptionUsage } from './SubscriptionAccountDetails';

type OveragesProps = Readonly<{
  subscriptionUsage: SubscriptionUsage | null;
  subscription: Subscription;
}>;

export const Overages = ({ subscriptionUsage, subscription }: OveragesProps) => {
  if (!subscriptionUsage || !subscription) {
    return <>{fill(Array(4), 2).map(() => <td>loading</td>)}</>;
  }
  const overages = renderUsage(subscriptionUsage._usage, subscription);
  return (
    <>
      {overages.map((d: { usage: number; limit: number }) => (
        <td>{d.limit === 0 ? 'N/A' : summarizeSubscriptionUsage(d.usage, d.limit)}</td>
      ))}
    </>
  );
};

const renderUsage = (subscriptionUsage: AccountMetrics, subscription: Subscription) => [
  {
    usage: subscriptionUsage[MetricType.SEATS],
    limit: subscription._limits[MetricType.SEATS],
  },
  {
    usage: subscriptionUsage[MetricType.MONTHLY_ACTIVE_USERS],
    limit: subscription._limits[MetricType.MONTHLY_ACTIVE_USERS],
  },
  {
    usage: subscriptionUsage[MetricType.EVENTS_PUBLISHED],
    limit: subscription._limits[MetricType.EVENTS_PUBLISHED],
  },
  {
    usage: subscriptionUsage[MetricType.EVENTS_RECEIVED],
    limit: subscription._limits[MetricType.EVENTS_RECEIVED],
  },
];
