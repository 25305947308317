import * as cx from 'classnames';
import * as React from 'react';
import './styles.scss';

type ModuleProps = Readonly<{
  className?: any;
  children?: any;
  id?: string;
}>;

export const Module = ({ className, id, children }: ModuleProps) => (
  <div className={cx('Module', className)} id={id}>
    {children}
  </div>
);

export const ModuleHeader = ({ className, id, children }: ModuleProps) => (
  <div className={cx('Module-header', className)} id={id}>
    {children}
  </div>
);

type ModuleContentProps = ModuleProps &
  Readonly<{
    snug?: boolean;
    seamless?: boolean;
  }>;

export const ModuleContent = ({ snug, seamless, className, id, children }: ModuleContentProps) => (
  <div
    id={id}
    className={cx(className, 'Module-content', {
      'Module-content--snug': snug,
      'Module-content--seamless': seamless,
    })}
  >
    {children}
  </div>
);
