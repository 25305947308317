import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { ignoreElements, tap } from 'rxjs/operators';
import * as accounts from '../accounts';
import * as auth from '../auth';
import { AppState } from '../reducer';
import * as slack from '../slack';
import { Notifications } from './components';

type Actions = accounts.Actions | auth.Actions;

export const processNotifications = (action$: ActionsObservable<Actions>) => {
  let message: string;
  return action$.pipe(
    tap(action => {
      switch (action.type) {
        case accounts.ActionTypes.UPDATE_TRIAL_END_FULFILLED:
          Notifications.show({
            intent: Intent.SUCCESS,
            icon: IconNames.SMALL_TICK,
            message: 'Updated trial period successfully',
          });
          break;
        case accounts.ActionTypes.UPDATE_ENTERPRISE_CAMPAIGN_DATES_FULFILLED:
          Notifications.show({
            intent: Intent.SUCCESS,
            icon: IconNames.SMALL_TICK,
            message: 'Updated Self-serve Enterprise trial period successfully',
          });
          break;
        case accounts.ActionTypes.UPDATE_GRACE_END_FULFILLED:
          Notifications.show({
            intent: Intent.SUCCESS,
            icon: IconNames.SMALL_TICK,
            message: 'Updated grace period successfully',
          });
          break;
        case accounts.ActionTypes.UPDATE_TRIAL_END_FAILED:
          Notifications.show({
            intent: Intent.DANGER,
            icon: IconNames.ERROR,
            message: 'Failed to update trial period',
          });
          break;
        case accounts.ActionTypes.UPDATE_GRACE_END_FAILED:
          Notifications.show({
            intent: Intent.DANGER,
            icon: IconNames.ERROR,
            message: 'Failed to update grace period',
          });
          break;
        case accounts.ActionTypes.UPDATE_ENTERPRISE_CAMPAIGN_DATES_FAILED:
          Notifications.show({
            intent: Intent.DANGER,
            icon: IconNames.ERROR,
            message: 'Failed to update self-serve Enterprise trial period',
          });
          break;
        case accounts.ActionTypes.UPDATE_LEGACY_TRIAL_END_FULFILLED:
          Notifications.show({
            intent: Intent.SUCCESS,
            icon: IconNames.SMALL_TICK,
            message: 'Updated trial period successfully',
          });
          break;
        case accounts.ActionTypes.UPDATE_LEGACY_GRACE_END_FULFILLED:
          Notifications.show({
            intent: Intent.SUCCESS,
            icon: IconNames.SMALL_TICK,
            message: 'Updated grace period successfully',
          });
          break;
        case accounts.ActionTypes.UPDATE_LEGACY_TRIAL_END_FAILED:
          Notifications.show({
            intent: Intent.DANGER,
            icon: IconNames.ERROR,
            message: 'Failed to update trial period',
          });
          break;
        case accounts.ActionTypes.SET_FEATURE_TRIAL_SUCCESS:
          Notifications.show({
            intent: Intent.SUCCESS,
            icon: IconNames.SMALL_TICK,
            message: 'Added account to feature trial',
          });
          break;
        case accounts.ActionTypes.UPDATE_LEGACY_GRACE_END_FAILED:
          Notifications.show({
            intent: Intent.DANGER,
            icon: IconNames.ERROR,
            message: 'Failed to update grace period',
          });
          break;
        case accounts.ActionTypes.SUBSCRIBE_FULFILLED:
          Notifications.show({
            intent: Intent.SUCCESS,
            icon: IconNames.SMALL_TICK,
            message: 'Updated subscription successfully',
          });
          break;
        case accounts.ActionTypes.SUBSCRIBE_FAILED:
          // yikes
          message = action.error && action.error.message && (action.error.message as { [key: string]: string }).message;
          Notifications.show({
            intent: Intent.DANGER,
            icon: IconNames.ERROR,
            message: `Failed to update subscription: ${message}`,
          });
          break;
        case accounts.ActionTypes.CANCEL_SUBSCRIPTION_FULFILLED:
          Notifications.show({
            intent: Intent.SUCCESS,
            icon: IconNames.SMALL_TICK,
            message: 'Canceled subscription successfully',
          });
          break;
        case accounts.ActionTypes.CANCEL_SUBSCRIPTION_FAILED:
          message = action.error && action.error.message && (action.error.message as { [key: string]: string }).message;
          Notifications.show({
            intent: Intent.DANGER,
            icon: IconNames.ERROR,
            message: `Failed to update subscription: ${message}`,
          });
          break;
        case accounts.ActionTypes.UPDATE_ACCOUNT_OWNER_FULFILLED:
          Notifications.show({
            intent: Intent.SUCCESS,
            icon: IconNames.SMALL_TICK,
            message: `Updated the account owner successfully`,
          });
          break;
        case accounts.ActionTypes.UPDATE_ACCOUNT_OWNER_FAILED:
          message = action.error && action.error.message && (action.error.message as { [key: string]: string }).message;
          Notifications.show({
            intent: Intent.DANGER,
            icon: IconNames.ERROR,
            message: `Failed to update account owner: ${message}`,
          });
        case accounts.ActionTypes.CLEAR_PENDING_SUBSCRIPTION_UPDATE_FULFILLED:
          Notifications.show({
            intent: Intent.SUCCESS,
            icon: IconNames.SMALL_TICK,
            message: `Cleared pendingUpdate successfully`,
          });
          break;
        case accounts.ActionTypes.CLEAR_PENDING_SUBSCRIPTION_UPDATE_FAILED:
          message = action.error && action.error.message && (action.error.message as { [key: string]: string }).message;
          Notifications.show({
            intent: Intent.DANGER,
            icon: IconNames.ERROR,
            message: `Failed to clear pendingUpdate: ${message}`,
          });
          break;
        case accounts.ActionTypes.UPDATE_STRIPE_SUBSCRIPTION_ID_FULFILLED:
          Notifications.show({
            intent: Intent.SUCCESS,
            icon: IconNames.SMALL_TICK,
            message: `Updated stripe subscription ID successfully`,
          });
          break;
        case accounts.ActionTypes.UPDATE_STRIPE_SUBSCRIPTION_ID_FAILED:
          message = action.error && action.error.message && (action.error.message as { [key: string]: string }).message;
          Notifications.show({
            intent: Intent.DANGER,
            icon: IconNames.ERROR,
            message: `Failed to update stripe subscription ID: ${message}`,
          });
          break;
        case accounts.ActionTypes.DISABLE_MFA_FAILED:
          message = action.error && action.error.message && (action.error.message as { [key: string]: string }).message;
          Notifications.show({
            intent: Intent.DANGER,
            icon: IconNames.ERROR,
            message: `Failed to disable MFA for account owner: ${message}`,
          });
          break;
        case accounts.ActionTypes.SET_SSO_DISABLED_FAILED:
          message = action.error && action.error.message && (action.error.message as string);
          Notifications.show({
            intent: Intent.DANGER,
            icon: IconNames.ERROR,
            message: `Failed to disable SSO for account: ${message}`,
          });
          break;
        default:
          return;
      }
    }),
    ignoreElements(),
  );
};

export const postToSlack = (action$: ActionsObservable<Actions>, state$: StateObservable<AppState>) => {
  return action$.pipe(
    tap(action => {
      const user = auth.getUser(state$.value);
      const webHookUrl = state$.value.settings.slackWebHookPlanChanges;

      if (!user) {
        return;
      }

      switch (action.type) {
        case accounts.ActionTypes.UPDATE_LEGACY_TRIAL_END_FULFILLED:
          slack.logMessage(
            slack.trialChangeSuccessMessage(user, action.account, action.subscription, action.updatedSubscription),
            webHookUrl,
          );
          break;
        case accounts.ActionTypes.UPDATE_LEGACY_GRACE_END_FULFILLED:
          slack.logMessage(
            slack.graceChangeSuccessMessage(user, action.account, action.subscription, action.updatedSubscription),
            webHookUrl,
          );
          break;
        case accounts.ActionTypes.SUBSCRIBE_FULFILLED:
          slack.logMessage(
            slack.planChangeSuccessMessage(user, action.account, action.subscription, action.updatedSubscription),
            webHookUrl,
          );
          break;
        case accounts.ActionTypes.CANCEL_SUBSCRIPTION_FULFILLED:
          slack.logMessage(slack.cancelSubscriptionSuccessMessage(user, action.account), webHookUrl);
          break;
        default:
          return;
      }
    }),
    ignoreElements(),
  );
};
