import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../reducer';
import { Loading, LoadingError, RequestError, ResourceNotFound } from '../../shared';
import { Actions } from '../actions';
import { AccountOwnerPanel } from '../components/AccountOwnerPanel';
import { Account, Member, OwnerPromotion } from '../model';
import { getMembers, getMembersRequestByAccountId } from '../selectors';

type StateProps = Readonly<{
  isFetching: boolean;
  error: RequestError;
  account: Account;
  members: Member[];
}>;

type DispatchProps = Readonly<{
  fetchMembers: (account: Account) => void;
}>;

type OwnProps = Readonly<{
  account: Account;
  isUpdatingAccountOwner: boolean;
  onChangeAccountOwner: (promotion: OwnerPromotion) => void;
}>;

type AccountOwnerPanelContainerProps = DispatchProps & StateProps & OwnProps;

export class AccountOwnerPanelContainer extends React.Component<AccountOwnerPanelContainerProps, any> {
  public componentWillMount() {
    const { fetchMembers, account } = this.props;
    fetchMembers(account);
  }

  public render() {
    const { isFetching, error, account, isUpdatingAccountOwner, onChangeAccountOwner, members } = this.props;

    if (isFetching) {
      return <Loading title="Loading account owner" />;
    }

    if (error) {
      switch (error.status) {
        case 404:
          return (
            <ResourceNotFound
              title="Account owner not found"
              description={<p>Account owner not found</p>}
              returnTo="/accounts"
              returnLabel="Go back to accounts"
            />
          );
        default:
          return <LoadingError title="Failed to account owner" />;
      }
    }

    return (
      <AccountOwnerPanel
        account={account}
        members={members}
        isUpdatingAccountOwner={isUpdatingAccountOwner}
        onChangeAccountOwner={onChangeAccountOwner}
      />
    );
  }
}

const mapStateToProps = (state: AppState, props: OwnProps) => ({
  members: getMembers(state, { accountId: props.account._id }),
  ...getMembersRequestByAccountId(state, { accountId: props.account._id }),
});

const mapDispatchToProps = {
  fetchMembers: Actions.fetchMembers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountOwnerPanelContainer);
