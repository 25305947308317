import { ActionsObservable, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, flatMap, ignoreElements, map, tap } from 'rxjs/operators';
import { Actions as ActionsAccount, ActionTypes as ActionTypesAccount } from '../accounts/actions';
import { RequestError } from '../shared';
import { Actions, ActionTypes } from './actions';
import * as api from './api';
import { client } from './service';

const TRACK_ACCOUNT_SEARCH_METRIC = 'Used account search';
const TRACK_ACCOUNT_SEARCH_TIMING = 'Account search fetch time';

type AccountSearchActions =
  | ReturnType<typeof ActionsAccount.fetchAccounts>
  | ReturnType<typeof ActionsAccount.receiveAccounts>
  | ReturnType<typeof ActionsAccount.searchAccounts>
  | ReturnType<typeof ActionsAccount.receiveSearchResults>;

export const trackAccountSearch = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<AccountSearchActions>(
      ActionTypesAccount.FETCH_ACCOUNTS_FULFILLED,
      ActionTypesAccount.RECEIVE_ACCOUNT_RESULTS,
    ),
    filter(action => !!action.q),
    tap(() => {
      if (!client) {
        return;
      }

      client.track(TRACK_ACCOUNT_SEARCH_METRIC);
    }),
    ignoreElements(),
  );
};

export const trackAccountSearchTiming = (action$: ActionsObservable<Actions>) => {
  let startTime = 0;

  return action$.pipe(
    ofType<AccountSearchActions>(
      ActionTypesAccount.FETCH_ACCOUNTS,
      ActionTypesAccount.SEARCH_ACCOUNTS,
      ActionTypesAccount.FETCH_ACCOUNTS_FULFILLED,
      ActionTypesAccount.RECEIVE_ACCOUNT_RESULTS,
    ),
    filter(action => !!action.q),
    tap(action => {
      // XXX: there's probably a better way to do this in rxjs-land, but I couldn't figure it out
      if (action.type === ActionTypesAccount.FETCH_ACCOUNTS || action.type === ActionTypesAccount.SEARCH_ACCOUNTS) {
        startTime = new Date().valueOf();
        return;
      }
      if (!client) {
        return;
      }
      const endTime = new Date().valueOf();

      client.track(TRACK_ACCOUNT_SEARCH_TIMING, undefined, endTime - startTime);
    }),
    ignoreElements(),
  );
};

export const settingsPrivateFetch = (action$: ActionsObservable<Actions>) =>
  action$.pipe(
    ofType<ReturnType<typeof Actions.updateSettingsPrivate>>(ActionTypes.UPDATE_SETTINGS_PRIVATE),
    flatMap(() =>
      api.getSettingsPrivate().pipe(
        map(response => Actions.updateSettingsPrivateFulfilled(response)),
        catchError((error: RequestError) => of(Actions.updateSettingsPrivateFailed(error))),
      ),
    ),
  );
