import { Switch } from '@blueprintjs/core';
import * as React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Scopes } from '../../auth';
import { AppState } from '../../reducer';
import { Settings } from '../../settings';
import { MainContent, MainPanel, RequestState, Time } from '../../shared';
import { actionCreators } from '../actions';
import { AvailablePlans } from '../components/AvailablePlans';
import { PlanReview } from '../components/PlanReview';
import { RequestStatus } from '../components/RequestStatus';
import { ExecutedPlanState, ProcessedPlansByAccountIDState, RejectedPlanState, SelectedPlanState } from '../reducer';
import { getProvisioningState } from '../selectors';

type StateProps = {
  userEmail: string | null;
  date: string;
  processedPlansByAccountID: ProcessedPlansByAccountIDState;
  selectedPlan: SelectedPlanState;
  executedPlan: ExecutedPlanState;
  rejectedPlan: RejectedPlanState;
  fetchAvailablePlansRequest: RequestState;
  fetchLaunchOrdersRequest: { [accountID: string]: RequestState };
  setLaunchOrderEndDateRequest: { [accountID: string]: RequestState };
  setExecutedPlanRequest: { [accountID: string]: RequestState };
  setRejectedPlanRequest: { [accountID: string]: RequestState };
};

type OwnProps = {
  settings: Settings;
  scopes: Scopes;
};

type DispatchProps = {
  fetchAvailablePlans: (clearSelectedPlan: boolean, absoluteMode: boolean) => void;
  getSelectedPlanID: (accountID: string) => void;
  setLaunchOrderEndDate: (accountID: string, orderID: number, endDate: Date, absoluteMode: boolean) => void;
  setExecutedPlan: (
    accountID: string,
    inputsHash: string,
    absoluteMode: boolean,
    skipEmailNotification: boolean,
    userEmail: string,
  ) => void;
  setRejectedPlan: (
    accountID: string,
    inputsHash: string,
    absoluteMode: boolean,
    userEmail: string,
    comment: string,
  ) => void;
};

type ProvisioningPageState = {
  absoluteMode: boolean;
};

class ProvisioningPage extends React.Component<StateProps & OwnProps & DispatchProps, ProvisioningPageState> {
  constructor(props: any) {
    super(props);

    this.state = { absoluteMode: true };
  }

  public handleProvisionStateChange() {
    const absoluteMode = this.state.absoluteMode ? true : false;
    this.setState({ absoluteMode: !absoluteMode });
    this.props.fetchAvailablePlans(true, !absoluteMode);
  }

  public componentDidMount() {
    this.props.fetchAvailablePlans(true, this.state.absoluteMode);
  }

  public render() {
    const {
      userEmail,
      date,
      processedPlansByAccountID,
      selectedPlan,
      fetchAvailablePlansRequest,
      fetchLaunchOrdersRequest,
      setLaunchOrderEndDateRequest,
      setExecutedPlanRequest,
      setRejectedPlanRequest,
      settings,
      scopes,
      fetchAvailablePlans,
      getSelectedPlanID,
      setLaunchOrderEndDate,
      setExecutedPlan,
      setRejectedPlan,
    } = this.props;

    if (fetchAvailablePlansRequest.isFetching || fetchAvailablePlansRequest.error) {
      return <RequestStatus title="Loading provisioning plans" requestState={fetchAvailablePlansRequest} showOnFetch />;
    }

    return (
      <MainPanel>
        <MainContent>
          <Helmet>
            <title>Account Provisioning</title>
          </Helmet>
          <h4>
            Today's Date: <Time datetime={date} format="L" utc />{' '}
            <Switch
              checked={this.state.absoluteMode}
              onChange={this.handleProvisionStateChange.bind(this)}
              label="Use Absolute Mode for provisioning"
              className="absoluteModeToggle"
            />
          </h4>
          <AvailablePlans
            date={date}
            processedPlansByAccountID={processedPlansByAccountID}
            settings={settings}
            fetchAvailablePlans={() => fetchAvailablePlans(true, this.state.absoluteMode)}
            getSelectedPlanID={getSelectedPlanID}
          />
          {selectedPlan.accountID &&
            processedPlansByAccountID[selectedPlan.accountID] && (
              <PlanReview
                {...selectedPlan}
                accountID={selectedPlan.accountID}
                userEmail={userEmail}
                processedPlansByAccountID={processedPlansByAccountID}
                settings={settings}
                scopes={scopes}
                absoluteMode={this.state.absoluteMode}
                refreshSelectedPlan={() => fetchAvailablePlans(false, this.state.absoluteMode)}
                setLaunchOrderEndDate={setLaunchOrderEndDate}
                setExecutedPlan={setExecutedPlan}
                setRejectedPlan={setRejectedPlan}
                fetchLaunchOrdersRequest={fetchLaunchOrdersRequest}
                setLaunchOrderEndDateRequest={setLaunchOrderEndDateRequest}
                setExecutedPlanRequest={setExecutedPlanRequest}
                setRejectedPlanRequest={setRejectedPlanRequest}
              />
            )}
        </MainContent>
      </MainPanel>
    );
  }
}

const mapStateToProps: (state: AppState) => StateProps = getProvisioningState;

const mapDispatchToProps = {
  fetchAvailablePlans: actionCreators.fetchAvailablePlans,
  getSelectedPlanID: actionCreators.getSelectedPlanID,
  setLaunchOrderEndDate: actionCreators.setLaunchOrderEndDate,
  setExecutedPlan: actionCreators.setExecutedPlan,
  setRejectedPlan: actionCreators.setRejectedPlan,
};

export const PlanReviewContainer = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ProvisioningPage);
