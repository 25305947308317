import { Button, Classes, Intent } from '@blueprintjs/core';
import * as moment from 'moment';
import * as React from 'react';
import { DatePickerButton } from '../../shared';

type SubscriptionDateManagerProps = Readonly<{
  value?: string;
  onChange: (date: Date) => void;
}>;

export const SubscriptionDateManager = ({ value, onChange }: SubscriptionDateManagerProps) => {
  const endDate = moment.utc(value);
  const maxDate = endDate
    .clone()
    .add(1, 'year')
    .toDate();

  return (
    <DatePickerButton value={endDate.toDate()} maxDate={maxDate} onDateChange={onChange}>
      <Button className={Classes.MINIMAL} intent={Intent.PRIMARY} icon="edit" />
    </DatePickerButton>
  );
};
