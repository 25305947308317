import * as React from 'react';
import { Account, SubscriptionState } from '../../model';

// https://github.com/palantir/blueprint/blob/develop/packages/docs-app/src/examples/select-examples/films.tsx#L145
export function highlightText(text: string, query: string) {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter(word => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join('|'), 'gi');
  const tokens: React.ReactNode[] = [];
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}

function escapeRegExpChars(text: string) {
  return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

const subscriptionSortOrder = (s: SubscriptionState): number => {
  switch (s) {
    case SubscriptionState.ACTIVE_SUBSCRIPTION:
    case SubscriptionState.ACTIVE_TRIAL_WITH_SUBSCRIPTION:
      return 2;
    case SubscriptionState.ACTIVE_TRIAL:
      return 1;
    default:
      return 0;
  }
};

export function sortActiveAccounts(a: Account, b: Account) {
  if (!a.subscription || !b.subscription) {
    return 0;
  }
  return subscriptionSortOrder(b.subscription.state) - subscriptionSortOrder(a.subscription.state);
}
