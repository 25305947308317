import { Button, Classes, Icon, Intent, Popover, Position, Tooltip } from '@blueprintjs/core';
import { Box, Flex } from 'grid-styled';
import * as numbro from 'numbro';
import * as React from 'react';
import { Scopes } from '../../auth';
import {
  BooleanTag,
  DataList,
  DataListItem,
  Module,
  ModuleContent,
  ModuleHeader,
  Table,
  TableCell,
  Time,
} from '../../shared';
import {
  computeLimitRatio,
  formatLimit,
  getEnvironmentLimit,
  getLegacyMAUPercentage,
  getLegacySeatLimit,
  getProjectLimit,
  getUsagePercentage,
  LegacySubscription,
  UNLIMITED,
} from '../model';
import { SubscriptionDateManager } from './SubscriptionDateManager';
import { SubscriptionUsageMeter } from './SubscriptionUsageMeter';

type LegacySubscriptionAccountDetailsProps = Readonly<{
  scopes: Scopes;
  legacySubscription: LegacySubscription;
  accountRequireMfa: boolean;
  onChangeLegacyTrialEnd: (date: Date) => void;
  onChangeLegacyGraceEnd: (date: Date) => void;
}>;

export const LegacySubscriptionAccountDetails = ({
  scopes,
  legacySubscription,
  accountRequireMfa,
  onChangeLegacyTrialEnd,
  onChangeLegacyGraceEnd,
}: LegacySubscriptionAccountDetailsProps) => {
  const { _limits: legacyLimits, _usage: legacyUsage, seatCount } = legacySubscription;
  return (
    <Module>
      <ModuleHeader>Subscription</ModuleHeader>
      <ModuleContent snug>
        {legacySubscription && (
          <DataList>
            <DataListItem>
              <Flex alignItems="center">
                <Box width={1 / 4}>Seat count</Box>
                <Box width={2 / 4}>
                  <SubscriptionUsageMeter
                    ratio={getUsagePercentage(legacyUsage.memberCount, seatCount ? seatCount : Infinity)}
                  />
                </Box>
                <Box ml="auto">{summarizeMemberCount(legacySubscription)}</Box>
              </Flex>
            </DataListItem>
            {
              <DataListItem>
                <Flex justify="space-between">
                  <Box>Enforce seat count</Box>
                  <Box>
                    <Tooltip content="When enabled, account is prevented from creating new users above their seat count">
                      <BooleanTag value={legacySubscription._limits.enforceSeatLimits} />
                    </Tooltip>
                  </Box>
                </Flex>
              </DataListItem>
            }
            <DataListItem>
              <Flex alignItems="center">
                <Box width={1 / 4}>Client-side MAUs</Box>
                <Box width={2 / 4}>
                  <SubscriptionUsageMeter ratio={getLegacyMAUPercentage(legacySubscription)} />
                </Box>
                <Box ml="auto">
                  <Flex align="center">
                    <Box>{summarizeMAUs(legacySubscription)}</Box>
                    {getLegacyMAUPercentage(legacySubscription) > 1 && (
                      <Tooltip content="You're over your MAU limit!">
                        <Icon icon="warning-sign" title={null} color="red" />
                      </Tooltip>
                    )}
                    <Popover content={segmentMAUs(legacySubscription)} position={Position.BOTTOM}>
                      <Button className={Classes.MINIMAL} intent={Intent.PRIMARY} icon="pie-chart" />
                    </Popover>
                  </Flex>
                </Box>
              </Flex>
            </DataListItem>
            <DataListItem>
              <Flex justify="center">
                <Box width={1 / 4}>Project count</Box>
                <Box width={2 / 4}>
                  <SubscriptionUsageMeter
                    ratio={computeLimitRatio(legacySubscription._usage.projectCount, getProjectLimit(legacyLimits))}
                  />
                </Box>
                <Box ml="auto">
                  {`${numbro(legacySubscription._usage.projectCount).format('0,0')} / ${formattedUsageLimit(
                    getProjectLimit(legacyLimits),
                  )}`}
                </Box>
              </Flex>
            </DataListItem>
            <DataListItem>
              <Flex justify="center">
                <Box width={1 / 4}>Environment count</Box>
                <Box width={2 / 4}>
                  <SubscriptionUsageMeter
                    ratio={computeLimitRatio(
                      legacySubscription._usage.environmentCount,
                      getEnvironmentLimit(legacyLimits),
                    )}
                  />
                </Box>
                <Box ml="auto">
                  {`${numbro(legacySubscription._usage.environmentCount).format('0,0')} / ${formattedUsageLimit(
                    getEnvironmentLimit(legacyLimits),
                  )}`}
                </Box>
              </Flex>
            </DataListItem>
            <DataListItem>
              <Flex justify="space-between">
                <Box>beta</Box>
                <Box>
                  <BooleanTag value={legacySubscription.isBeta} />
                </Box>
              </Flex>
            </DataListItem>
            <DataListItem>
              <Flex justify="space-between">
                <Box>MFA Required</Box>
                <Box>
                  <BooleanTag value={accountRequireMfa} />
                </Box>
              </Flex>
            </DataListItem>
            <DataListItem>
              <Flex justify="space-between">
                <Box>Trial</Box>
                <Box>
                  <BooleanTag value={legacySubscription.isTrial} />
                </Box>
              </Flex>
            </DataListItem>
            <DataListItem>
              <Flex justify="space-between">
                <Box>Plan Name</Box>
                <Box>{legacySubscription.name ? legacySubscription.name.toUpperCase() : 'N/A'}</Box>
              </Flex>
            </DataListItem>
            <DataListItem>
              <Flex justify="space-between">
                <Box>Active</Box>
                <Box>
                  <BooleanTag value={legacySubscription.isActive} />
                </Box>
              </Flex>
            </DataListItem>
            {!legacySubscription.isBeta &&
              !legacySubscription.isActive && (
                <DataListItem>
                  <Flex justify="space-between" align="center">
                    <Box>Trial period</Box>
                    <Box>
                      <Flex align="center">
                        <Time datetime={legacySubscription.trialStartDate} format="L" />
                        —
                        <Time datetime={legacySubscription.trialEndDate} format="L" />
                        {scopes.extendTrialGrace && (
                          <SubscriptionDateManager
                            value={legacySubscription.trialEndDate}
                            onChange={onChangeLegacyTrialEnd}
                          />
                        )}
                      </Flex>
                    </Box>
                  </Flex>
                </DataListItem>
              )}
            {!legacySubscription.isBeta && (
              <DataListItem>
                <Flex justify="space-between" align="center">
                  <Box>Grace period</Box>
                  <Box>
                    <Flex align="center">
                      {legacySubscription.graceStartDate ? (
                        <Time datetime={legacySubscription.graceStartDate} format="L" />
                      ) : (
                        'N/A'
                      )}
                      —
                      {legacySubscription.graceEndDate ? (
                        <Time datetime={legacySubscription.graceEndDate} format="L" />
                      ) : (
                        'N/A'
                      )}
                      {scopes.extendTrialGrace && (
                        <SubscriptionDateManager
                          value={legacySubscription.graceEndDate}
                          onChange={onChangeLegacyGraceEnd}
                        />
                      )}
                    </Flex>
                  </Box>
                </Flex>
              </DataListItem>
            )}
          </DataList>
        )}
      </ModuleContent>
    </Module>
  );
};

const formattedUsageLimit = (usageLimit: string | number) => {
  return typeof usageLimit === 'string' ? usageLimit : formatLimit(usageLimit);
};

const summarizeMemberCount = (sub: LegacySubscription) => {
  const usage = numbro(sub._usage.memberCount).format('0,0');
  const limit = getLegacySeatLimit(sub);

  return `${usage} / ${limit === UNLIMITED ? '∞' : numbro(limit).format('0,0')}`;
};

const summarizeMAUs = (sub: LegacySubscription) =>
  `${numbro(sub._usage.mauCount.client).format('0,0')} / ${
    sub._limits.mauLimit === -1 ? '∞' : numbro(sub._limits.mauLimit).format('0,0')
  } (${sub._limits.mauLimit === -1 ? 0 : Math.round(getLegacyMAUPercentage(sub) * 100)}%)`;

const segmentMAUs = (sub: LegacySubscription) => (
  <Table>
    <tbody>
      <tr>
        <TableCell>Anonymous</TableCell>
        <TableCell align="right">{numbro(sub._usage.mauCount.anonymous).format('0,0')}</TableCell>
      </tr>
      <tr>
        <TableCell>Non-anonymous</TableCell>
        <TableCell align="right">{numbro(sub._usage.mauCount.nonAnonymous).format('0,0')}</TableCell>
      </tr>
    </tbody>
  </Table>
);
