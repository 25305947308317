import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from '../../reducer';
import { createUsageShortcuts, Metadata, Series } from '../../shared/index';
import { Actions } from '../actions';

import { AccountUsage } from '../components/AccountUsage';
import { TimeRangeFilter } from '../components/TimeRangeFilter';
import {
  Account,
  ChartTypes,
  getDefaultDateRangeForUsage,
  UsageFilters,
  UsageIdentifier,
  UsageOrigin,
  UsageTypes,
} from '../model';
import { experimentsSelector } from '../selectors';

type OwnProps = Readonly<{
  account: Account;
  limit: number;
}>;

type StateProps = Readonly<{
  isFetching: boolean;
  metadata: Metadata;
  series: Series;
  chartType: ChartTypes;
  usageIdentifier: UsageIdentifier;
  tooltipText: string;
}>;

type DispatchProps = Readonly<{
  onFetchAccountUsage: (filters: UsageFilters) => void;
}>;

type State = {
  readonly timeRange: { to: number; from: number };
  readonly selectedTimeOption?: string;
};

type ExperimentUsageContainerProps = StateProps &
  Readonly<{
    onFetchAccountUsage: (filters: any) => void;
    account: Account;
  }>;

export class ExperimentUsageContainer extends React.Component<ExperimentUsageContainerProps> {
  public state: State = {
    timeRange: getDefaultDateRangeForUsage(),
    selectedTimeOption: 'Last 30 days',
  };
  public componentDidMount() {
    this.props.onFetchAccountUsage(this.state.timeRange);
  }

  public render() {
    const { metadata, chartType, tooltipText, series, onFetchAccountUsage, isFetching } = this.props;
    return (
      <AccountUsage
        renderFilters={() => this.renderFilters()}
        series={series}
        metadata={metadata}
        onFetchAccountUsage={onFetchAccountUsage}
        chartType={chartType}
        tooltipText={tooltipText}
        isFetching={isFetching}
      />
    );
  }

  private renderFilters = () => (
    <div className="">
      <TimeRangeFilter
        onSelect={(value: { to: number; from: number }, label: string) => this.handleTimeSelect(value, label)}
        selectedOption={this.state.selectedTimeOption}
        value="hours"
        timeRange={createUsageShortcuts()}
      />
    </div>
  );

  private handleTimeSelect = (value: { to: number; from: number }, label: string) => {
    this.setState({ selectedTimeOption: label });
    this.props.onFetchAccountUsage(value);
  };
}

const mapStateToProps: (state: AppState, ownProps: OwnProps) => StateProps = (state, ownProps) => ({
  ...experimentsSelector(state),
  chartType: ChartTypes.BAR,
  tooltipText: 'received event',
  account: ownProps.account,
  usageIdentifier: UsageIdentifier.EXPERIMENTS,
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => ({
  onFetchAccountUsage: (filters: UsageFilters) =>
    dispatch(
      Actions.fetchAccountUsage({
        account: ownProps.account,
        usageIdentifier: UsageIdentifier.EXPERIMENTS,
        usageType: UsageTypes.RECEIVED,
        metric: UsageOrigin.EVENTS,
        filters,
      }),
    ),
});

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ExperimentUsageContainer);
