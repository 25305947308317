import { Classes, Intent } from '@blueprintjs/core';
import * as cx from 'classnames';
import * as React from 'react';

type CalloutBaseProps = Readonly<{
  heading?: string;
  children?: any;
}>;

type CalloutProps = CalloutBaseProps &
  Readonly<{
    intent: Intent;
  }>;

export const Callout = ({ intent, heading, children }: CalloutProps) => (
  <div className={cx('pt-callout', Classes.intentClass(intent))}>
    {heading && <h5>{heading}</h5>}
    {children}
  </div>
);

export const DefaultCallout = (props: CalloutBaseProps) => <Callout {...props} intent={Intent.NONE} />;

export const ErrorCallout = (props: CalloutBaseProps) => <Callout {...props} intent={Intent.DANGER} />;
