import * as d3 from 'd3';
import * as React from 'react';
import { DataPoint, Series } from './types';

export type AreaProps = Readonly<{
  xScale: any;
  yScale: any;
  x: (d: DataPoint) => any;
  y: (d: DataPoint) => any;
  data: Series;
  height: any;
}>;

export class Area extends React.Component<AreaProps> {
  public render() {
    const { xScale, yScale, x, y, data, height } = this.props;

    const areaGenerator = d3
      .area<DataPoint>()
      .x(d => xScale(x(d)))
      .y0(height)
      .y1(d => yScale(y(d)));

    const area = areaGenerator(data as DataPoint[]);

    return <path className="Area" d={area || ''} />;
  }
}
