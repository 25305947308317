import * as React from 'react';

export type MarkerProps = Readonly<{
  x1?: number;
  y1?: number;
  x2?: number;
  y2?: number;
}>;

export const Marker = ({ x1, y1, x2, y2 }: MarkerProps) => <line className="Marker" x1={x1} y1={y1} x2={x2} y2={y2} />;
