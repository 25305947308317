import { Actions, ActionTypes } from './actions';
import { Settings } from './model';

export type SettingsState = Settings;

const initialState = {
  pricingCalculator: false,
  enableHighUsageChart: false,
  enableOverages: false,
  recentlyViewedAccounts: false,
  removeServerConnections: false,
  enableSchedulingTrialEntitlements: false,
  enableMemberSessions: false,
  enableDetailedMauCharts: false,
  dataExportPercentagePricing: false,
  enablePaginationOfMembers: false,
  enableHoneycombLinks: false,
  displayImmediatelyActionableProductsInAccountProvisionReview: false,
  enableExtendingEnterpriseTrialCampaign: false,
  enableSubscriptionStateFiltering: false,
  enableCancelImmediatelyFunctionality: false,
  slackWebHookPlanChanges: '',
  isFetching: false,
  error: null,
};

export const reducer = (state: SettingsState = initialState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.UPDATE_SETTINGS_PUBLIC:
      return {
        ...state,
        ...action.settings,
      };
    case ActionTypes.UPDATE_SETTINGS_PRIVATE:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case ActionTypes.UPDATE_SETTINGS_PRIVATE_FULFILLED:
      return {
        ...state,
        ...action.settings,
        isFetching: false,
        error: null,
      };
    case ActionTypes.UPDATE_SETTINGS_PRIVATE_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};
