import config from '../config';

export const catfoodUrl = () => {
  return config.launchdarkly.baseUrl || 'https://app.ld.catamorphic.com';
};

export const catfoodProject = () => {
  return config.launchdarkly.project || 'default';
};

export const linkToCatfood = (accountID: string) => {
  return `${catfoodUrl()}/${catfoodProject()}/${config.launchdarkly.environment}/contexts/account/${accountID}`;
};

export const linkToHowToGhostConfluencePage = () => {
  return 'https://launchdarkly.atlassian.net/wiki/spaces/ENG/pages/226657888/How+to+ghost';
};

export const linkToFullstory = (accountID: string) => {
  return (
    'https://app.fullstory.com/ui/27CV3/segments/everyone/people:search:((NOW%2FDAY-29DAY:NOW%2FDAY%2B1DAY):((user_accountId_str:==:%22' +
    accountID +
    '%22)):():():())/0'
  );
};

export const honeycombDataset = () => {
  return config.honeycomb.dataset;
};

export const linkToHoneycombEvents = (accountID: string) => {
  const dataset = honeycombDataset();
  return `https://ui.honeycomb.io/launchdarkly/datasets/${dataset}?query={"breakdowns":["environment"],"calculations":[{"op":"COUNT"},{"column":"all_event_len","op":"SUM"}],"filters":[{"column":"service_name","op":"=","value":"event-recorder"},{"column":"name","op":"=","value":"eventPool"},{"column":"account","op":"=","value":"${accountID}"}],"orders":[{"op":"COUNT","order":"descending"}],"limit":100,"time_range":7200}`;
};

export const linkToHoneycombSDKVersions = (accountID: string) => {
  const dataset = honeycombDataset();
  return `https://ui.honeycomb.io/launchdarkly/datasets/${dataset}?query={"breakdowns":["environment","user_agent"],"calculations":[{"op":"COUNT"}],"filters":[{"column":"account","op":"=","value":"${accountID}"}],"orders":[{"op":"COUNT","order":"descending"}],"limit":100,"time_range":7200}`;
};

export const linkToSalesforce = (accountID: string) => {
  return (
    'https://na35.salesforce.com/_ui/search/ui/UnifiedSearchResults?searchType=2&sen=00Q&sen=001&sen=068&sen=003&sen=01t&sen=00T&sen=00U&sen=005&sen=006&sen=a06&sen=701&sen=00O&str=' +
    accountID
  );
};

export const linkToStripe = (accountID: string) => {
  return `https://dashboard.stripe.com/search?query=is%3Acustomer%20${accountID}`;
};

export const linkToLogDNA = (accountID: string) => {
  return `https://app.logdna.com/700216bb4c/logs/view?q=${accountID}`;
};
