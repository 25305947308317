import { Alert, Button, FormGroup, Intent, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import { Settings } from '../../settings';
import { Time } from '../../shared/components/Time';
import { getFeatureTrialExpiration } from '../../shared/utils';
import { Account, ScheduledTrial, Subscription, TrialFeatures, TrialFeaturesName } from '../model';

const FeatureTrialTypeSelect = Select.ofType<TrialFeaturesName>();

export type FeatureTrialFormProps = {
  settings: Settings;
  subscription: Subscription;
  isSubmitting?: boolean;
  onSetFeatureTrial: (flagKey: string) => void;
  onSetFeatureExpirationDate: (flagKey: string) => void;
  scheduledTrials: { [id in TrialFeatures]: ScheduledTrial[] };
  account: Account;
};

type FeatureTrialFormState = Readonly<{
  isConfirmationOpen: boolean;
}>;

export class FeatureTrialForm extends React.Component<FeatureTrialFormProps, FeatureTrialFormState> {
  public state = {
    isConfirmationOpen: false,
  };

  public render() {
    const { scheduledTrials, account } = this.props;

    const experimentationFeatureTrials = scheduledTrials[TrialFeatures.EXPERIMENTATION_KEY];
    const experimentationFeatureTrialExpirationDate = getFeatureTrialExpiration(
      experimentationFeatureTrials,
      account._id,
    );

    const dataExportFeatureTrials = scheduledTrials[TrialFeatures.DATA_EXPORT_KEY];
    const dataExportTrialExpirationDate = getFeatureTrialExpiration(dataExportFeatureTrials, account._id);

    return (
      <>
        {experimentationFeatureTrialExpirationDate ? (
          <div>
            Customer is on an Experiment trial{' '}
            <p>
              Experiment trial expires{' '}
              <strong>
                <Time datetime={experimentationFeatureTrialExpirationDate} format="LL" />
              </strong>
            </p>
          </div>
        ) : (
          <span />
        )}

        {dataExportTrialExpirationDate ? (
          <div>
            Customer is on a Data export trial{' '}
            <p>
              Data export trial expires{' '}
              <strong>
                <Time datetime={dataExportTrialExpirationDate} format="LL" />
              </strong>
            </p>
          </div>
        ) : (
          <span />
        )}
        <Formik
          initialValues={{
            featureTrial: TrialFeaturesName.DATA_EXPORT,
          }}
          onSubmit={() => this.setState({ isConfirmationOpen: true })}
          render={(form: FormikProps<{ featureTrial: TrialFeaturesName }>) => (
            <form onSubmit={form.handleSubmit}>
              <FormGroup label="Feature trial" labelInfo="(expires in 30 days)">
                <FeatureTrialTypeSelect
                  items={[TrialFeaturesName.DATA_EXPORT, TrialFeaturesName.EXPERIMENTATION]}
                  onItemSelect={item => form.setFieldValue('featureTrial', item)}
                  itemRenderer={this.renderFeatureTrialItem}
                  filterable={false}
                >
                  <Button text={form.values.featureTrial} rightIcon="double-caret-vertical" />
                </FeatureTrialTypeSelect>
              </FormGroup>
              <FormGroup>
                <Button intent={Intent.PRIMARY} type="submit" disabled={this.props.isSubmitting}>
                  {this.props.isSubmitting ? 'Adding trial…' : 'Add trial'}
                </Button>
                <Alert
                  intent={Intent.DANGER}
                  isOpen={this.state.isConfirmationOpen}
                  cancelButtonText="Cancel"
                  confirmButtonText="Confirm"
                  onConfirm={() => this.handleSubmit(form.values)}
                  onCancel={() => this.setState({ isConfirmationOpen: false })}
                >
                  Are you sure you want to add this trial? It will expire in 30 days.
                </Alert>
              </FormGroup>
            </form>
          )}
        />
      </>
    );
  }

  public handleSubmit = (values: { featureTrial: TrialFeaturesName }) => {
    this.props.onSetFeatureTrial(
      values.featureTrial === TrialFeaturesName.DATA_EXPORT
        ? TrialFeatures.DATA_EXPORT_KEY
        : TrialFeatures.EXPERIMENTATION_KEY,
    );

    this.props.onSetFeatureExpirationDate(
      values.featureTrial === TrialFeaturesName.DATA_EXPORT
        ? TrialFeatures.DATA_EXPORT_KEY
        : TrialFeatures.EXPERIMENTATION_KEY,
    );

    this.setState({
      isConfirmationOpen: false,
    });
  };

  private renderFeatureTrialItem: ItemRenderer<TrialFeaturesName> = (i, props) => (
    <MenuItem active={props.modifiers.active} onClick={props.handleClick} key={i} text={i} />
  );
}
