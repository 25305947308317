import { FocusStyleManager } from '@blueprintjs/core';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { WithAuth } from './auth';
import configureStore from './configureStore';
import './global.scss';
import Root from './Root';
import { initializeSettings } from './settings/service';

const store = configureStore();

const c = initializeSettings(store);

FocusStyleManager.onlyShowFocusOnTabs();

c.waitForInitialization().then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <WithAuth>
        {({ user, isUserReady, onSignIn, onSignOut }) => (
          <Root user={user} isUserReady={isUserReady} onSignIn={onSignIn} onSignOut={onSignOut} />
        )}
      </WithAuth>
    </Provider>,
    document.getElementById('root'),
  );
});
