import * as cx from 'classnames';
import * as React from 'react';
import { ReactElement } from 'react';
import './styles.scss';

type SubheadProps = Readonly<{
  secondary?: boolean;
  children?: any;
  actions?: Array<ReactElement<{}>>;
}>;

export const Subhead = ({ actions, children, secondary }: SubheadProps) => (
  <div className={cx('Subhead', { 'Subhead-secondary': secondary })}>
    <div className="Subhead-heading">{children}</div>
    {actions && actions.length && <div className="Subhead-actions">{actions}</div>}
  </div>
);
