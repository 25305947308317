import {
  AnchorButton,
  Button,
  Callout,
  Checkbox,
  Classes,
  Colors,
  Dialog,
  HTMLTable,
  Icon,
  TextArea,
  Tooltip,
} from '@blueprintjs/core';
import { endOfYesterday, isAfter } from 'date-fns';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { Scopes } from '../../auth';
import { Settings } from '../../settings';
import { CopyToClipboard, RequestState } from '../../shared';
import { Instance, LimitOverrideFlag, LineItem, Platform } from '../model';
import { ProcessedPlansByAccountIDState } from '../reducer';
import { vitalBaseUrl } from '../util';
import '../util.ts';
import './PlanReview.scss';
import { PlanReviewConfig } from './PlanReviewConfig';
import { PlanReviewLineItemSection } from './PlanReviewLineItemSection';
import { PlanReviewOverrideCell } from './PlanReviewOverrideCell';
import { RequestStatus } from './RequestStatus';

type PlanReviewProps = {
  accountID: string;
  launchLineItem: LineItem | null;
  userEmail: string | null;
  absoluteMode: boolean;
  processedPlansByAccountID: ProcessedPlansByAccountIDState;
  settings: Settings;
  scopes: Scopes;
  refreshSelectedPlan: () => void;
  setLaunchOrderEndDate: (accountID: string, orderID: number, endDate: Date, absoluteMode: boolean) => void;
  setExecutedPlan: (
    accountID: string,
    inputsHash: string,
    absoluteMode: boolean,
    skipEmailNotification: boolean,
    userEmail: string | null,
  ) => void;
  setRejectedPlan: (
    accountID: string,
    inputsHash: string,
    absoluteMode: boolean,
    userEmail: string | null,
    comment: string | null,
  ) => void;
  fetchLaunchOrdersRequest: { [accountID: string]: RequestState };
  setLaunchOrderEndDateRequest: { [accountID: string]: RequestState };
  setExecutedPlanRequest: { [accountID: string]: RequestState };
  setRejectedPlanRequest: { [accountID: string]: RequestState };
};

type State = {
  isOpen: boolean;
  skipEmailNotification: boolean;
  rejectionComment: string;
  requestTitle: string | null;
  requestState: RequestState | null;
  showLoadingIndicatorOnFetch: boolean;
};

type TableHeaderProps = {
  shouldDisplayTrialDates: boolean;
  showStartingDate: boolean;
};

function TableHeader(props: TableHeaderProps) {
  return (
    <thead>
      <tr>
        <th />
        <th>Order ID</th>
        <th>Order Source</th>
        <th>Opportunity ID</th>
        <th>
          {props.showStartingDate ? 'Starting Date' : ''}{' '}
          {props.shouldDisplayTrialDates && (
            <Tooltip content="The start date for the single action product represents the start date of the trial extension. This start date will not show up in new contracted limits, which will also represent the initial start date of the trial.">
              <Icon icon="info-sign" title={null} color={Colors.GRAY1} />
            </Tooltip>
          )}
        </th>
        <th>Ending Date</th>
        <th>Platform</th>
        <th>Seats</th>
        <th>Hosts</th>
        <th>cMau</th>
        <th>Experiment Events</th>
        <th>Experimentation Keys</th>
        <th>Data Export</th>
        <th>SSO</th>
      </tr>
    </thead>
  );
}

export class PlanReview extends React.Component<PlanReviewProps> {
  public state: State = {
    isOpen: false,
    skipEmailNotification: false,
    rejectionComment: '',
    requestTitle: null,
    requestState: null,
    showLoadingIndicatorOnFetch: false,
  };

  private shouldLineItemSectionBeStriped: boolean;

  public componentDidUpdate(prevProps: Readonly<PlanReviewProps>) {
    const {
      accountID,
      fetchLaunchOrdersRequest,
      setLaunchOrderEndDateRequest,
      setExecutedPlanRequest,
      setRejectedPlanRequest,
    } = this.props;

    const requestError: boolean = this.state.requestState !== null && this.state.requestState.error !== null;

    if (!requestError && fetchLaunchOrdersRequest[accountID] !== prevProps.fetchLaunchOrdersRequest[accountID]) {
      return this.setState({
        requestTitle: 'Loading launch orders',
        requestState: fetchLaunchOrdersRequest[accountID],
        showLoadingIndicatorOnFetch: true,
      });
    }

    if (
      !requestError &&
      setLaunchOrderEndDateRequest[accountID] !== prevProps.setLaunchOrderEndDateRequest[accountID]
    ) {
      return this.setState({
        requestTitle: 'Updating launch order end date',
        requestState: setLaunchOrderEndDateRequest[accountID],
        showLoadingIndicatorOnFetch: false,
      });
    }

    if (!requestError && setExecutedPlanRequest[accountID] !== prevProps.setExecutedPlanRequest[accountID]) {
      return this.setState({
        requestTitle: 'Executing plan',
        requestState: setExecutedPlanRequest[accountID],
        showLoadingIndicatorOnFetch: false,
      });
    }

    if (!requestError && setRejectedPlanRequest[accountID] !== prevProps.setRejectedPlanRequest[accountID]) {
      return this.setState({
        requestTitle: 'Rejecting plan',
        requestState: setRejectedPlanRequest[accountID],
        showLoadingIndicatorOnFetch: false,
      });
    }
  }

  public render() {
    const {
      accountID,
      launchLineItem,
      refreshSelectedPlan,
      setLaunchOrderEndDate,
      settings,
      scopes,
      absoluteMode,
    } = this.props;
    const { skipEmailNotification, requestTitle, requestState, showLoadingIndicatorOnFetch } = this.state;

    const plan = this.props.processedPlansByAccountID[accountID];

    // Only display trial start & end dates in PlanReviewConfig if IASAPs are being provisioned
    const shouldDisplayTrialDates =
      plan.immediatelyActionableSingleActionProducts.length > 0 &&
      [Platform.EnterpriseTrial, Platform.StandardTrial2021].includes(plan.plannedConfig.platform);

    this.shouldLineItemSectionBeStriped = false;

    if (requestTitle && requestState && (requestState.isFetching || requestState.error)) {
      return (
        <RequestStatus title={requestTitle} requestState={requestState} showOnFetch={showLoadingIndicatorOnFetch} />
      );
    }

    return (
      <>
        <Callout title={plan.organization} className="planReview">
          <div>
            <>{plan.ldInstance} | </>
            {this.vitalLink(accountID, plan.ldInstance)}
            <Tooltip content="Copy LD AccountID">
              <CopyToClipboard content={accountID}>
                <Icon icon="clipboard" />
              </CopyToClipboard>
            </Tooltip>
          </div>
          {/* TODO(jeremy): Enable once SFDC account ID is included in /available-plans response */}
          {/*<div>*/}
          {/*  SFDC Account ID: <a href="#"></a>*/}
          {/*</div>*/}
          <AnchorButton minimal icon="refresh" className="refresh" onClick={refreshSelectedPlan} />
        </Callout>
        <HTMLTable className="planReview">
          <TableHeader showStartingDate={true} shouldDisplayTrialDates={shouldDisplayTrialDates} />
          <tbody>
            <PlanReviewConfig
              title="Current Limits"
              config={plan.previousAccountConfig}
              className="currentLimits"
              displayTrialDates={shouldDisplayTrialDates}
            />
            {!absoluteMode &&
              plan.endingLineItems &&
              plan.endingLineItems.length > 0 && (
                <PlanReviewLineItemSection
                  title="Ending"
                  isSFDCLineItem={true}
                  lineItems={plan.endingLineItems}
                  className="ending"
                  invertQtySign
                  striped={this.isLineItemSectionStriped(plan.endingLineItems)}
                  setLaunchOrderEndDate={setLaunchOrderEndDate.bind(this, accountID)}
                  scopes={scopes}
                />
              )}
            {settings.displayImmediatelyActionableProductsInAccountProvisionReview && (
              <PlanReviewLineItemSection
                title="Immediately Actionable Products"
                isSFDCLineItem={true}
                lineItems={plan.immediatelyActionableSingleActionProducts}
                className="starting"
                striped={this.isLineItemSectionStriped(plan.immediatelyActionableSingleActionProducts)}
                setLaunchOrderEndDate={setLaunchOrderEndDate.bind(this, accountID)}
                scopes={scopes}
              />
            )}
            <PlanReviewLineItemSection
              title="Participating"
              isSFDCLineItem={true}
              lineItems={plan.participatingLineItems}
              className="wash"
              striped={this.isLineItemSectionStriped(plan.participatingLineItems)}
              setLaunchOrderEndDate={setLaunchOrderEndDate.bind(this, accountID)}
              scopes={scopes}
            />
            <PlanReviewLineItemSection
              title="Starting"
              isSFDCLineItem={true}
              lineItems={plan.startingLineItems}
              className="starting"
              striped={this.isLineItemSectionStriped(plan.startingLineItems)}
              setLaunchOrderEndDate={setLaunchOrderEndDate.bind(this, accountID)}
              scopes={scopes}
            />
            {!absoluteMode &&
              plan.washLineItems &&
              plan.washLineItems.length > 0 && (
                <PlanReviewLineItemSection
                  title="Wash"
                  isSFDCLineItem={true}
                  lineItems={plan.washLineItems}
                  className="wash"
                  striped={this.isLineItemSectionStriped(plan.washLineItems)}
                  setLaunchOrderEndDate={setLaunchOrderEndDate.bind(this, accountID)}
                  scopes={scopes}
                />
              )}
            <PlanReviewConfig
              title="New Contracted Limits"
              config={plan.plannedConfig}
              className="newLimits"
              displayTrialDates={shouldDisplayTrialDates}
            />
            {Object.keys(plan.flagOverrides).length > 0 && (
              <tr className="flagOverrides">
                <td>Catfood {Object.keys(plan.flagOverrides).length > 1 ? 'Overrides are' : 'Override is'} Active</td>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td>
                  <PlanReviewOverrideCell value={plan.flagOverrides.seats} flag={LimitOverrideFlag.Seats} />
                </td>
                <td>
                  <PlanReviewOverrideCell value={plan.flagOverrides.hosts} flag={LimitOverrideFlag.Hosts} />
                </td>
                <td>
                  <PlanReviewOverrideCell value={plan.flagOverrides.c_mau} flag={LimitOverrideFlag.Cmau} />
                </td>
                <td>
                  <PlanReviewOverrideCell
                    value={plan.flagOverrides.experiment_events}
                    flag={LimitOverrideFlag.ExperimentEvents}
                  />
                </td>
                <td>
                  <PlanReviewOverrideCell
                    value={plan.flagOverrides.experiment_keys}
                    flag={LimitOverrideFlag.ExperimentationKeys}
                  />
                </td>
                <td>
                  <PlanReviewOverrideCell
                    value={plan.flagOverrides.data_export_events}
                    flag={LimitOverrideFlag.DataExportEvents}
                  />
                </td>
                <td>
                  <PlanReviewOverrideCell value={plan.flagOverrides.sso} flag={LimitOverrideFlag.SSO} />
                </td>
              </tr>
            )}
            {scopes.accountProvisioning && (
              <tr>
                <td colSpan={10}>
                  {scopes.optOutProvisioningEmail && (
                    <Checkbox
                      className="options"
                      onChange={this.handleSkipEmailNotificationChange}
                      checked={skipEmailNotification}
                      labelElement={<strong>Do not send provisioning email</strong>}
                    />
                  )}
                  <div className="buttonContainer">
                    <Tooltip
                      content="Approving contracted limits has no effect on Catfood overrides."
                      disabled={Object.keys(plan.flagOverrides).length === 0}
                    >
                      <AnchorButton
                        large
                        icon="thumbs-up"
                        intent="success"
                        text="Approve New Limits"
                        onClick={this.handleExecuteClick(
                          accountID,
                          plan.inputsHash,
                          absoluteMode,
                          this.state.skipEmailNotification,
                        )}
                      />
                    </Tooltip>
                    <AnchorButton
                      large
                      icon="thumbs-down"
                      intent="warning"
                      text="Reject &amp; Escalate"
                      onClick={this.handleDialogOpen}
                    />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </HTMLTable>
        {launchLineItem &&
          launchLineItem.endDate &&
          isAfter(launchLineItem.endDate, endOfYesterday()) && (
            <HTMLTable className="planReview">
              <TableHeader showStartingDate={false} shouldDisplayTrialDates={false} />
              <tbody>
                <PlanReviewLineItemSection
                  title="Launch Order Exists"
                  isSFDCLineItem={true}
                  lineItems={[launchLineItem]}
                  className="launch"
                  striped={false}
                  setLaunchOrderEndDate={setLaunchOrderEndDate.bind(this, accountID)}
                  scopes={scopes}
                />
              </tbody>
            </HTMLTable>
          )}
        {absoluteMode &&
          plan.washLineItems &&
          plan.washLineItems.length > 0 && (
            <HTMLTable className="planReview">
              <TableHeader showStartingDate={true} shouldDisplayTrialDates={false} />
              <tbody>
                <PlanReviewLineItemSection
                  title="Wash"
                  isSFDCLineItem={true}
                  lineItems={plan.washLineItems}
                  className="wash"
                  striped={this.isLineItemSectionStriped(plan.washLineItems)}
                  setLaunchOrderEndDate={setLaunchOrderEndDate.bind(this, accountID)}
                  scopes={scopes}
                />
              </tbody>
            </HTMLTable>
          )}
        {absoluteMode &&
          plan.endingLineItems &&
          plan.endingLineItems.length > 0 && (
            <HTMLTable className="planReview">
              <TableHeader showStartingDate={true} shouldDisplayTrialDates={false} />
              <tbody>
                <PlanReviewLineItemSection
                  title="Recently Ended Products"
                  isSFDCLineItem={true}
                  lineItems={plan.endingLineItems}
                  className="wash"
                  striped={this.isLineItemSectionStriped(plan.endingLineItems)}
                  setLaunchOrderEndDate={setLaunchOrderEndDate.bind(this, accountID)}
                  scopes={scopes}
                />
              </tbody>
            </HTMLTable>
          )}
        <Dialog
          autoFocus
          icon="thumbs-down"
          isOpen={this.state.isOpen}
          onClose={this.handleDialogClose}
          title="Reject & Escalate Plan"
        >
          <div className={Classes.DIALOG_BODY}>
            <TextArea
              className="rejectPlan"
              fill
              growVertically
              placeholder="Describe the problem/reason for the rejection..."
              onChange={this.handleCommentChange}
            />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button text="Close" onClick={this.handleDialogClose} />
              <Button
                text="Reject"
                intent="primary"
                onClick={this.handleRejectClick(accountID, plan.inputsHash, absoluteMode)}
              />
            </div>
          </div>
        </Dialog>
      </>
    );
  }

  private vitalLink(accountID: string, ldInstance: Instance) {
    const baseUrl = vitalBaseUrl(ldInstance);
    return (
      <a href={`${baseUrl}/#/accounts/${accountID}`} target="_blank">
        {accountID}
      </a>
    );
  }

  private isLineItemSectionStriped(lineItems: LineItem[]): boolean {
    if (lineItems.length) {
      this.shouldLineItemSectionBeStriped = !this.shouldLineItemSectionBeStriped;
    }
    return this.shouldLineItemSectionBeStriped;
  }

  private handleDialogOpen = () => {
    this.setState({ isOpen: true });
  };

  private handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  private handleCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ rejectionComment: event.target.value });
  };

  private handleExecuteClick(
    accountID: string,
    inputsHash: string,
    absoluteMode: boolean,
    skipEmailNotification: boolean,
  ) {
    return () => {
      const { userEmail } = this.props;
      this.props.setExecutedPlan(accountID, inputsHash, absoluteMode, skipEmailNotification, userEmail);
    };
  }

  private handleSkipEmailNotificationChange = () => {
    this.setState({ skipEmailNotification: !this.state.skipEmailNotification });
  };

  private handleRejectClick(accountID: string, inputsHash: string, absoluteMode: boolean) {
    return () => {
      const { userEmail } = this.props;
      this.props.setRejectedPlan(accountID, inputsHash, absoluteMode, userEmail, this.state.rejectionComment);
      this.setState({ isOpen: false, rejectionComment: '' });
    };
  }
}
