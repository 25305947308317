import { combineReducers } from 'redux';
import { AccountState, reducer as accountReducer } from './accounts';
import { AuthState, reducer as authReducer } from './auth';
import { ProvisioningState, reducer as provisioningReducer } from './provisioning/reducer';
import { reducer as settingsReducer, SettingsState } from './settings';

export type AppState = Readonly<{
  auth: AuthState;
  settings: SettingsState;
  accounts: AccountState;
  provisioning: ProvisioningState;
}>;

export default combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  accounts: accountReducer,
  provisioning: provisioningReducer,
});
