import { Alert, Button, Colors, Icon, Intent, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Box, Flex } from 'grid-styled';
import * as React from 'react';
import { Scopes } from '../../auth';
import { BooleanTag, DefaultCallout, Module, ModuleContent, ModuleHeader, Table } from '../../shared';
import { Account } from '../model';

type AccountSecurityPanelProps = {
  account: Account;
  scopes: Scopes;
  setSsoDisabled: () => void;
};

type AccountSecurityPanelState = {
  alertIsOpen: boolean;
};

export class AccountSecurityPanel extends React.Component<AccountSecurityPanelProps, AccountSecurityPanelState> {
  public state: AccountSecurityPanelState = {
    alertIsOpen: false,
  };

  public render() {
    const { account, scopes } = this.props;
    const { alertIsOpen } = this.state;

    const samlConfig = account.samlConfig;
    const samlEnabled = samlConfig ? samlConfig.enabled : false;

    return (
      <Flex>
        <Box>
          <Module>
            <ModuleHeader>
              <span>
                Security Settings{' '}
                <Tooltip content="If enabled, but not required, SSO is in test-drive mode. Team members can log in from LaunchDarkly OR their identity provider.">
                  <Icon icon={IconNames.INFO_SIGN} iconSize={15} color={Colors.GRAY1} />
                </Tooltip>
              </span>
            </ModuleHeader>
            <ModuleContent snug>
              <Table className="ssoSettings-Table">
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>MFA Required?</td>
                    <td>{<BooleanTag value={account.requireMfa} />}</td>
                  </tr>
                  <tr>
                    <td>SAML SSO Enabled?</td>
                    <td>
                      {<BooleanTag value={samlEnabled} />}
                      {scopes.disableSso &&
                        samlEnabled && (
                          <>
                            <Button
                              className="ssoDisable-Button"
                              icon="disable"
                              intent={Intent.DANGER}
                              small
                              text="Disable SSO"
                              type="button"
                              onClick={this.handleDisableSsoClick}
                            />
                            <Alert
                              canEscapeKeyCancel
                              cancelButtonText="Cancel"
                              confirmButtonText="Disable"
                              intent={Intent.DANGER}
                              isOpen={alertIsOpen}
                              onCancel={this.handleDisableSsoCancel}
                              onConfirm={this.handleDisableSsoConfirm}
                            >
                              <h3>Disable single sign-on?</h3>
                              <p>All team members will be required to sign in via their email and password.</p>
                            </Alert>
                          </>
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td>SAML SSO Required?</td>
                    <td>{<BooleanTag value={samlConfig ? samlConfig.requireSso : false} />}</td>
                  </tr>
                  <tr>
                    <td>SAML SSO Sign on URL</td>
                    <td>
                      {samlConfig && samlConfig.ssoUrl ? (
                        samlConfig.ssoUrl
                      ) : (
                        <DefaultCallout>There is no SAML Configuration for this account.</DefaultCallout>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </ModuleContent>
          </Module>
        </Box>
      </Flex>
    );
  }

  private handleDisableSsoClick = () => this.setState({ alertIsOpen: true });
  private handleDisableSsoCancel = () => this.setState({ alertIsOpen: false });
  private handleDisableSsoConfirm = () => {
    this.setState({ alertIsOpen: false });
    this.props.setSsoDisabled();
  };
}
