import * as React from 'react';
import './styles.scss';

type PageProps = Readonly<{
  children?: any;
}>;

export const Page = ({ children }: PageProps) => <div className="Page">{children}</div>;

export const MainNav = ({ children }: PageProps) => <div className="MainNav">{children}</div>;

export const MainPanel = ({ children }: PageProps) => <div className="MainPanel">{children}</div>;

export const MainContent = ({ children }: PageProps) => <div className="MainContent">{children}</div>;
