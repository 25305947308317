import * as React from 'react';
import * as ReactDOM from 'react-dom';

type BoundsChildProps = Readonly<{
  rect?: ClientRect | DOMRect;
  parentRect?: ClientRect | DOMRect;
}>;

export type BoundsProps = Readonly<{
  render: (props: BoundsChildProps) => React.ReactNode;
}>;

type BoundsState = Readonly<{
  rect?: ClientRect | DOMRect;
  parentRect?: ClientRect | DOMRect;
}>;

export class Bounds extends React.Component<BoundsProps, BoundsState> {
  public state = {
    rect: undefined,
    parentRect: undefined,
  };
  private node: Element | null;

  public componentDidMount() {
    const node = ReactDOM.findDOMNode(this);

    if (!(node instanceof Text)) {
      this.node = node;
    }

    this.setState(() => this.getBoundingRects());
  }

  public render() {
    const { rect, parentRect } = this.state;

    return this.props.render({
      rect,
      parentRect,
    });
  }

  private getBoundingRects = () => {
    if (!this.node) {
      return this.state;
    }

    const node = this.node;

    const parentNode = node.parentNode;

    const emptyRect = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: 0,
      height: 0,
    };

    const rect = node.getBoundingClientRect ? node.getBoundingClientRect() : emptyRect;

    const parentRect =
      parentNode && parentNode instanceof Element && parentNode.getBoundingClientRect
        ? parentNode.getBoundingClientRect()
        : emptyRect;

    return { rect, parentRect };
  };
}
