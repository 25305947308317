import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

type Size = Readonly<{
  width: number;
  height: number;
}>;

type ResponsiveState = Size;

export type ResponsiveProps = Readonly<{
  render: (size: Size) => React.ReactNode;
  minWidth: number;
  minHeight: number;
}>;

export class Responsive extends React.Component<ResponsiveProps, ResponsiveState> {
  public state = {
    width: this.props.minWidth,
    height: this.props.minHeight,
  };
  private element: HTMLDivElement | null;

  private observer: ResizeObserver;

  public componentDidMount() {
    this.attachResizeObserver();
  }

  public componentWillUnmount() {
    this.detachResizeObserver();
  }

  public render() {
    return <div ref={this.setRef}>{this.props.render(this.state)}</div>;
  }

  private setRef = (node: HTMLDivElement | null) => {
    if (this.observer) {
      if (node) {
        this.observer.observe(node);
      } else {
        if (this.element) {
          this.observer.unobserve(this.element);
        }
      }
    }

    this.element = node;
  };

  private attachResizeObserver() {
    if (!this.element) {
      return;
    }

    this.observer = new ResizeObserver(([rect]) => {
      this.setState(() => ({ width: rect.contentRect.width, height: rect.contentRect.height }));
    });

    this.observer.observe(this.element);
  }

  private detachResizeObserver() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
