import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from '../../reducer';
import { Actions } from '../actions';

import { Metadata, Series } from '../../shared';
import { HighAccountUsage } from '../components/HighAccountUsage';
import { ChartTypes, SDKType } from '../model';
import { getHighAccountUsage, getHighAccountUsageFetchRequest } from '../selectors';

type StateProps = Readonly<{
  isFetching: boolean;
  metadata: Metadata;
  series: Series;
}>;

type DispatchProps = Readonly<{
  onFetchHighAccountUsage: (value: SDKType, filters: { to: string; from: string }) => void;
}>;

const mapStateToProps: (state: AppState) => StateProps = state => {
  return {
    ...getHighAccountUsage(state),
    isFetching: getHighAccountUsageFetchRequest(state).isFetching,
    chartType: ChartTypes.LINE,
    tooltipText: 'connection',
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFetchHighAccountUsage: (usageType: SDKType, dates?: { to: string; from: string }) =>
    dispatch(Actions.fetchHighAccountUsage(usageType, dates)),
});

export const HighAccountUsageContainer = connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(HighAccountUsage);
