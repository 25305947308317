import * as React from 'react';

export type ChartProps = Readonly<{
  width: number;
  height: number;
  children: React.ReactNode;
}>;

export class Chart extends React.PureComponent<ChartProps> {
  public render() {
    const { width, height, children } = this.props;

    return (
      <svg className="Chart" width={width} height={height}>
        {children}
      </svg>
    );
  }
}
