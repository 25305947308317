import * as React from 'react';

import { Button } from '@blueprintjs/core';

type CSVExportProps = Readonly<{
  fileName: string;
  buttonText?: string;
  headerRows: string[];
  tableRows: string[][];
}>;

export const CSVExport = ({ buttonText = 'Export as CSV', fileName, headerRows, tableRows }: CSVExportProps) => {
  const handleExportAsCsv = () => {
    const rows = [headerRows, ...tableRows].join('\n');
    const csvContent = `data:text/csv;charset=utf-8,${encodeURI(rows)}`;
    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  return (
    <Button className="AccountDetails-exportCsv" onClick={handleExportAsCsv}>
      {buttonText}
    </Button>
  );
};
