import { ActionsUnion, makeActionCreator, RequestError } from '../shared';
import {
  GetAvailablePlansResponse,
  GetOrdersResponse,
  PostExecutedPlanResponse,
  PostLaunchOrderEndDateResponse,
  PostRejectedPlanResponse,
} from './api';
import { OrderSource } from './model';

export enum ActionTypes {
  FETCH_AVAILABLE_PLANS = 'provisioning/FETCH_AVAILABLE_PLANS',
  FETCH_AVAILABLE_PLANS_FULFILLED = 'provisioning/FETCH_AVAILABLE_PLANS_FULFILLED',
  FETCH_AVAILABLE_PLANS_FAILED = 'provisioning/FETCH_AVAILABLE_PLANS_FAILED',
  GET_SELECTED_PLAN_ID = 'provisioning/GET_SELECTED_PLAN_ID',
  FETCH_LAUNCH_ORDERS = 'provisioning/FETCH_LAUNCH_ORDERS',
  FETCH_LAUNCH_ORDERS_FULFILLED = 'provisioning/FETCH_LAUNCH_ORDERS_FULFILLED',
  FETCH_LAUNCH_ORDERS_FAILED = 'provisioning/FETCH_LAUNCH_ORDERS_FAILED',
  SET_LAUNCH_ORDER_END_DATE = 'provisioning/SET_LAUNCH_ORDER_END_DATE',
  SET_LAUNCH_ORDER_END_DATE_FULFILLED = 'provisioning/SET_LAUNCH_ORDER_END_DATE_FULFILLED',
  SET_LAUNCH_ORDER_END_DATE_FAILED = 'provisioning/SET_LAUNCH_ORDER_END_DATE_FAILED',
  SET_EXECUTED_PLAN = 'provisioning/SET_EXECUTED_PLAN',
  SET_EXECUTED_PLAN_FULFILLED = 'provisioning/SET_EXECUTED_PLAN_FULFILLED',
  SET_EXECUTED_PLAN_FAILED = 'provisioning/SET_EXECUTED_PLAN_FAILED',
  SET_REJECTED_PLAN = 'provisioning/SET_REJECTED_PLAN',
  SET_REJECTED_PLAN_FULFILLED = 'provisioning/SET_REJECTED_PLAN_FULFILLED',
  SET_REJECTED_PLAN_FAILED = 'provisioning/SET_REJECTED_PLAN_FAILED',
}

const createAction = makeActionCreator<ActionTypes>();

export const actionCreators = {
  fetchAvailablePlans: (clearSelectedPlan: boolean, absoluteMode: boolean) =>
    createAction({
      type: ActionTypes.FETCH_AVAILABLE_PLANS,
      clearSelectedPlan,
      absoluteMode,
    }),

  fetchAvailablePlansFulfilled: (
    clearSelectedPlan: boolean,
    absoluteMode: boolean,
    response: GetAvailablePlansResponse,
  ) =>
    createAction({
      type: ActionTypes.FETCH_AVAILABLE_PLANS_FULFILLED,
      clearSelectedPlan,
      absoluteMode,
      response,
    }),

  fetchAvailablePlansFailed: (clearSelectedPlan: boolean, error: RequestError) =>
    createAction({
      type: ActionTypes.FETCH_AVAILABLE_PLANS_FAILED,
      clearSelectedPlan,
      error,
    }),

  getSelectedPlanID: (accountID: string) =>
    createAction({
      type: ActionTypes.GET_SELECTED_PLAN_ID,
      accountID,
    }),

  fetchLaunchOrders: (accountID: string, source: OrderSource) =>
    createAction({
      type: ActionTypes.FETCH_LAUNCH_ORDERS,
      accountID,
      source,
    }),

  fetchLaunchOrdersFulfilled: (accountID: string, response: GetOrdersResponse) =>
    createAction({
      type: ActionTypes.FETCH_LAUNCH_ORDERS_FULFILLED,
      accountID,
      response,
    }),

  fetchLaunchOrdersFailed: (accountID: string, error: RequestError) =>
    createAction({
      type: ActionTypes.FETCH_LAUNCH_ORDERS_FAILED,
      accountID,
      error,
    }),

  setLaunchOrderEndDate: (accountID: string, orderID: number, endDate: Date, absoluteMode: boolean) =>
    createAction({
      type: ActionTypes.SET_LAUNCH_ORDER_END_DATE,
      accountID,
      orderID,
      endDate,
      absoluteMode,
    }),

  setLaunchOrderEndDateFulfilled: (
    accountID: string,
    orderID: number,
    endDate: Date,
    response: PostLaunchOrderEndDateResponse,
  ) =>
    createAction({
      type: ActionTypes.SET_LAUNCH_ORDER_END_DATE_FULFILLED,
      accountID,
      orderID,
      endDate,
      response,
    }),

  setLaunchOrderEndDateFailed: (accountID: string, orderID: number, endDate: Date, error: RequestError) =>
    createAction({
      type: ActionTypes.SET_LAUNCH_ORDER_END_DATE_FAILED,
      accountID,
      orderID,
      endDate,
      error,
    }),

  setExecutedPlan: (
    accountID: string,
    inputsHash: string,
    absoluteMode: boolean,
    skipEmailNotification: boolean,
    userEmail: string | null,
  ) =>
    createAction({
      type: ActionTypes.SET_EXECUTED_PLAN,
      accountID,
      inputsHash,
      absoluteMode,
      skipEmailNotification,
      userEmail,
    }),

  setExecutedPlanFulfilled: (accountID: string, response: PostExecutedPlanResponse) =>
    createAction({
      type: ActionTypes.SET_EXECUTED_PLAN_FULFILLED,
      accountID,
      response,
    }),

  setExecutedPlanFailed: (accountID: string, error: RequestError) =>
    createAction({
      type: ActionTypes.SET_EXECUTED_PLAN_FAILED,
      accountID,
      error,
    }),

  setRejectedPlan: (
    accountID: string,
    inputsHash: string,
    absoluteMode: boolean,
    userEmail: string | null,
    comment: string | null,
  ) =>
    createAction({
      type: ActionTypes.SET_REJECTED_PLAN,
      accountID,
      inputsHash,
      absoluteMode,
      userEmail,
      comment,
    }),

  setRejectedPlanFulfilled: (accountID: string, response: PostRejectedPlanResponse) =>
    createAction({
      type: ActionTypes.SET_REJECTED_PLAN_FULFILLED,
      accountID,
      response,
    }),

  setRejectedPlanFailed: (accountID: string, error: RequestError) =>
    createAction({
      type: ActionTypes.SET_REJECTED_PLAN_FAILED,
      accountID,
      error,
    }),
};

export type Actions = ActionsUnion<typeof actionCreators>;
