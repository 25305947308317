import * as React from 'react';
import { Link } from 'react-router-dom';
import { Settings } from '../../settings';
import { Table } from '../../shared';
import { Account, getAccountDisplayName, NoAccountsMsg, SavedAccount } from '../model';
import { sortActiveAccounts } from './AccountSearch/utils';
import { OveragesContainer } from './OveragesContainer';
import { SubscriptionStateTag } from './SubscriptionStateTag';

type AccountListProps = Readonly<{
  accounts: Account[];
  showOverages: boolean;
  filterName: string;
  noAccountsMsg: string;
  onAddAccountToRecentlyViewed: (recentlyViewedAccount: SavedAccount) => void;
  settings: Settings;
}>;

export const AccountList = ({
  accounts,
  showOverages,
  filterName,
  noAccountsMsg,
  onAddAccountToRecentlyViewed,
  settings,
}: AccountListProps) => {
  if ((!accounts || !accounts.length) && noAccountsMsg === NoAccountsMsg.DEFAULT) {
    return <p>No matching accounts</p>;
  }

  const sortedAccounts = settings.enableSubscriptionStateFiltering
    ? Array.from(accounts).sort(sortActiveAccounts)
    : accounts;

  return (
    <Table>
      <thead>
        <tr>
          <th>Account</th>
          {showOverages &&
            filterName !== 'All' && (
              <>
                <th>Seats</th>
                <th>Client-side MAUs</th>
                <th>Events received</th>
                <th>Events published</th>
              </>
            )}
        </tr>
      </thead>
      <tbody>
        {sortedAccounts.map(
          a =>
            a && (
              <tr key={a._id}>
                <td
                  onClick={() => {
                    onAddAccountToRecentlyViewed({ id: a._id, displayName: getAccountDisplayName(a) });
                  }}
                >
                  <Link to={`/accounts/${a._id}`}>{getAccountDisplayName(a)}</Link>
                  {a.subscription &&
                    settings.enableSubscriptionStateFiltering && <SubscriptionStateTag state={a.subscription.state} />}
                </td>
                {showOverages && filterName !== 'All' && <OveragesContainer accountId={a._id} />}
              </tr>
            ),
        )}
      </tbody>
    </Table>
  );
};
