import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from '../../reducer';
import { createUsageShortcuts, Metadata, Series } from '../../shared';
import { Actions } from '../actions';
import { AccountUsage } from '../components/AccountUsage';
import { TimeRangeFilter } from '../components/TimeRangeFilter';
import { ServerSDKVersionsContainer } from '../containers/SDKVersionsContainer';
import {
  Account,
  ChartTypes,
  getDefaultDateRangeForUsage,
  SDKOption,
  SDKType,
  UsageFilters,
  UsageIdentifier,
  UsageOrigin,
  UsageTypes,
} from '../model';
import { serverConnectionsSelector } from '../selectors';

type OwnProps = Readonly<{
  account: Account;
  limit: number;
}>;

type StateProps = Readonly<{
  isFetching: boolean;
  metadata: Metadata;
  series: Series;
  chartType: ChartTypes;
  tooltipText: string;
}>;

type State = {
  readonly selectedOption: SDKOption;
  readonly timeRange: { to: number; from: number };
  readonly selectedTimeOption?: string;
};

type DispatchProps = Readonly<{
  onFetchAccountUsage: (filters: UsageFilters) => void;
}>;

type ServerConnectionsUsageProps = StateProps &
  Readonly<{
    onFetchAccountUsage: (filters: any) => void;
    account: Account;
  }>;

export class ServerConnectionsUsageContainer extends React.Component<ServerConnectionsUsageProps> {
  public state: State = {
    selectedOption: {
      sdk: '',
      version: '',
      name: '',
      value: '',
    },
    selectedTimeOption: 'Last 30 days',
    timeRange: getDefaultDateRangeForUsage(),
  };
  public componentDidMount() {
    this.props.onFetchAccountUsage({ ...this.state.selectedOption, ...this.state.timeRange });
  }

  public render() {
    const { metadata, chartType, tooltipText, series, onFetchAccountUsage, isFetching } = this.props;
    return (
      <AccountUsage
        renderFilters={() => this.renderFilters()}
        series={series}
        metadata={metadata}
        onFetchAccountUsage={onFetchAccountUsage}
        chartType={chartType}
        selectedOption={this.state.selectedOption}
        tooltipText={tooltipText}
        isFetching={isFetching}
      />
    );
  }

  private renderFilters = () => (
    <>
      <ServerSDKVersionsContainer
        onSelect={this.handleSelect}
        account={this.props.account}
        sdkType={SDKType.SERVER}
        selectedSDK={this.state.selectedOption}
        onClear={this.handleClear}
      />
      <TimeRangeFilter
        onSelect={(value: { to: number; from: number }, label: string) => this.handleTimeSelect(value, label)}
        selectedOption={this.state.selectedTimeOption}
        value="hours"
        timeRange={createUsageShortcuts()}
        rightMarginSpacing
      />
    </>
  );

  private handleSelect = (option: SDKOption) => {
    this.setState({
      selectedOption: option,
    });
    const filters = { ...option, ...this.state.timeRange };
    this.props.onFetchAccountUsage(filters);
  };

  private handleClear = () => {
    if (this.state.selectedOption.value) {
      this.setState({
        selectedOption: {
          sdk: '',
          version: '',
          name: '',
          value: '',
        },
      });
      const filters = { ...this.state.selectedOption, ...this.state.timeRange };
      this.props.onFetchAccountUsage(filters);
    }
  };

  private handleTimeSelect = (value: { to: number; from: number }, label: string) => {
    this.setState({ selectedTimeOption: label });
    const filters = { ...this.state.selectedOption, ...value };
    this.props.onFetchAccountUsage(filters);
  };
}

const mapStateToProps: (state: AppState, ownProps: OwnProps) => StateProps = (state, ownProps) => ({
  ...serverConnectionsSelector(state),
  chartType: ChartTypes.LINE,
  usageIdentifier: UsageIdentifier.SERVER_CONNECTIONS,
  account: ownProps.account,
  tooltipText: 'connection',
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => ({
  onFetchAccountUsage: (filters: UsageFilters) =>
    dispatch(
      Actions.fetchAccountUsage({
        account: ownProps.account,
        usageIdentifier: UsageIdentifier.SERVER_CONNECTIONS,
        usageType: UsageTypes.SERVER,
        mauType: undefined,
        metric: UsageOrigin.STREAMS,
        filters,
      }),
    ),
});

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ServerConnectionsUsageContainer);
