import { Actions, ActionTypes } from './actions';
import { Scopes, User } from './model';

export type AuthState = Readonly<{
  user: User | null;
  scopes: Scopes | null;
  error: Error | null;
  isReady: boolean;
}>;

export const reducer = (
  state: AuthState = { user: null, scopes: null, isReady: false, error: null },
  action: Actions,
) => {
  switch (action.type) {
    case ActionTypes.CHECKING_AUTH:
      return {
        ...state,
        isReady: false,
      };
    case ActionTypes.SIGN_IN:
      return {
        ...state,
        isReady: false,
      };
    case ActionTypes.SIGNING_IN:
      return {
        ...state,
        isReady: false,
      };
    case ActionTypes.SIGNED_IN:
      return {
        ...state,
        user: action.user,
        scopes: action.scopes,
        isReady: true,
      };
    case ActionTypes.SIGN_OUT:
      return {
        ...state,
        isReady: false,
      };
    case ActionTypes.SIGNED_OUT:
      return {
        ...state,
        user: null,
        scopes: null,
        isReady: true,
      };
    default:
      return state;
  }
};
