import { Classes } from '@blueprintjs/core';
import * as cx from 'classnames';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

type LinkMenuItemProps = Readonly<{
  to: string | object;
  children?: any;
}>;

export const LinkMenuItem = ({ to, children }: LinkMenuItemProps) => (
  <NavLink
    exact
    to={to}
    className={cx(Classes.MENU_ITEM, Classes.POPOVER_DISMISS)}
    activeClassName={cx(Classes.ACTIVE, Classes.INTENT_PRIMARY)}
  >
    {children}
  </NavLink>
);
