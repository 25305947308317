import { Observable } from 'rxjs';
import config from '../config';
import { request } from '../shared';
import { Order, OrderSource, Plan } from './model';

const baseUrl = config.provisioning.host;
const baseHeaders = {
  'Content-Type': 'application/json',
};

export type GetAvailablePlansResponse = {
  date: string;
  plans: Plan[];
};

export type GetOrdersResponse = {
  orders: Order[];
};

export type PostLaunchOrderEndDateRequest = {
  endDate: Date;
};

export type PostLaunchOrderEndDateResponse = {
  order: Order;
};

export type PostExecutedPlanRequest = {
  inputsHash: string;
  skipEmailNotification: boolean;
  userEmail: string | null;
  absoluteMode: boolean;
};

export type PostExecutedPlanResponse = {
  executedPlanID: number;
};

export type PostRejectedPlanRequest = {
  inputsHash: string;
  userEmail: string | null;
  comment: string | null;
  absoluteMode: boolean;
};

export type PostRejectedPlanResponse = {
  rejectedPlanID: number;
};

export const getAvailablePlans = (absoluteMode: boolean): Observable<GetAvailablePlansResponse> => {
  const req = {
    headers: baseHeaders,
    method: 'GET',
    url: `${baseUrl}/api/available-plans?absoluteMode=${absoluteMode}`,
  };

  return request<GetAvailablePlansResponse>(req);
};

export const getOrders = ({
  accountID,
  source,
}: {
  accountID?: string;
  source?: OrderSource;
}): Observable<GetOrdersResponse> => {
  let url = `${baseUrl}/api/orders`;
  const params: string[] = [];
  if (accountID) {
    params.push(`accountID=${accountID}`);
  }
  if (source) {
    params.push(`source=${source}`);
  }
  if (params.length) {
    url += `?${params.join('&')}`;
  }

  const req = {
    headers: baseHeaders,
    method: 'GET',
    url,
  };

  return request<GetOrdersResponse>(req);
};

export const postLaunchOrderEndDate = (orderID: number, endDate: Date): Observable<PostLaunchOrderEndDateResponse> => {
  const requestBody: PostLaunchOrderEndDateRequest = {
    endDate,
  };
  const req = {
    body: requestBody,
    headers: baseHeaders,
    method: 'POST',
    url: `${baseUrl}/api/update-launch-order-end-dates/${orderID}`,
  };

  return request<PostLaunchOrderEndDateResponse>(req);
};

export const postExecutedPlan = (
  accountID: string,
  inputsHash: string,
  absoluteMode: boolean,
  skipEmailNotification: boolean,
  userEmail: string | null,
): Observable<PostExecutedPlanResponse> => {
  const requestBody: PostExecutedPlanRequest = { inputsHash, skipEmailNotification, userEmail, absoluteMode };
  const req = {
    body: requestBody,
    headers: baseHeaders,
    method: 'POST',
    url: `${baseUrl}/api/executed-plans/${accountID}`,
  };

  return request<PostExecutedPlanResponse>(req);
};

export const postRejectedPlan = (
  accountID: string,
  inputsHash: string,
  absoluteMode: boolean,
  userEmail: string | null,
  comment: string | null,
): Observable<PostRejectedPlanResponse> => {
  const requestBody: PostRejectedPlanRequest = { inputsHash, userEmail, comment, absoluteMode };
  const req = {
    body: requestBody,
    headers: baseHeaders,
    method: 'POST',
    url: `${baseUrl}/api/rejected-plans/${accountID}`,
  };

  return request<PostRejectedPlanResponse>(req);
};
