import { getScopes } from '../auth';
import { AppState } from '../reducer';
import { getSettings } from '../settings';

export const getProvisioning = (state: AppState) => state.provisioning;

export const getProcessedPlansByAccountID = (state: AppState) => getProvisioning(state).processedPlansByAccountID;

export const getSelectedPlan = (state: AppState) => getProvisioning(state).selectedPlan;

export const getExecutedPlan = (state: AppState) => getProvisioning(state).executedPlan;

export const getRejectedPlan = (state: AppState) => getProvisioning(state).rejectedPlan;

export const getProvisioningState = (state: AppState) => ({
  userEmail: getUserEmail(state),
  date: getProvisioning(state).date,
  processedPlansByAccountID: getProcessedPlansByAccountID(state),
  selectedPlan: getSelectedPlan(state),
  executedPlan: getExecutedPlan(state),
  rejectedPlan: getRejectedPlan(state),
  fetchAvailablePlansRequest: getProvisioning(state).fetchAvailablePlansRequest,
  fetchLaunchOrdersRequest: getProvisioning(state).fetchLaunchOrdersRequest,
  setLaunchOrderEndDateRequest: getProvisioning(state).setLaunchOrderEndDateRequest,
  setExecutedPlanRequest: getProvisioning(state).setExecutedPlanRequest,
  setRejectedPlanRequest: getProvisioning(state).setRejectedPlanRequest,
  settings: getSettings(state),
  scopes: getScopes(state),
});

export const getUserEmail = (state: AppState) => {
  if (!state.auth.user || state.auth.user.email === undefined) {
    return null;
  }
  return state.auth.user.email;
};
