import * as d3 from 'd3';
import * as React from 'react';

export const enum AxisOrientation {
  TOP,
  RIGHT,
  BOTTOM,
  LEFT,
}

export type AxisProps = Readonly<{
  scale: d3.AxisScale<d3.AxisDomain>;
  orientation: AxisOrientation;
  tickFormat: (...args: any[]) => string;
  transform?: string;
  ticks?: number | d3.AxisTimeInterval | null;
  tickValues?: d3.AxisDomain[];
}>;

export class Axis extends React.Component<AxisProps> {
  private element: SVGGElement | null;

  public componentDidMount() {
    this.renderAxis();
  }

  public componentDidUpdate() {
    this.renderAxis();
  }

  public render() {
    const { transform } = this.props;

    return <g className="Axis" transform={transform} ref={el => (this.element = el)} />;
  }

  private renderAxis() {
    d3.select(this.element).call(this.createAxis());
  }

  private createAxis() {
    const { scale, ticks, tickValues, tickFormat } = this.props;
    const axis = this.getAxisFn()(scale).tickFormat(tickFormat);

    if (ticks) {
      axis.ticks(ticks);
    } else if (tickValues) {
      axis.tickValues(tickValues);
    }

    return axis;
  }

  private getAxisFn() {
    switch (this.props.orientation) {
      case AxisOrientation.TOP:
        return d3.axisTop;
      case AxisOrientation.RIGHT:
        return d3.axisRight;
      case AxisOrientation.BOTTOM:
        return d3.axisBottom;
      case AxisOrientation.LEFT:
        return d3.axisLeft;
    }
  }
}
