import { Icon, Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { distanceInWordsToNow, format } from 'date-fns';
import { Box } from 'grid-styled';
import * as marked from 'marked';

import * as React from 'react';
import './styles.scss';

import { AuditLogEntry, AuditLogEntryDetail, AuditLogMember, Project } from '../../model';

type EntryProps = Readonly<{
  entry: AuditLogEntry | AuditLogEntryDetail;
  projects: Project[];
}>;

export class AuditLogEntryOverview extends React.Component<EntryProps> {
  public render() {
    const { entry, projects } = this.props;
    return (
      <>
        <Box className="AuditLogEntry-title">
          <span style={{ fontWeight: 'bold' }}>{entry.member && this.getMemberNameOrEmail(entry.member)}</span>
          <span style={this.getTitleVerbTextColor(entry.titleVerb)}>
            {' ' + entry.titleVerb}
            {entry.name === 'account' && ' the'}
          </span>
          <span style={{ fontWeight: 'bold' }}>{' ' + entry.name}</span>
          {entry.parent && ' in ' + entry.parent.name}
          <span>
            {entry.parent && this.getEnvColor(projects, this.getProjectKey(entry.parent.resource), entry.parent.name)}
          </span>
        </Box>
        <Box className="AuditLogEntry-details">
          <Tooltip content={format(new Date(entry.date), 'dddd, MMMM Do, YYYY[ at ]h:mm A')} position={Position.BOTTOM}>
            {distanceInWordsToNow(entry.date, { addSuffix: true })}
          </Tooltip>
          {entry.accesses[0].resource &&
            entry.accesses[0].resource.startsWith('proj') &&
            ' - ' + this.getProjectKey(entry.accesses[0].resource) + ' project'}

          {entry.token && <Box>{' - using the token ' + entry.token.name + ' (...' + entry.token.ending + ')'}</Box>}
        </Box>
        {entry.description && (
          <Box className="AuditLogEntry-description">
            <span
              dangerouslySetInnerHTML={{
                __html: marked(entry.description, { sanitize: true }),
              }}
            />
          </Box>
        )}
      </>
    );
  }
  private getProjectKey = (resource: string) => {
    const projectRegex = /(proj\/([a-zA-Z0-9-_.]+)):?/;
    const match = projectRegex.exec(resource);
    if (match) {
      return match[match.length - 1];
    }
    return undefined;
  };

  private getEnvColor = (projects: Project[], projectKey: any, environmentName: any) => {
    for (const proj in projects) {
      if (projectKey === projects[proj].key) {
        for (const env in projects[proj].environments) {
          if (environmentName === projects[proj].environments[env].name) {
            return (
              <Icon icon={IconNames.SYMBOL_SQUARE} iconSize={22} color={`#${projects[proj].environments[env].color}`} />
            );
          }
        }
      }
    }
    return undefined;
  };

  private getMemberNameOrEmail = (member: AuditLogMember) => {
    if (member.firstName && member.lastName) {
      return member.firstName + ' ' + member.lastName;
    }
    return member.email;
  };

  private getTitleVerbTextColor = (titleVerb: string) => {
    if (titleVerb.startsWith('created')) {
      return { color: 'green' };
    } else if (titleVerb.startsWith('deleted')) {
      return { color: 'red' };
    } else {
      return { color: 'black' };
    }
  };
}
