import { Button, Hotkey, Hotkeys, HotkeysTarget, Menu, MenuItem, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemListRenderer, ItemRenderer, Omnibar } from '@blueprintjs/select';
import { Box, Flex } from 'grid-styled';
import * as React from 'react';
import history from '../../../history';
import { Settings } from '../../../settings';
import { Account, getAccountDisplayName, SubscriptionState } from '../../model';
import { SubscriptionStateTag } from '../SubscriptionStateTag';
import { SubscriptionStateSelect } from './SubscriptionStateSelect';
import { highlightText, sortActiveAccounts } from './utils';

type Props = {
  isFetching: boolean;
  q: string;
  results: Account[];
  settings: Settings;
  onSearch: (value: string) => void;
  onClearResults: () => void;
};

type State = {
  readonly isOpen: boolean;
  subStateFilter: SubscriptionState | undefined;
};

const AccountOmnibar = Omnibar.ofType<Account>();

@HotkeysTarget
export class AccountSearch extends React.Component<Props, State> {
  public state: State = {
    isOpen: false,
    subStateFilter: undefined,
  };

  public renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey
          global
          combo="shift + s"
          label="Search accounts"
          onKeyDown={this.handleToggle}
          // prevent typing "S" in omnibar input
          preventDefault
        />
      </Hotkeys>
    );
  }

  public render() {
    const { q, results, settings } = this.props;
    const { subStateFilter } = this.state;

    return (
      <div className="AccountSearch">
        <Button minimal icon={IconNames.SEARCH} onClick={this.handleToggle}>
          Search accounts
        </Button>

        <AccountOmnibar
          resetOnSelect
          isOpen={this.state.isOpen}
          query={q}
          initialContent={this.initialContent}
          items={results.sort(sortActiveAccounts)}
          itemPredicate={(_, item) =>
            !settings.enableSubscriptionStateFiltering ||
            !subStateFilter ||
            !item.subscription ||
            item.subscription.state === subStateFilter
          }
          itemListRenderer={this.renderItemList}
          itemRenderer={this.renderItem}
          noResults={this.noResults}
          onQueryChange={this.handleQueryChange}
          onItemSelect={this.handleItemSelect}
          onClose={this.handleClose}
        />
      </div>
    );
  }

  private initialContent = (
    <div className="AccountSearch-initialContent">
      The following fields are included in the search
      <ul>
        <li>Member Email Address</li>
        <li>Member ID</li>
        <li>Organization Name</li>
      </ul>
    </div>
  );

  private noResults = () => (
    <MenuItem
      disabled
      text={
        this.props.isFetching ? (
          <Flex alignItems="center">
            <Box pr={2}>
              <Spinner size={15} />
            </Box>
            <Box>Searching accounts…</Box>
          </Flex>
        ) : (
          'No results.'
        )
      }
    />
  );

  private renderItemLabel = (account: Account, text: string, query: string) => {
    return (
      <>
        {highlightText(text, query)}
        {account.subscription &&
          this.props.settings.enableSubscriptionStateFiltering && (
            <SubscriptionStateTag state={account.subscription.state} />
          )}
      </>
    );
  };

  private renderItem: ItemRenderer<Account> = (account, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    const text = getAccountDisplayName(account);

    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={account._id}
        onClick={handleClick}
        text={this.renderItemLabel(account, text, query)}
      />
    );
  };

  private renderItemList: ItemListRenderer<Account> = ({ filteredItems, renderItem, query, itemsParentRef }) => {
    if (!query) {
      return this.initialContent;
    }
    if (filteredItems.length === 0) {
      return (
        <>
          {!this.props.isFetching && this.renderSubscriptionFilter()}
          <Menu ulRef={itemsParentRef}>{this.noResults()}</Menu>
        </>
      );
    }

    return (
      <>
        {this.renderSubscriptionFilter()}
        <Menu ulRef={itemsParentRef}>{Array.from(filteredItems).map(renderItem)}</Menu>
      </>
    );
  };

  private renderSubscriptionFilter = () => {
    if (!this.props.settings.enableSubscriptionStateFiltering) {
      return null;
    }
    return (
      <SubscriptionStateSelect
        subStateFilter={this.state.subStateFilter}
        setSubStateFilter={item => this.setState({ subStateFilter: item as SubscriptionState })}
      />
    );
  };

  private handleItemSelect = (account: Account) => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }), () => history.push(`/accounts/${account._id}`));
  };

  private handleQueryChange = (query: string) => {
    this.props.onSearch(query);
  };

  private handleClose = () => {
    this.setState(() => ({ isOpen: false }), () => this.props.onSearch(''));
  };

  private handleToggle = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };
}
