import { diffJson } from 'diff';
import * as React from 'react';

type DiffProps = Readonly<{
  a: object;
  b: object;
}>;

export class EntryDiff extends React.Component<DiffProps> {
  public render() {
    return (
      <div className="AuditLogEntryDetails-tabcontent">
        <pre>{this.result}</pre>
      </div>
    );
  }

  private changes = diffJson(this.props.a, this.props.b);

  private result = this.changes.map(({ value, added, removed }, index) => {
    let node;

    if (removed) {
      node = (
        <del style={{ backgroundColor: '#f3a2a2', textDecorationLine: 'none' }} key={index}>
          {value}
        </del>
      );
    } else if (added) {
      node = (
        <ins style={{ backgroundColor: '#b5f9b5', textDecorationLine: 'none' }} key={index}>
          {value}
        </ins>
      );
    } else {
      node = <span key={index}>{value}</span>;
    }

    return node;
  });
}
