import { Checkbox, FormGroup, HTMLSelect } from '@blueprintjs/core';
import * as accounting from 'accounting';
import * as React from 'react';
import { Settings } from '../../../settings';

const Buckets = (props: {
  isDisabled: boolean;
  formsEnabled: boolean;
  options: number[];
  value: number;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}) => {
  const { isDisabled, options, onChange, value } = props;
  return props.formsEnabled ? (
    <FormGroup className="options">
      <HTMLSelect
        disabled={isDisabled}
        fill
        options={accounting.formatNumber(Object.keys(options))}
        value={accounting.formatNumber(value)}
        onChange={onChange}
      />
    </FormGroup>
  ) : (
    <>accounting.formatNumber(value)</>
  );
};

type DataExportProps = Readonly<{
  isChecked: boolean;
  isDisabled: boolean;
  formsEnabled: boolean;
  options: number[];
  value: number;
  onChange: (event: any) => void;
  settings: Settings;
}>;

export class DataExport extends React.Component<DataExportProps> {
  public render() {
    const { isChecked, isDisabled, formsEnabled, options, value, onChange } = this.props;

    return this.props.settings.dataExportPercentagePricing ? (
      <FormGroup className="options">
        <Checkbox checked={isChecked} disabled={isDisabled} onChange={onChange} />
      </FormGroup>
    ) : (
      <Buckets
        isDisabled={isDisabled}
        formsEnabled={formsEnabled}
        options={options}
        onChange={onChange}
        value={value}
      />
    );
  }
}
