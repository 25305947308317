import {
  Button,
  Classes,
  Icon,
  Menu,
  // aliased to avoid collision with type declared below
  MenuItem as MItem,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as cx from 'classnames';
import * as H from 'history';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { envBaseUrls } from '../../../constants';
import { Avatar } from '../Avatar';
import { LinkMenuItem } from '../LinkMenuItem';
import { Logo } from '../Logo';
import './styles.scss';

type MenuItem = Readonly<{
  to: string;
  name: string;
  allowed?: boolean;
}>;

type TopNavProps = Readonly<{
  menuItems: ReadonlyArray<MenuItem>;
  avatar: string | null | undefined;
  searchComponent?: React.ComponentType | null;
  onSignOut: () => void;
  history: H.History;
}>;

const TopNavMenuContent = ({ menuItems }: { menuItems: ReadonlyArray<MenuItem> }) => (
  <Menu>
    {menuItems.map(
      ({ allowed, to, name }: MenuItem) =>
        allowed && (
          <LinkMenuItem key={to} to={to}>
            {name}
          </LinkMenuItem>
        ),
    )}
  </Menu>
);

const TopNavMenu = ({ menuItems }: { menuItems: ReadonlyArray<MenuItem> }) => (
  <Popover
    content={<TopNavMenuContent menuItems={menuItems} />}
    interactionKind={PopoverInteractionKind.CLICK}
    position={Position.BOTTOM_RIGHT}
  >
    <Button icon="layout-grid" className={Classes.MINIMAL} />
  </Popover>
);

const EnvMenu = (
  <Menu>
    <MItem text="Staging" href={envBaseUrls.staging} />
    <MItem text="Production" href={envBaseUrls.production} />
    <MItem text="Federal Staging" href={envBaseUrls.federalStaging} />
    <MItem text="Federal Production" href={envBaseUrls.federalProduction} />
    <MItem text="EU Production" href={envBaseUrls.euProduction} />
    <MItem text="Catamorphic" href={envBaseUrls.catamorphic} />
    <MItem text="Local" href={envBaseUrls.localHost} />
  </Menu>
);

const EnvironmentSwitcher = () => (
  <div>
    <Popover content={EnvMenu} position={Position.RIGHT_TOP}>
      <Button icon="link" text="Switch environment" />
    </Popover>
  </div>
);

export const TopNav = ({ menuItems, avatar, searchComponent: SearchComponent, onSignOut, history }: TopNavProps) => (
  <nav className={cx('TopNav', Classes.NAVBAR)}>
    <div className={cx(Classes.NAVBAR_GROUP, Classes.ALIGN_LEFT)}>
      <Link to="/" className={cx('TopNav-logo', Classes.BUTTON, Classes.MINIMAL)}>
        <Logo />
        <span className={Classes.NAVBAR_HEADING}>Vitalstatistix</span>
      </Link>
    </div>

    {SearchComponent && (
      <div className={cx(Classes.NAVBAR_GROUP, Classes.ALIGN_LEFT)}>
        <SearchComponent />
      </div>
    )}

    <div className={cx(Classes.NAVBAR_GROUP, Classes.ALIGN_RIGHT)}>
      {history.location.pathname === '/provisioning-plan-review' ? '' : <EnvironmentSwitcher />}

      <TopNavMenu menuItems={menuItems} />

      <div className={Classes.NAVBAR_DIVIDER} />

      {avatar ? <Avatar src={avatar} /> : <Icon icon={IconNames.USER} />}

      <Button onClick={() => onSignOut()} icon="log-out" className={Classes.MINIMAL} />
    </div>
  </nav>
);
