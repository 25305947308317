const deserialize = (data: string) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    return [];
  }
};

export const getAccountsFromLocalStorage = (key: string) => {
  const storage = window.localStorage;
  const data = storage.getItem(key);
  return data !== null ? deserialize(data) : [];
};

export enum localStorageKeys {
  FAVORITED = 'favoritedVitalstatistixAccounts',
  RECENTLY_VIEWED = 'recentlyViewedVitalstatistixAccounts',
}

export const getRecentlyViewedAccounts = () => {
  return getAccountsFromLocalStorage(localStorageKeys.RECENTLY_VIEWED);
};

export const getFavoritedAccounts = () => {
  return getAccountsFromLocalStorage(localStorageKeys.FAVORITED);
};
