import {
  ButtonGroup,
  Classes,
  Icon,
  InputGroup,
  Menu,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core';
import { Box, Flex } from 'grid-styled';
import { debounce } from 'lodash';
import * as qs from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import history from '../../history';
import { AppState } from '../../reducer';
import { getSettings, Settings } from '../../settings';
import { Loading, LoadingError, RequestError, Subhead } from '../../shared';
import { Actions } from '../actions';
import { AccountList, SavedAccountList } from '../components';
import { Account, MetricType, NoAccountsMsg, SavedAccount } from '../model';
import { getAllAccounts } from '../selectors';
import { getFavoritedAccounts, getRecentlyViewedAccounts } from '../storageUtils';

import '../components/AccountList.scss';
import { SubscriptionStateSelect } from '../components/AccountSearch/SubscriptionStateSelect';

type StateProps = Readonly<{
  accounts: Account[];
  isFetching: boolean;
  error: RequestError | null;
  settings: Settings;
  query: string;
  fromZD: boolean;
}>;

type OwnProps = RouteComponentProps<{}> &
  Readonly<{ displayUnpaidOnly: boolean; overage?: string; displayWithoutQuery: boolean; showOverages: boolean }>;

type DispatchProps = Readonly<{
  onFetchAccounts: (q: string, unpaid: boolean, overage?: string) => void;
  onSearch: (value: string) => void;
  onAddAccountToRecentlyViewed: (recentlyViewedAccount: SavedAccount) => void;
}>;

type IAccountListPageProps = StateProps & DispatchProps & OwnProps;

class AccountListPage extends React.Component<IAccountListPageProps, any> {
  public state = {
    query: this.props.query,
    filterName: 'All',
    isOpen: false,
    subStateFilter: undefined,
  };

  private searchInputRef: HTMLInputElement;

  public componentDidMount() {
    const { displayUnpaidOnly, displayWithoutQuery, overage, query } = this.props;
    if (displayUnpaidOnly || overage || displayWithoutQuery || query) {
      this.loadAccounts();
    }

    this.focus();
  }

  public componentDidUpdate(prevProps: IAccountListPageProps) {
    const { fromZD, location, query, accounts } = this.props;
    if (query !== prevProps.query) {
      this.loadAccounts();
      this.setState({ query });
    }
    if (fromZD && prevProps.accounts && prevProps.accounts.length !== 1 && accounts && accounts.length === 1) {
      history.push({
        pathname: `${location.pathname}/${accounts[0]._id}`,
      });
    }
  }

  public render() {
    const { displayWithoutQuery, displayUnpaidOnly, overage } = this.props;
    const { query } = this.state;

    return (
      <>
        {overage && <Subhead>Professional plans with overages</Subhead>}
        {displayUnpaidOnly && <Subhead>Unpaid accounts</Subhead>}
        {!displayWithoutQuery && (
          <Flex>
            <Box width={1 / 2} mb={2}>
              <InputGroup
                onChange={this.handleQueryChange}
                value={query}
                placeholder="Start typing to search"
                large
                inputRef={this.setSearchInputRef}
              />
            </Box>
          </Flex>
        )}
        {this.renderFilters()}
        {this.renderContent()}
      </>
    );
  }

  private renderFilters = () => {
    const { displayWithoutQuery, overage, settings } = this.props;
    const { query } = this.state;
    const showAccountList = displayWithoutQuery || query;
    if (!showAccountList) {
      return null;
    }
    return (
      <div className="AccountList-filters">
        {overage && this.renderOverageFilters()}
        {settings.enableSubscriptionStateFiltering && showAccountList && this.renderSubscriptionFilter()}
      </div>
    );
  };

  private renderOverageFilters = () => {
    const options = [
      { value: 'any', name: 'All' },
      { value: '', name: '' },
      { value: MetricType.SEATS, name: 'Seats' },
      { value: MetricType.MONTHLY_ACTIVE_USERS, name: 'Client MAUs' },
      { value: MetricType.EVENTS_RECEIVED, name: 'Experiments' },
      { value: MetricType.EVENTS_PUBLISHED, name: 'Data export' },
    ];
    return (
      <ButtonGroup>
        <strong className="Overage">Overage type:</strong>

        <Popover
          isOpen={this.state.isOpen}
          interactionKind={PopoverInteractionKind.CLICK}
          position={Position.BOTTOM_LEFT}
          onInteraction={this.handlePopoverInteraction}
          className="Overages-popover"
        >
          <p className="OverageFilter">
            {this.getDescription()} <Icon icon="chevron-down" className="DownCarrot" />
          </p>

          <div>
            <Menu>
              {options.map(
                (d: { value: MetricType; name: string }, i: number) =>
                  i === 1 ? (
                    <div className="bp3-menu-divider" key="divider" />
                  ) : (
                    <MenuItem text={d.name} onClick={() => this.handleSelect(d)} key={i} />
                  ),
              )}
            </Menu>
          </div>
        </Popover>
      </ButtonGroup>
    );
  };

  private renderSubscriptionFilter = () => {
    const { subStateFilter } = this.state;
    return (
      <SubscriptionStateSelect
        subStateFilter={subStateFilter}
        setSubStateFilter={item => this.setState({ subStateFilter: item })}
      />
    );
  };

  private handleSelect = (d: { value: MetricType | string; name: string }) => {
    const { query, onFetchAccounts } = this.props;
    this.setState({
      filterName: d.name,
    });
    onFetchAccounts(query, false, d.value);
  };

  private getDescription = () => {
    return this.state.filterName;
  };

  private handlePopoverInteraction = (nextOpenState: boolean) => {
    this.setState((prevState: any) => {
      return {
        isOpen: nextOpenState,
        value: nextOpenState ? prevState.filterName : '',
      };
    });
  };

  private setSearchInputRef = (node: HTMLInputElement) => {
    this.searchInputRef = node;
  };

  private renderContent() {
    const { isFetching, accounts, error, displayWithoutQuery, showOverages, settings } = this.props;
    const { query, filterName, subStateFilter } = this.state;
    const showAccountList = displayWithoutQuery || query;
    if (isFetching) {
      return <Loading title="Loading accounts…" />;
    } else if (error) {
      return <LoadingError title="Error loading accounts" description={error.message.toString()} />;
    } else if (showAccountList) {
      return (
        <AccountList
          onAddAccountToRecentlyViewed={this.addAccountToRecentlyViewed}
          accounts={accounts.filter(
            ({ subscription }) => !subStateFilter || !subscription || subscription.state === subStateFilter,
          )}
          showOverages={showOverages}
          filterName={filterName}
          noAccountsMsg={NoAccountsMsg.DEFAULT}
          settings={settings}
        />
      );
    }
    return this.renderEmptyState();
  }

  private addAccountToRecentlyViewed = (recentlyViewedAccount: SavedAccount) => {
    const { onAddAccountToRecentlyViewed } = this.props;
    onAddAccountToRecentlyViewed(recentlyViewedAccount);
  };

  private renderEmptyState() {
    const { settings } = this.props;

    return (
      <>
        <p className={Classes.TEXT_LARGE}>
          Search accounts by Organization (name or id) or by Member (email address or id).
        </p>
        <p>
          <Link to="accounts/unpaid">Unpaid accounts</Link> &middot; <Link to="accounts/all">All accounts</Link>{' '}
          {settings.enableOverages && (
            <>
              &middot; <Link to="accounts/overages">Accounts with overages</Link>
            </>
          )}
        </p>
        <>
          {settings.recentlyViewedAccounts && (
            <Flex>
              <Box className="Recently-Viewed-Table-Holder" width={1 / 2}>
                <SavedAccountList
                  onAddAccountToRecentlyViewed={this.addAccountToRecentlyViewed}
                  noAccountsMsg={NoAccountsMsg.RECENTLY_VIEWED}
                  savedAccounts={getRecentlyViewedAccounts()}
                  type="recent"
                />
              </Box>
              <Box className="Favorited-Table-Holder" width={1 / 2}>
                <SavedAccountList
                  onAddAccountToRecentlyViewed={this.addAccountToRecentlyViewed}
                  noAccountsMsg={NoAccountsMsg.FAVORITED}
                  savedAccounts={getFavoritedAccounts()}
                  type="favorited"
                />
              </Box>
            </Flex>
          )}
        </>
      </>
    );
  }

  private search = debounce((query: string) => {
    const { location } = this.props;

    history.push({
      pathname: location.pathname,
      search: query ? `q=${encodeURIComponent(query)}` : '',
    });
  }, 250);

  private handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    this.setState({ query });
    this.search(query);
  };

  private loadAccounts() {
    const { location, onFetchAccounts, displayUnpaidOnly, overage } = this.props;
    const queryParams = qs.parse(location.search);
    const query = queryParams.q;
    onFetchAccounts(query, displayUnpaidOnly, overage);
  }

  private focus() {
    if (this.searchInputRef) {
      this.searchInputRef.focus();
    }
  }
}

const mapStateToProps: (state: AppState, ownProps: OwnProps) => StateProps = (state, ownProps) => {
  const queryParams = qs.parse(ownProps.location.search);
  const query = queryParams.q || '';
  const fromZD = queryParams.fromzd || '';
  return {
    ...getAllAccounts(state),
    settings: getSettings(state),
    query,
    fromZD,
  };
};

const mapDispatchToProps = {
  onFetchAccounts: Actions.fetchAccounts,
  onSearch: Actions.searchAccounts,
  onClearResults: Actions.clearAccountResults,
  onAddAccountToRecentlyViewed: Actions.addAccountToRecentlyViewed,
};

export const AccountListPageContainer = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AccountListPage);

export const OverageAccountListPageContainer = (props: RouteComponentProps<{}>) => (
  <AccountListPageContainer
    displayWithoutQuery
    displayUnpaidOnly={false}
    showOverages={true}
    overage="any"
    {...props}
  />
);

export const UnpaidAccountListPageContainer = (props: RouteComponentProps<{}>) => (
  <AccountListPageContainer displayWithoutQuery displayUnpaidOnly showOverages={false} {...props} />
);

export const AllAccountListPageContainer = (props: RouteComponentProps<{}>) => (
  <AccountListPageContainer displayWithoutQuery displayUnpaidOnly={false} showOverages={false} {...props} />
);
