import * as React from 'react';
import { DataPoint } from './types';

type MouseInfo = Readonly<{
  data: DataPoint | null;
}>;

type MouseChildProps = Readonly<{
  onMove: (info: MouseInfo) => void;
  mouseData: DataPoint | null;
}>;

export type MouseProps = Readonly<{
  render: (props: MouseChildProps) => React.ReactNode;
}>;

type MouseState = Readonly<{
  data: DataPoint | null;
}>;

export class Mouse extends React.Component<MouseProps, MouseState> {
  public render() {
    return (
      <div className="Mouse">
        {this.props.render({
          onMove: this.handleMove,
          mouseData: this.state ? this.state.data : null,
        })}
      </div>
    );
  }

  private handleMove = (info: MouseInfo) => {
    this.setState(info);
  };
}
