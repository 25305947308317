import { Action, applyMiddleware, compose, createStore, Store } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import * as accounts from './accounts';
import * as auth from './auth';
import rootEpic from './epics';
import * as provisioning from './provisioning';
import rootReducer, { AppState } from './reducer';
import * as settings from './settings';

type Actions = accounts.Actions | auth.Actions | settings.Actions | provisioning.Actions;

export default (): Store<AppState> => {
  const epicMiddleware = createEpicMiddleware<Action<Actions>, Action<Actions>, AppState>();
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancer = composeEnhancers(applyMiddleware(epicMiddleware));

  const store = createStore(rootReducer, enhancer);

  epicMiddleware.run(rootEpic);

  return store;
};
