import * as cx from 'classnames';
import * as React from 'react';

type CodeProps = Readonly<{
  className?: string;
  children?: any;
}>;

export const Code = ({ className, children }: CodeProps) => (
  <code className={cx('pt-monospace-text', className)}>{children}</code>
);
