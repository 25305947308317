import { ActionsObservable, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';
import history from '../history';
import { Actions, ActionTypes } from './actions';
import * as api from './api';
import { parseScopes } from './utils';

export const checkingAuth = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.checkingAuth>>(ActionTypes.CHECKING_AUTH),
    flatMap(() =>
      api.checkAuth().pipe(
        // Okta access token doesn't include email field (set instead as user_id), so set email to user_id field
        map(response =>
          Actions.signedIn(
            {
              user_id: response.user_id,
              email: response.user_id,
              groups: response.groups,
            },
            parseScopes(response.scopes),
          ),
        ),
        catchError(() => of(Actions.signIn())),
      ),
    ),
  );
};

export const signIn = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.signIn>>(ActionTypes.SIGN_IN),
    map(() => {
      sessionStorage.setItem('oauthRedirectPath', history.location.pathname + history.location.search);
      api.logIn();
      return Actions.signingIn();
    }),
  );
};

export const signOut = (action$: ActionsObservable<Actions>) => {
  return action$.pipe(
    ofType<ReturnType<typeof Actions.signOut>>(ActionTypes.SIGN_OUT),
    flatMap(() =>
      api.logOut().pipe(
        map(() => Actions.signedOut()),
        catchError(() => of(Actions.signedOut())),
      ),
    ),
  );
};
