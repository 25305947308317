import { Tag } from '@blueprintjs/core';
import React = require('react');
import { SubscriptionState, SubscriptionStateReadableNames } from '../model';
import './SubscriptionStateTag.scss';

export function SubscriptionStateTag({ state }: { state: SubscriptionState }) {
  return (
    <Tag round minimal className={`SubscriptionStateTag SubscriptionStateTag-${state} bp4-text-small`}>
      {SubscriptionStateReadableNames[state]}
    </Tag>
  );
}
