import { Box, Flex } from 'grid-styled';
import * as React from 'react';
import { Module, ModuleContent, ModuleHeader } from '../../shared';
import { Account, Member, OwnerPromotion } from '../model';
import { OwnerForm } from './OwnerForm';

import './members.scss';

type AccountOwnerPanelProps = Readonly<{
  account: Account;
  members: Member[];
  isUpdatingAccountOwner: boolean;
  onChangeAccountOwner: (promotion: OwnerPromotion) => void;
}>;

export class AccountOwnerPanel extends React.Component<AccountOwnerPanelProps> {
  public render() {
    return (
      <Flex>
        <Box width={2 / 3}>
          <Module>
            <Box>
              <ModuleHeader>
                <div>Change account owner</div>
              </ModuleHeader>
              <ModuleContent>
                <Flex>
                  <OwnerForm {...this.props} />
                </Flex>
              </ModuleContent>
            </Box>
          </Module>
        </Box>
      </Flex>
    );
  }
}
