import { Button, H2, Intent } from '@blueprintjs/core';
import { Box, Flex } from 'grid-styled';
import * as React from 'react';

import { DataList, DataListItem } from '../../../shared';
import { Subscription } from '../../model';

type DebugPanelProps = Readonly<{
  subscription: Subscription;
  onUpdateStripeSubscriptionId: (stripeSubscriptionId: string) => void;
  onClearPendingSubscriptionUpdate: () => void;
}>;
export class DebugPanel extends React.Component<DebugPanelProps, any> {
  public render() {
    const { subscription } = this.props;
    return (
      <>
        <Flex>
          <Box>
            <H2>Debugging tools</H2>
          </Box>
        </Flex>
        <DataList>
          <DataListItem>
            <Flex justify="space-between" align="center">
              <Box>Pending Update</Box>
              <Box>{!!subscription.pendingUpdate + ''}</Box>
              <Box>
                {subscription.pendingUpdate && (
                  <Button intent={Intent.DANGER} onClick={this.props.onClearPendingSubscriptionUpdate}>
                    Clear
                  </Button>
                )}
              </Box>
            </Flex>
          </DataListItem>
          <DataListItem>
            <Flex justify="space-between" align="center">
              <Box>Stripe Subscription Id</Box>
              <Box>
                <a href={'https://dashboard.stripe.com/subscriptions/' + subscription.stripeSubscriptionId}>
                  {subscription.stripeSubscriptionId}
                </a>
              </Box>
              <Box>
                <input onChange={e => this.setState({ stripeSubscriptionId: e.target.value })} type="text" />
              </Box>
              <Box>
                <Button
                  intent={Intent.PRIMARY}
                  onClick={() => this.props.onUpdateStripeSubscriptionId(this.state.stripeSubscriptionId)}
                >
                  Update
                </Button>
              </Box>
            </Flex>
          </DataListItem>
        </DataList>
      </>
    );
  }
}
